import React from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import invoicebarDropdownOpenIcon from "../../../../layout/assets/images/Invoices/invoicebar-dropdown-open-arrow.svg";
import invoicebarDropdownClosedIcon from "../../../../layout/assets/images/Invoices/invoicebar-dropdown-closed-arrow.svg";
import invoiceFooterEditIcon from "../../../../layout/assets/images/Invoices/invoice-footer-edit-btn.svg";
import moment from "moment";

export const InvoiceRightbarHeader = (props: any) => {
  let data = props.headerData;
  return (
    <>
      <div className="row">
        <div
          className="col-xs-12 d-flex align-items-end"
          style={{ marginBottom: "20px" }}
        >
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16.94px",
              marginRight: "6px",
            }}
          >
            Invoice No.
          </span>{" "}
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(255, 255, 255, 1)",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24.2px",
              marginRight: "9px",
            }}
          >
            {data.invoice_no}
          </span>
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(250, 200, 178, 1)",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "12.1px",
              textDecoration: "underline",
            }}
          >
            view details
          </span>
        </div>
        <div
          className="col-xs-12 d-flex align-items-end"
          style={{ marginBottom: "22.46px" }}
        >
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16.94px",
              marginRight: "11px",
            }}
          >
            Client
          </span>{" "}
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(255, 255, 255, 1)",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
              marginRight: "5px",
              textTransform: "capitalize",
              whiteSpace: "nowrap",
            }}
          >
            {data.client_name.toLowerCase()}
          </span>
          <span>
            {data.del === 0 ? (
              <Chip
                label="Active"
                color="primary"
                sx={{
                  height: "20px",
                  bgcolor: "#D5EFCC",
                  color: "#0A8442",
                }}
              />
            ) : (
              <Chip
                label="Cancelled"
                color="primary"
                sx={{
                  height: "20px",
                  bgcolor: "rgba(216, 106, 106, 0.32)",
                  color: "#840A0A",
                }}
              />
            )}
          </span>
        </div>

        <div className="col-xs-12 d-flex align-items-end">
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16.94px",
              marginRight: "5px",
              whiteSpace: "nowrap",
            }}
          >
            Invoice Date
          </span>{" "}
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#FFF",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
              whiteSpace: "nowrap",
            }}
          >
            {moment(data.creation_date).format("DD-MMM-YYYY hh:mm A")}
          </span>
        </div>
      </div>
    </>
  );
};

export const InvoiceRightbarBody = (props: any) => {
  const currentInvoiceDetails = props.currentInvoiceDetails;

  const [invoiceInnerDetails, setInvoiceInnerDetails] = React.useState(false);

  const handleInvoiceInnerDetails = () => {
    setInvoiceInnerDetails(!invoiceInnerDetails);
  };
  return (
    <>
      <div className="container" style={{ padding: "10px 39.55px 0 32px" }}>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-xs-12">
            <span
              style={{
                fontFamily: "Inter, sans-serif",
                color: "#09414E",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "16.94px",
                marginRight: "165px",
              }}
            >
              Serial Number
            </span>
            <span>
              <img
                src={
                  invoiceInnerDetails
                    ? invoicebarDropdownOpenIcon
                    : invoicebarDropdownClosedIcon
                }
                onClick={handleInvoiceInnerDetails}
                alt=""
                style={{ cursor: "pointer" }}
              />{" "}
            </span>
          </div>
        </div>
        <div
          className={invoiceInnerDetails ? "d-block" : "d-none"}
          style={{ transition: "all 2s ease" }}
        >
          <div className="row" style={{ marginBottom: "5px" }}>
            <div className="col-xs-12">
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#2E2E2E",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.52px",
                  marginRight: "26px",
                }}
              >
                Date From
              </span>
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.52px",
                }}
              >
                {currentInvoiceDetails.date_from}
              </span>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-xs-12">
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#2E2E2E",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.52px",
                  marginRight: "41px",
                }}
              >
                Vehicle{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.52px",
                }}
              >
                {currentInvoiceDetails.container_no}
              </span>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "5px" }}>
            <div className="col-xs-12 d-flex">
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#2E2E2E",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.52px",
                  marginRight: "21px",
                }}
              >
                Particulars{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.52px",
                }}
              >
                <li style={{ marginBottom: "10px" }}>Container no.: 26 CTN</li>
                <li style={{ marginBottom: "10px" }}>Weight: 442 kgs</li>
                <li style={{ marginBottom: "10px" }}>From: BALMER CFS-JNPT</li>
                <li style={{ marginBottom: "10px" }}>To: Vasai</li>
                <li>Return: JNPT</li>
              </span>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "5px" }}>
            <div className="col-xs-12">
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#2E2E2E",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.52px",
                  marginRight: "40px",
                }}
              >
                Freight{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.52px",
                }}
              >
                5000
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#2E2E2E",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.52px",
                  marginRight: "24px",
                }}
              >
                Extra Exp.{" "}
              </span>
              <span
                style={{
                  fontFamily: "Inter, sans-serif",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.52px",
                }}
              >
                1900
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const InvoiceRightbarSubBody = () => {
  return (
    <div className="container" style={{ padding: "10.91px 0px 10.91px 12px" }}>
      <div className="row" style={{ marginBottom: "8.73px" }}>
        <div className="col-xs-12">
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
              marginRight: "162px",
            }}
          >
            Total Amount
          </span>
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
            }}
          >
            6900
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
              marginRight: "182px",
            }}
          >
            Total Advance
          </span>
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
            }}
          >
            0
          </span>
        </div>
      </div>
      <hr style={{ marginTop: "13px", marginBottom: "4.82px" }} />
      <div className="row">
        <div className="col-xs-12">
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
              marginRight: "133px",
              marginLeft: "3px",
            }}
          >
            Total Balance
          </span>
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#2E2E2E",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16.94px",
            }}
          >
            INR 6900
          </span>
        </div>
      </div>
    </div>
  );
};
export const InvoiceRightbarFooter = () => {
  return (
    <div className="container" style={{ padding: "7.37px 9px 7.37px 9px" }}>
      <div className="row">
        <div className="col-xs-12 d-flex justify-content-center">
          <Button
            variant="outlined"
            style={{
              color: "#FFFFFF",
              backgroundColor: "rgba(9, 65, 78, 0.5)",
              borderColor: "#FFFFFF",
              textTransform: "capitalize",
              padding: "7.09px 14px 8.28px 16px",
              height: "35.46px",
              marginRight: "7px",
            }}
          >
            <img src={invoiceFooterEditIcon} style={{ marginRight: "7px" }} />
            <span
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
              }}
            >
              Edit Invoice
            </span>
          </Button>
          <Button
            style={{
              color: "#FFFFFF",
              backgroundColor: "#B66643",
              textTransform: "capitalize",
              padding: "7.09px 14px 8.28px 14px",
              height: "35.46px",
            }}
          >
            <span
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
              }}
            >
              Download Invoice
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
