import { Link } from "react-router-dom";
import "./login.scss";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { login, post, setAccessToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import GoogleIcon from "../../layout/assets/images/Login/google-icon.png";
import GateLogoIcon from "../../layout/assets/images/Gate/logo_img_black.png";
import LoginRegisterBanner from "../../layout/assets/images/Login/login-register-banner.svg";

import { AppContext } from "../../utils/state";
import SnackBar from "../../layout/components/Snackbar";

function Login() {
  const navigate = useNavigate();
  // const context = useContext(AppContext);
  // const { dispatch } = context;

  // const { state, dispatch } = useContext(AppContext);
  const { state, dispatch } = useContext(AppContext);

  // const context = useContext(AppContext);
  // const dispatch = (context as { dispatch: (action: any) => void }).dispatch;

  // const history = useHistory();  //history is the old one

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [alertData, setAlert] = useState({
    status: false,
    message: "",
    severity: "success",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await login("login", {
        email: formData.email,
        password: formData.password,
      });

      // const response = await post("login", {
      //   email: formData.email,
      //   password: formData.password,
      // });
      // if (response) {
      if (response.accessToken !== null) {
        // await localStorage.setItem("id_token", response.data.api_token);

        // if (response.accessToken) {
        //   await localStorage.setItem("accessToken", response.accessToken);
        // await localStorage.setItem("accessToken", response.accessToken);
        setAccessToken("JWT " + response.accessToken);

        await localStorage.setItem(
          "accessToken",
          "JWT " + response.accessToken
        );

        console.log(state);

        state.accessToken = response.accessToken;
        // state.accessToken = response.accessToken;
        state.user = response.user;
        state.isAuthenticated = true;
        dispatch({
          type: "setAuth",
          data: true,
        });
        console.log(state);
        navigate("/products");
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
        setAlert({
          status: true,
          message: "Login Sucessfull",
          severity: "success",
        });
      } else {
        // alert(response.data.message);
        console.log(response.data);
      }
      // history.push('/new-route');

      // Process the response from the API here
    } catch (err) {
      console.error(err);
      // alert("Login failed ")
      setAlert({ status: true, message: "Login Failed", severity: "error" });
    }
  };
  return (
    <>
      <SnackBar alertData={alertData} />
      <div className="row login-page">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 login-rt-cntnr">
          <img src={LoginRegisterBanner} alt="" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
          <div className="logo-cntr">
            <img
              src={GateLogoIcon}
              className="logo"
              alt="logo"
              style={{ width: "30%", height: "100%" }}
            />
          </div>
          <h1 className="heading login-heading">Sign In</h1>

          {/* <GoogleButton className="google-btn" >Google</GoogleButton> */}

          <form onSubmit={handleSubmit}>
            <TextField
              className="form email-field"
              id="outlined-basic"
              type="email"
              value={formData.email}
              onChange={(e: any) =>
                setFormData({ ...formData, email: e.target.value })
              }
              label="Email"
              variant="outlined"
            />
            <br />

            <TextField
              className="form password-field"
              id="outlined-basic"
              type="password"
              value={formData.password}
              onChange={(e: any) =>
                setFormData({ ...formData, password: e.target.value })
              }
              label="Password"
              variant="outlined"
            />
            <div className="forgot-password">
              <Link to="#" className="d-flex justify-content-center">
                forgot password
              </Link>
            </div>
            <br />

            <input type="submit" className="login-btn" value="Login"></input>
          </form>
          {/* <div className="login-google-option">
            <h1>or</h1>
            <button className="btn google-btn">
              <img src={GoogleIcon} alt="" className="google-icon" />
              <span> Sign in with Google</span>
            </button>
          </div> */}

          <p>
            New to Gate?
            <Link to={"/register"} className="sign-up">
              {" "}
              Create an account
            </Link>
          </p>
        </div>
        {/* col-6 end */}
      </div>
    </>
  );
}

export default Login;
