import ListModule from "../../../../layout/components/CRUD/List/List";

function List() {
  return (
    <ListModule
      module="products"
      modulePath="/products"
      primary_id="product_id"
    />
  );
}

export default List;
