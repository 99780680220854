import React from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import List from "./List";
import Header from "./Header";
import Summary from "./Summary";

import NewModule from "../../../../layout/components/CRUD/New";
import EditAdminUserDetails from "./edit";
import EditUserRoleDetails from "./edit";
import NewUserRole from "./New";

function UserRoles() {
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <Header /> */}
            <Outlet />
          </>
        }
      >
        <Route
          path="/list"
          element={
            <>
              <Header />
              <List />
            </>
          }
        />
        {/* <Route
          path="new-restaurant"
          element={
            <>
              <NewRestaurant />
            </>
          }
        /> */}
        {/* <Route
          path="details/*"
          element={
            <>
              <QueriesDetailsRoute className={""} />
            </>
          }
        /> */}
        {/* <Route
          path="edit-restaurant/:id"
          element={
            <>
              <EditQueriesDetails />
            </>
          }
        /> */}

        <Route
          path="new"
          element={
            <>
              <NewUserRole />
            </>
          }
        />
        <Route
          path="edit/:id"
          element={
            <>
              {/* <EditModule
                module={"queries"}
                modulePath="/queries"
                primary_key="_id"
              /> */}
              <EditUserRoleDetails />
            </>
          }
        />

        <Route path="/summary" element={<Summary />} />

        <Route index element={<Navigate to="/user-roles/list" />} />
      </Route>
    </Routes>
  );
}

export default UserRoles;
