import React, { createContext, useReducer } from "react";

let AppContext = createContext();

const initialState = {
  isAuthenticated: false,
  accessToken:null,
  user:{
    first_name:'',
    last_name:'',
  }
}
// state={count,isAuthenticated}

let reducer = (state, action) => {
  switch (action.type) {
    case "setAuth": {
      return { ...state, isAuthenticated: action.data }
    }
  }
  return state;
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };


  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };