import { useEffect, useState } from "react";
import { dashboardSummaryGet, get, issueReportsGet } from "../../../utils/api";
import "./dashboard.scss";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha,
  styled,
} from "@mui/material";
import InfoIcon from "../../../layout/assets/images/Dashboard/material-symbols_info-outline.svg";
import ListModule from "../../../layout/components/CRUD/List/List";
import NewUsersIcon from "../../../layout/assets/images/Dashboard/new_users.svg";
import NewCuisinesIcon from "../../../layout/assets/images/Dashboard/new_cuisines.svg";
import NewRestaurantsIcon from "../../../layout/assets/images/Dashboard/new_restaurant.svg";
import NewFavouritesIcon from "../../../layout/assets/images/Dashboard/new_favourites.svg";
import NewItemsIcon from "../../../layout/assets/images/Dashboard/new_items.svg";
import NewCitiesIcon from "../../../layout/assets/images/Dashboard/new_cities.svg";
import ReportsIcon from "../../../layout/assets/images/Dashboard/reports.svg";
import PendingActionsIcon from "../../../layout/assets/images/Dashboard/pending_actions.svg";
import GotoIcon from "../../../layout/assets/images/Dashboard/goto-btn-icon.svg";
import DateRangeComponent from "./DateRangeComponent";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";

const options = ["None", "Atria", "Callisto"];

const ITEM_HEIGHT = 48;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TodayBlock = (props: any) => {
  return (
    <div className="today-block">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <img src={props.image} className="me-2" alt="" />
        <p>{props.title} </p>
      </div>
      <div>
        <span>{props.count} </span>
      </div>
    </div>
  );
};

const TotalBlock = (props: any) => {
  return (
    <div className="total-block">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: "6px" }}
      >
        <p>{props.title} </p>
        <p>View</p>
      </div>
      <div style={{ marginBottom: "6px" }}>
        <span>{props.count} </span>
      </div>
      <div>
        <p className="total-block-text">Total {props.title}</p>
      </div>
    </div>
  );
};

export default function Dashboard() {
  useEffect(() => {
    dashboardSummary();
    issueReports();
    getApiLogsData();
  }, []);

  const [data, setData] = useState<any>(null);
  const [logsData, setLogsData] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dashboardSummary = async () => {
    try {
      const response = await dashboardSummaryGet("dashboard_summary", {});
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);

  const issueReports = async () => {
    try {
      const response = await issueReportsGet("customer_issue_reports", {});
    } catch (error) {
      console.log(error);
    }
  };

  const getApiLogsData = async () => {
    try {
      const response = await get("admin_api_logs", {});
      if (response) {
        console.log(response.data);
        setLogsData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateRange = async (dateRange: DateRange<Dayjs>) => {
    console.log(
      dateRange[0]?.toDate().toISOString(),
      dateRange[1]?.toDate().toISOString()
    );

    const startDate = dateRange[0]?.toDate() || null;
    const endDate = dateRange[1]?.toDate() || null;

    if (startDate && endDate) {
      getDatedDashboardSummary(startDate, endDate);
    }
  };

  const getDatedDashboardSummary = async (startDate: Date, endDate: Date) => {
    try {
      const response = await dashboardSummaryGet("dashboard_summary", {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });

      if (response) {
        console.log(response.data);
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="container"
      style={{
        paddingLeft: "38.5px",
        paddingTop: "36.5px",
        marginBottom: "50px",
      }}
    >
      <div className="row">
        <div className="col-xs-12">
          <h1>Dashboard</h1>
          {/* <div
            className="dashboard-heading mb-4 d-flex justify-content-between align-items-center"
            style={{ maxWidth: "921px" }}
          >
            <h3>Today</h3>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#FFFFFF",
                color: "#000000",
                textTransform: "capitalize",
                boxShadow: "0 2px 3px rgba(60, 66, 87, 0.8)",
                border: "1px solid #D5DBE1",
                " &:hover": {
                  bgcolor: "#FFFFFF",
                  boxShadow: "0 1px 1px rgba(0, 0, 0, 0.12)",
                },
                width: "90px",
                height: "30px",
              }}
            >
              <span className="customize-span">Customize</span>
            </Button>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-9 col-md-9">
              <div className="d-flex flex-wrap">
                <TodayBlock
                  image={NewUsersIcon}
                  title="New Users"
                  count={
                    data &&
                    data.todayCustomerSummary &&
                    data.todayCustomerSummary.length > 0
                      ? data.todayCustomerSummary[0].totalMembers
                      : 0
                  }
                />
                <TodayBlock
                  image={NewItemsIcon}
                  title="New Items"
                  count={
                    data &&
                    data.newItemCuisineSummary &&
                    data.newItemCuisineSummary.length > 0
                      ? data.newItemCuisineSummary[0].newDishes
                      : 0
                  }
                />
                <TodayBlock
                  image={NewRestaurantsIcon}
                  title="New Restaurants"
                  count={
                    data &&
                    data.todayRestaurantSummary &&
                    data.todayRestaurantSummary.length > 0
                      ? data.todayRestaurantSummary[0].restaurantCount
                      : 0
                  }
                />
                <TodayBlock
                  image={NewFavouritesIcon}
                  title="New Favourites"
                  count={
                    data &&
                    data.todayCustomerSummary &&
                    data.todayCustomerSummary.length > 0
                      ? data.todayCustomerSummary[0].totalDishes
                      : 0
                  }
                />
                <TodayBlock
                  image={NewCuisinesIcon}
                  title="New Cuisines"
                  count="42"
                />
                <TodayBlock
                  image={NewCitiesIcon}
                  title="New Cities"
                  count="42"
                />
                <TodayBlock
                  image={ReportsIcon}
                  title="Reports"
                  count={
                    data &&
                    data.reportQueriesSummary &&
                    data.reportQueriesSummary.length > 0
                      ? data.reportQueriesSummary[0].reportTotalCount
                      : 0
                  }
                />
              </div>
              <div
                className="dashboard-heading mb-4"
                style={{ maxWidth: "921px" }}
              >
                <h3>Total Summary</h3>
                <DateRangeComponent handleDateRange={handleDateRange} />
              </div>
              <div className="d-flex flex-wrap">
                <TotalBlock
                  image={NewUsersIcon}
                  title="Users"
                  count={
                    data &&
                    data.customerSummary &&
                    data.customerSummary.length > 0
                      ? data.customerSummary[0].totalMembers
                      : 0
                  }
                />
                <TotalBlock
                  image={NewItemsIcon}
                  title="Items"
                  count={
                    data &&
                    data.customerSummary &&
                    data.customerSummary.length > 0
                      ? data.customerSummary[0].totalDishes
                      : 0
                  }
                />
                <TotalBlock
                  image={NewRestaurantsIcon}
                  title="Restaurants"
                  count={
                    data &&
                    data.restaurantSummary &&
                    data.restaurantSummary.length > 0
                      ? data.restaurantSummary[0].restaurantCount
                      : 0
                  }
                />
                <TotalBlock
                  image={NewFavouritesIcon}
                  title="Favourites"
                  count="42"
                />
                <TotalBlock
                  image={NewCuisinesIcon}
                  title="Cuisines"
                  count={
                    data &&
                    data.restaurantSummary &&
                    data.restaurantSummary.length > 0
                      ? data.restaurantSummary[0].totalCuisineCount
                      : 0
                  }
                />
                <TotalBlock image={NewCitiesIcon} title="Cities" count="42" />
                <TotalBlock image={ReportsIcon} title="Reports" count="42" />
              </div>
            </div>

            <div className="col-xs-12 col-lg-3 col-md-3">
              <Card sx={{ border: "1px solid #D5DBE1", boxShadow: "none" }}>
                <div className="d-flex align-items-center pending-card-head">
                  <img src={PendingActionsIcon} alt="" />
                  <p>Pending task</p>
                </div>
                <div className="pending-sub-card">
                  <h4>Pending dish approval</h4>
                  <h6>
                    {data &&
                    data.itemApprovalSummary &&
                    data.itemApprovalSummary.length > 0
                      ? data.itemApprovalSummary[0].newDishes
                      : 0}{" "}
                    new dishes pending for approval
                  </h6>
                  <Button
                    className="d-flex"
                    sx={{
                      textTransform: "capitalize",
                      padding: 0,
                      minWidth: 0,
                    }}
                  >
                    <p className="me-1">View</p>
                    <img src={GotoIcon} alt="" />
                  </Button>
                </div>
                <div className="pending-sub-card">
                  <h4>Pending report actions</h4>
                  <h6>12 new reports pending for action</h6>
                  <Button
                    className="d-flex"
                    sx={{
                      textTransform: "capitalize",
                      padding: 0,
                      minWidth: 0,
                    }}
                  >
                    <p className="me-1">View</p>
                    <img src={GotoIcon} alt="" />
                  </Button>
                </div>
                <div style={{ padding: "16px" }}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      bgcolor: "#005D72",
                      color: "#FFFFFF",
                      width: "100%",
                      height: "28px",
                      "&:hover": {
                        bgcolor: "#09414E",
                      },
                    }}
                  >
                    <span className="viewall-btn-dash">View All</span>
                  </Button>
                </div>
              </Card>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
