import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import Tabswitch from "../../../../layout/components/Tabswitch/Tabswitch";
import ItemDetails from "../Details/details";

export default function RestaurantTabs(props: any) {
  const location = useLocation();
  const { id } = useParams();

  let tabData = [
    {
      tabName: "Item Category Details",
      // tabLink: "/restaurant-details/" + id,
      // tabComponent: <ItemDetails />,
    },
  ];

  return (
    <>
      <Tabswitch tabData={tabData} modal={props.modal} />
    </>
  );
}
