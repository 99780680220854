import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProfileIcon from "../../../../layout/assets/images/DummyImages/pexels-dummy.jpg";
import "./index.scss";

import moment from "moment";
import { AppContext } from "../../../../utils/state";
import { getProfile } from "../../../../utils/api";

function Profile() {
  const { state, dispatch } = useContext(AppContext);
  const { id } = useParams();
  console.log("euiyeuyir", id);

  useEffect(() => {
    if (!state.user._id) {
      fetchProfile();
    }
  }, []);

  const fetchProfile = async () => {
    console.log("hhhh");
    let data = await getProfile();
    console.log(data);
    state.user = data.user;
    state.isAuthenticated = true;
    dispatch({
      type: "setAuth",
      data: true,
    });
  };

  const capitalize = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  console.log("state", state.user);

  return (
    <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
      <CardHeader
        // title="Profile"
        // action={
        //   <Button
        //     color="secondary"
        //     variant="contained"
        //     // startIcon={<EditIcon />}
        //     component={Link}
        //     to="/edit"
        //   >
        //     Edit
        //   </Button>
        // }
        sx={{ bgcolor: "#db561e", height: "125px", position: "relative" }}
      />
      <CardContent>
        <div className="row">
          <div
            className="col-xs-12 header-image"
            style={{ position: "absolute", top: "145px", width: "auto" }}
          >
            <img alt="Remy Sharp" src={ProfileIcon} />
          </div>
          <div className="row profile-info">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className="mb-3"
              >
                <strong>{`${capitalize(state.user.first_name)} ${capitalize(
                  state.user.last_name
                )}`}</strong>
                <Typography variant="body1" color="textSecondary" component="p">
                  {state.user.email}
                </Typography>
              </Typography>

              <Typography variant="body1" color="textSecondary" component="p">
                Created: {moment(state.user.created).format("DD-MMM-YYYY")}
              </Typography>

              <Typography variant="body1" color="textSecondary" component="p">
                Status: {state.user.status}
              </Typography>

              <Typography variant="body1" color="textSecondary" component="p">
                Role: {state.user.role}
              </Typography>
              {/* <Button
          variant="contained"
          color="primary"
          onClick={toggleTheme}
          style={{ marginTop: "1rem" }}
        >
          Toggle Theme
        </Button> */}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6 text-end">
              <Button
                color="secondary"
                variant="contained"
                // startIcon={<EditIcon />}
                component={Link}
                to={`/admin/admin-users/edit/${state.user.admin_user_id}`}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default Profile;
