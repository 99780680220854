import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./index.scss";

export default function Breadcrumb() {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((x) => x);

  console.log(paths);

  return (
    <Breadcrumbs
      separator=">"
      aria-label="breadcrumb"
      className="breadcrumb-main"
    >
      <Link color="inherit" href="/" className="first-breadcrumb">
        Home
      </Link>
      {paths.map((path, index) => {
        const last = index === paths.length - 1;
        const to = `/${paths.slice(0, index + 1).join("/")}`;

        return last ? (
          <span key={to} style={{ textTransform: "capitalize" }}>
            {path}
          </span>
        ) : (
          <Link
            color="inherit"
            href={to}
            key={to}
            style={{ textTransform: "capitalize" }}
          >
            {path}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
