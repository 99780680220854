import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LocationMarkerIcon from "../../../assets/images/Tabledata/location_marker.svg";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";

function NewRemarkModal(props: any) {
  return (
    <Modal
      open={props.openNewRemarkModal}
      onClose={props.handleNewRemarkModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={props.style}>
        <>
          <div className="d-flex mb-3 justify-content-end">
            <Button
              sx={{ minWidth: 0 }}
              className="p-0"
              onClick={props.handleNewRemarkModalClose}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="newReassignRemark">Add New Remark</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup className="d-flex">
                <TextField
                  fullWidth
                  id="newReassignRemark"
                  // label="Remark"
                  variant="outlined"
                  className="mb-3"
                  name="newReassignRemark"
                  onChange={props.handleNewReassignRemark}
                  value={props.newReassignRemark || ""}
                />
                <Button
                  variant="contained"
                  onClick={props.AddNewReassignRemark}
                >
                  Create Remark
                </Button>
              </FormGroup>
            </div>
          </div>
        </>
      </Box>
    </Modal>
  );
}

export default NewRemarkModal;
