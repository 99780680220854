import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { get, put } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, MenuItem, Select } from "@mui/material";
import bcrypt from "bcryptjs";

type Props = {};

function EditAdminUserDetails() {
  let { id } = useParams();
  // const { id } = useParams();
  const navigate = useNavigate();
  // console.log(id);
  // })
  const [formData, setFormData] = useState({
    // realm: "",
    // username: "",
    password: "",
    email: "",
    // emailVerified: "",
    // user_id: 0,
    first_name: "",
    last_name: "",
    // password_confirmation: "",
    role: "",
    user_role: "",
    user_role_id: 0,
    status: "",
    status_id: 0,
  });

  useEffect(() => {
    getAdminUserDetails();
    getUserRoles();
  }, []);

  const [selectedUserRole, setSelectedUserRole] = useState<any>(0);
  const [userRoles, setUserRoles] = useState<any>();

  const getUserRoles = async () => {
    try {
      const response = await get("user_roles", {});

      if (response) {
        console.log("userRoles", response);
        setUserRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminUserDetails = async () => {
    var params = {};

    await get(`admin_users/` + id, {})
      .then((data: any) => {
        // let adminUserData = data.data.data;
        // console.log(adminUserData);
        setFormData(data.data);
        setSelectedUserRole(data.data.user_role_id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);

  const handleUserRoleChange = (event: any) => {
    console.log("event", event.target.value);
    setSelectedUserRole(event.target.value);
    setFormData({
      ...formData,
      user_role_id: Number(event.target.value),
      user_role: userRoles.find(
        (item: any) => item.user_role_id === Number(event.target.value)
      ).user_role,
    });
  };

  const handleUpdateAdminUser = async (e: any) => {
    e.preventDefault();
    console.log(e);

    let adminUserDetails = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      password: formData.password,
      email: formData.email,
      user_role: formData.user_role,
      user_role_id: formData.user_role_id,
      status: formData.status,
      status_id: formData.status_id,
    };
    console.log(adminUserDetails);

    try {
      var params = {
        admin_user_id: Number(id),
        data: adminUserDetails,
      };

      put(`admin_users/${id}`, adminUserDetails).then(function (data) {
        console.log(data);
        if (data) {
          navigate(`/admin-users/list`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>Edit Admin User</h5>
          </div>
        </div>
        <form onSubmit={handleUpdateAdminUser} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="firstName"
                  className="reg-input name-field"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="lastName">Last Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="lastName"
                  className="reg-input name-field"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Last Name"
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="email"> Email </label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="email"
                  className="reg-input name-field"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="email"
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="lastName">Status</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <Select
                  fullWidth
                  required
                  id="status"
                  className="reg-input name-field"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  placeholder="Status"
                >
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                </Select>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userRole">User Role</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              {userRoles && (
                <FormControl fullWidth style={{ width: "50%" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="userRole"
                    size="small"
                    value={selectedUserRole}
                    onChange={handleUserRoleChange}
                  >
                    {userRoles.map((e: any) => (
                      <MenuItem key={e.user_role_id} value={e.user_role_id}>
                        {e.user_role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default EditAdminUserDetails;
