import ListModule from "../../../../layout/components/CRUD/List/List";

function List() {
  return (
    <ListModule
      module="product_categories"
      modulePath="/product-categories"
      primary_id="product_category_id"
    />
  );
}

export default List;
