import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import List from "./List";
import Header from "./Header";
// import Summary from "./Summary";
import NewItem from "./New/index";
import EditItemDetails from "./edit";
import NewModule from "../../../layout/components/CRUD/New";
import EditModule from "../../../layout/components/CRUD/Edit";
import ItemDetailsRoute from "./Details/index";
import ItemCategoryDetailsRoute from "./Details/index";
import EditItemCategoryDetails from "./edit";
import EditProductCategoryDetails from "./edit";
import ProductCategoryDetailsRoute from "./Details/index";
import NewProductCategory from "./New/index";

function ProductCategories() {
  const location = useLocation();

  return (
    <Routes>
      <Route
        element={
          <>
            {/* {location.pathname.includes("/items/edit-item") ? (
              <></>
            ) : (
              <Header />
            )} */}
            <Outlet />
          </>
        }
      >
        <Route
          path="/list"
          element={
            <>
              <Header />

              <List />
            </>
          }
        />

        <Route
          path="new"
          element={
            <>
              <NewProductCategory />
            </>
          }
        />
        <Route
          path="edit/:id"
          element={
            <>
              {/* <EditModule
                module={"items"}
                modulePath="/items"
                primary_key="item_id"
              /> */}
              <EditProductCategoryDetails />
            </>
          }
        />

        <Route
          path="details/:id"
          element={
            <>
              <ProductCategoryDetailsRoute className={""} />
            </>
          }
        />

        {/* <Route path="/summary" element={<Summary />} /> */}

        <Route index element={<Navigate to="/product-categories/list" />} />
      </Route>
    </Routes>
  );
}

export default ProductCategories;
