import * as React from "react";
import "./Navbar.scss";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import CustomizedMenus from "../Dropdown/Dropdown";
import Dropdown from "../Dropdown/Dropdown";
import DropdownIcon from "../Dropdown/DropdownIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/state";
import { getProfile } from "../../../utils/api";
import AppsDropdown from "../AppsDropdown";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const profileData = [
  {
    key: "Profile",
    value: "Profile",
  },
  // {
  //   key: "Settings",
  //   value: "Settings",
  // },
  {
    key: "Logout",
    value: "Logout",
  },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

export default function Navbar(props: any) {
  useEffect(() => {
    fetchProfile();
  }, []);
  const { state, dispatch } = useContext(AppContext);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("id_token", "");
    localStorage.setItem("accessToken", "");
    setTimeout(() => {
      navigate("/login");
    }, 500);
  };

  const fetchProfile = async () => {
    let data = await getProfile();
    state.user = data.user;
    state.admin_user_id = data.user.admin_user_id;
    state.isAuthenticated = true;
    dispatch({
      type: "setAuth",
      data: true,
    });
  };

  const handleProfile = (e: any) => {
    navigate(`/profile/${state.admin_user_id}`);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handleScreen = () => {
    // document.querySelector("body").style.overflow = "auto";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="navbar-header">
        <Toolbar className="navbar-height">
          <Button className="delnie-logo">
            <img
              src={
                require("../../assets/images/Gate/logo_img_black.png").default
              }
              alt=""
            />
          </Button>
          {window.matchMedia("(max-width: 992px)").matches ? (
            <AppsDropdown
              name="Web Admin"
              imagePath={
                require("../../assets/images/Navbar/apps-grid.svg").default
              }
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn"
              endIcon=""
              spanclass="span-spacing"
              onClick={handleScreen}
            />
          ) : (
            <AppsDropdown
              name="Web Admin"
              imagePath={
                require("../../assets/images/Navbar/apps-grid.svg").default
              }
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn"
              endIcon=""
              spanclass="span-spacing"
            />
          )}

          <Box sx={{ flexGrow: 1.25 }} />

          <Box className="nav-search-main" sx={{ flexGrow: 1 }}>
            <Search className="nav-search-bar">
              <StyledInputBase
                className="nav-search-input"
                placeholder="Search…"
                inputProps={{
                  "aria-label": "search",
                }}
              />
              <div className="search-end d-none d-md-block">[option + s]</div>
            </Search>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="notif-profile-section"
          >
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              {/* <Badge badgeContent={17} color="error"> */}
              <NotificationsIcon />
              {/* </Badge> */}
            </IconButton>
            <Dropdown
              // name={state?.user?.first_name}
              name={props.user.first_name}
              menuData={profileData}
              imagePath={
                require("../../assets/images/Navbar/profile-icon.svg").default
              }
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn pro-acc-btn"
              // endIcon={<DropdownIcon />}
              endIcon={<DropdownIcon iconColor="white" />}
              spanclass={"span-spacing"}
              customIcon={<LogoutIcon />}
              customText="Logout"
              customFunction={handleLogout}
              profileFunction={handleProfile}
              handleFieldClick={handleFieldClick}
            />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
