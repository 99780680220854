import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { awsupload, get, patch } from "../../../../utils/api";
import DishImage from "../../../../layout/assets/images/DummyImages/What-to-eat-in-Italy.png";
import "./details.scss";
import imageCompression from "browser-image-compression";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

function ProductDetails() {
  const { id } = useParams();

  const navigate = useNavigate();
  console.log(id);

  let item_id = id;

  const [formData, setFormData] = useState({
    _id: "",
    item_id: 0,
    item_name: "",
    cuisine_name: "",
    image_url: "",
    image_id: "",
    food_type: "",
    timing: "",
    item_description: "",
    views: 0,
    fvrt_profiles: [],
    fvrt_profiles_count: 0,
    followers: [],
    followers_count: 0,
  });

  const [sliderData, setSliderData] = useState<Array<any>>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterData, setFilterData] = React.useState<any>([]);
  const [sortBy, setSortBy] = useState<any>({ item_id: 1 });

  const [imageLoading, setImageLoading] = useState(false);

  let [image_name, setImageName] = useState("");
  let [imageFile, setImagefile] = useState<any>();
  // let [image_url, setImageUrl] = useState<any>();
  // let [image_id, setImageId] = useState<any>();

  const onChangeHandler = async (event: any, e: any) => {
    console.log(e);

    console.log("converted------------file", event.target.files[0]);
    setImagefile(event.target.files[0]);
    uploadImage(event, e);
  };

  const uploadImage = async (event: any, e: any) => {
    // let file_id: any;
    let folder_name = "items";
    let collection_name = "items";
    setImageLoading(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        item_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          formData.image_url = res.data.image_url;
          formData.image_id = res.data.image_id;
          // setImageUrl(image_url);
          // setImageId(image_id);
          setFormData((prevData) => ({
            ...prevData,
            image_url: formData.image_url,
            image_id: formData.image_id,
          }));

          if (formData.image_url && formData.image_id) {
            e["image_url"] = formData.image_url;
            e["image_id"] = formData.image_id;
            console.log(formData.image_url, formData.image_id);
            UpdateData(e);
          }
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const UpdateData = async (e: any) => {
    // e.preventDefault();
    let collection_name = "items";
    let file_id = formData.item_id;

    try {
      await patch(`${collection_name}/${file_id}`, formData);
      setImageLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(formData);

  const getAllItems = async () => {
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
      sortBy: sortBy,
    };

    await get("items", params)
      .then((response: any) => {
        let stateData = response.data.data;
        setRowsPerPage(stateData.length + 10);
        setSliderData(stateData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDetails = () => {
    var params = {};
    // params = { item_id: id };

    get("items/" + id, params)
      .then((response: any) => {
        let stateData = response.data;
        setFormData(stateData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(
    "silderData",
    sliderData.slice(Number(formData.item_id) - 1, Number(formData.item_id) + 9)
  );

  useEffect(() => {
    getItemDetails();
    getAllItems();
  }, [formData.item_id]);

  console.log(formData);
  const handleItem = (e: any) => {
    console.log("rrr", e.item_id);
    navigate("/items/details/" + e.item_id);
  };
  return (
    <div
      className="container"
      style={{ marginTop: "5px", paddingLeft: "20px" }}
    >
      <div className="row"></div>
      <div className="item-details-main-cntnr">
        <div className="row mt-3 mb-3">
          <div className="col-4">
            <span>Id : </span>

            <span> {formData._id}</span>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-3 m-auto ${
              imageLoading || !formData.image_url
                ? "d-flex justify-content-center align-items-center"
                : ""
            }`}
          >
            {imageLoading || !formData.image_url ? (
              <CircularProgress size={100} />
            ) : (
              <img src={formData.image_url} alt="" className="rest-dish-img2" />
            )}
          </div>
          <div className="col-4">
            <div className="row mt-3 mb-3">
              <div className="col-4">
                <span>Item id</span>
              </div>
              <div className="col-8">
                <span>{formData.item_id}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4">
                <span>Item Name</span>
              </div>
              <div className="col-8">
                <span>{formData.item_name}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4">
                <span>Cuisine Name</span>
              </div>
              <div className="col-8">
                <span>{formData.cuisine_name}</span>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-4">
                <span>Food Type</span>
              </div>
              <div className="col-8">
                <span>{formData.food_type}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4">
                <span>Timing</span>
              </div>
              <div className="col-8">
                <span>{formData.timing}</span>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="row mt-3 mb-3">
              <div className="col-8">
                <span>Views</span>
              </div>
              <div className="col-4">
                <span>{formData.views}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-8">
                <span>Followers</span>
              </div>
              <div className="col-4">
                <span>{formData.followers}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-8">
                <span>Favourited Profiles</span>
              </div>
              <div className="col-4">
                <span>{formData.fvrt_profiles}</span>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-8">
                <span>Followers Count</span>
              </div>
              <div className="col-4">
                <span>{formData.followers_count}</span>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-8">
                <span>Favourited Profile Count</span>
              </div>
              <div className="col-4">
                <span>{formData.fvrt_profiles_count}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="row ">
                <label className="btn upload-img">
                  <input
                    className="input-image"
                    accept="image/*"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => onChangeHandler(event, event)}
                  />
                  <span>Upload picture</span>
                </label>
              </div>
              <div className="row delete-img">
                <span>Delete picture</span>
              </div>
            </div>
            <div className="col-9">
              <div className="row mt-3 mb-3">
                <div className="col-2">
                  <span>Description</span>
                </div>
                <div className="col-10">
                  <span>{formData.item_description}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <span>Next Item</span>
      </div>

      <div className="row scroll-row">
        {sliderData !== undefined &&
          sliderData !== null &&
          sliderData
            .slice(Number(formData.item_id) - 1, Number(formData.item_id) + 9)
            .map((item: any) => {
              return (
                <div className="col-3 col-box" onClick={() => handleItem(item)}>
                  <div className="row">
                    <div className="col-3 col-card-image">
                      <img src={DishImage} alt="" className="rest-dish-img" />
                    </div>
                    <div className="col-9">
                      <div>
                        <span>{item.item_name}</span>
                      </div>

                      <div>
                        <span>Item Id: {item.item_id}</span>
                      </div>
                      <div>
                        <span>Cuisine Name: {item.cuisine_name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <div className="row"></div>
    </div>
  );
}

export default ProductDetails;
