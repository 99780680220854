import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import List from "./List";
import Header from "./Header";
import BrandDetailsRoute from "./Details";
import EditBrandDetails from "./edit";
import NewBrand from "./New";

function Brands() {
  const location = useLocation();

  return (
    <Routes>
      <Route
        element={
          <>
            {/* {location.pathname.includes("/items/edit-item") ? (
              <></>
            ) : (
              <Header />
            )} */}
            <Outlet />
          </>
        }
      >
        <Route
          path="/list"
          element={
            <>
              <Header />

              <List />
            </>
          }
        />

        <Route
          path="new"
          element={
            <>
              <NewBrand />
            </>
          }
        />
        <Route
          path="edit/:id"
          element={
            <>
              {/* <EditModule
                module={"items"}
                modulePath="/items"
                primary_key="item_id"
              /> */}
              <EditBrandDetails />
            </>
          }
        />

        <Route
          path="details/:id"
          element={
            <>
              <BrandDetailsRoute className={""} />
            </>
          }
        />

        {/* <Route path="/summary" element={<Summary />} /> */}

        <Route index element={<Navigate to="/brands/list" />} />
      </Route>
    </Routes>
  );
}

export default Brands;
