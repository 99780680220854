import "./register.scss";
import { FormGroup, TextField, Button, Checkbox } from "@mui/material";
import { patch, post, register } from "../../utils/api";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleIcon from "../../layout/assets/images/Login/google-icon.png";
import GateLogoIcon from "../../layout/assets/images/Gate/logo_img_black.png";
import LoginRegisterBanner from "../../layout/assets/images/Login/login-register-banner.svg";
import { Alert } from "@mui/material";
import SnackbarAlert from "../common/sanckbar";

function Register() {
  const navigate = useNavigate();
  const [regData, setRegData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    user_role: "analyst",
    user_role_id: 1,
    status: "inactive",
    status_id: 0,
  });

  const [errorState, setErrorState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    user_role: "analyst",
    user_role_id: 1,
    status: "inactive",
    status_id: 0,
  });

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [message, setMessage] = useState("");

  const handlePost = async (e: any) => {
    e.preventDefault();

    const response = await register("register", {
      first_name: regData.first_name,
      last_name: regData.last_name,
      email: regData.email,
      password: regData.password,
      user_role: regData.user_role,
      user_role_id: regData.user_role_id,
      status: regData.status,
      status_id: regData.status_id,
    });
    if (response) {
      alert(
        response.message ? response.message : "Registered Successfully!",
        "success"
      );
      setTimeout(() => navigate("/login"), 500);
    } else {
      alert(
        response.message ? response.message : "Something went wrong!",
        "warning"
      );
    }
  };

  const validateForm = () => {
    const newErrorState = { ...errorState };

    if (!regData.email) {
      newErrorState.email = 'Email is required  className="reg-input"';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(regData.email)
    ) {
      newErrorState.email = "Invalid email address";
    } else {
      newErrorState.email = "";
    }

    if (!regData.first_name) {
      newErrorState.first_name =
        'First name is required  className="reg-input"';
    } else {
      newErrorState.first_name = "";
    }

    if (!regData.last_name) {
      newErrorState.last_name = 'Last name is required  className="reg-input"';
    } else {
      newErrorState.last_name = "";
    }

    if (!regData.password) {
      newErrorState.password = 'Password is required  className="reg-input"';
    } else {
      newErrorState.password = "";
    }

    setErrorState(newErrorState);

    return Object.values(newErrorState).every((error) => error === "");
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setRegData({
      ...regData,
      [name]: value,
    });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={message}
        severity={alert_severity}
      />
      <div className="row register">
        <div className="col-lg-6 col-md-6 col-xs-12 lft-cntnr">
          <img src={LoginRegisterBanner} alt="" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-cntnr text-center register-right-section">
          <img
            src={GateLogoIcon}
            className="logo"
            alt="logo"
            style={{ width: "25%" }}
          />
          <span className="company-name-first-part"> Delnie </span>{" "}
          <span className="company-name-second-part"> Books</span>
          <h1 className="heading login-heading">Sign Up</h1>
          <form className="form" onSubmit={handlePost}>
            <FormGroup>
              <TextField
                label="First Name"
                required
                className="reg-input name-field"
                name="first_name"
                value={regData.first_name}
                onChange={handleChange}
                error={Boolean(errorState.first_name)}
                helperText={errorState.first_name}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Last Name"
                required
                className="reg-input name-field"
                name="last_name"
                value={regData.last_name}
                onChange={handleChange}
                error={Boolean(errorState.last_name)}
                helperText={errorState.last_name}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Email"
                type="email"
                required
                className="reg-input email-field"
                name="email"
                value={regData.email}
                onChange={handleChange}
                error={Boolean(errorState.email)}
                helperText={errorState.email}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Password"
                type="password"
                required
                className="reg-input password-field"
                name="password"
                value={regData.password}
                onChange={handleChange}
                error={Boolean(errorState.password)}
                helperText={errorState.password}
              />
            </FormGroup>
            <div className="d-flex justify-content-start align-items-center conditions-check-container">
              <Checkbox
                value="checkedA"
                inputProps={{
                  "aria-label": "Checkbox A",
                }}
                sx={{
                  borderWidth: "0.5px",
                }}
              />
              <span>I agree to Delnie’s terms and conditions</span>
            </div>
            <FormGroup className="rgstr-btn text-center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="register-btn"
              >
                <span> Get Started</span>
              </Button>
            </FormGroup>
          </form>
          <div className="d-flex justify-content-center align-items-center">
            <p>
              Have an account?
              <Link to={"/login"} className="sign-up">
                {" "}
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
