import ListModule from "../../../../../layout/components/CRUD/List/List";

function AdminUserList() {
  return (
    <ListModule
      module="admin_users"
      modulePath="/admin-users"
      primary_id="admin_user_id"
    />
  );
}

export default AdminUserList;
