import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { admin_ts_post, post } from "../../../../../utils/api";
import Button from "@mui/material/Button";
import SnackbarAlert from "../../../../../modules/common/sanckbar";

function RestaurantChainModal(props: any) {
  console.log("newCheckedReal", props);

  const findLongestCommonSubstring = (array: any) => {
    if (!array || array.length < 2) return null;

    const longestSubstring = (str1: any, str2: any) => {
      const memo = Array(str1.length + 1)
        .fill(null)
        .map(() => Array(str2.length + 1).fill(0));
      let longest = 0;
      let endIndex = 0;

      for (let i = 1; i <= str1.length; i++) {
        for (let j = 1; j <= str2.length; j++) {
          if (str1[i - 1] === str2[j - 1]) {
            memo[i][j] = memo[i - 1][j - 1] + 1;
            if (memo[i][j] > longest) {
              longest = memo[i][j];
              endIndex = i - 1;
            }
          } else {
            memo[i][j] = 0;
          }
        }
      }

      return str1.substring(endIndex - longest + 1, endIndex + 1);
    };

    let commonSubstring = array[0];

    for (let i = 1; i < array.length; i++) {
      commonSubstring = longestSubstring(commonSubstring, array[i]);
    }

    if (commonSubstring && array.length >= 2) {
      return commonSubstring;
    } else {
      return null;
    }
  };

  const result = findLongestCommonSubstring(props.checkedRestaurants);

  function findCommonCityId(arr: any) {
    // Create an object to store counts of each number
    const counts: any = {};

    // Loop through the array and count occurrences of each number
    for (const num of arr) {
      counts[num] = (counts[num] || 0) + 1;
    }

    // Find the number with count > 1
    for (const num in counts) {
      if (counts[num] > 1) {
        return parseInt(num);
      }
    }

    // Return null if no number is repeated
    return null;
  }

  const cityId = findCommonCityId(props.checkedCities);

  console.log("newExpectedResult", cityId);

  const createRestaurantChain = async () => {
    console.log("newAssistant", cityId, props.checked, result);
    const newData = {
      city_id: cityId,
      restaurant_ids: props.checked,
      chain_name: result,
    };

    //convert chain_name to camelcase to avoid duplication

    try {
      const response = await admin_ts_post("restaurantChain", newData);
      if (response) {
        console.log("newCreatedResponse", response);
        alert("Restaurant chain updated successfully", "success");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong!", "warning");
    }
  };

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />

      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={props.style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Restaurant Chain
          </Typography>
          <div>
            <h6>
              <strong>City Id:</strong> {cityId}
            </h6>
            <div>
              <ul>
                <strong>Selected Restaurant Ids:</strong>{" "}
                {props.checked.map((e: any, index: any) => (
                  <li>{e}</li>
                ))}
              </ul>
            </div>
            <h6>
              <strong>Restaurant Chain Name:</strong> {result}
            </h6>
          </div>
          <Button variant="contained" onClick={createRestaurantChain}>
            Update
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RestaurantChainModal;
