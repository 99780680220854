import {
  Autocomplete,
  Button,
  Card,
  FormControl,
  FormGroup,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { custom_patch, get, patch } from "../../../../utils/api";
import { AppContext } from "../../../../utils/state";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../../../modules/common/sanckbar";
import LocationMarkerIcon from "../../../assets/images/Tabledata/location_marker.svg";

const restaurantStatus = [
  {
    label: "solved",
    value: "solved",
  },
  {
    label: "hold",
    value: "hold",
  },
  {
    label: "pending",
    value: "pending",
  },
];

function StatusUpdater(props: any) {
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [data, setData] = useState<any>([]);
  const [cuisineData, setCuisineData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCuisine, setSelectedCuisine] = useState<any>({});
  const [selectedFoodType, setSelectedFoodType] = useState<any>({});
  const [foodTypeData, setFoodTypeData] = useState<any>([]);

  const [itemCategories, setItemCategories] = useState<any>([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState<any>("");
  const [itemCategoryData, setItemCategoryData] = useState<any>({
    item_category_name: "",
  });
  console.log("restaurantprops", data, props);
  const { state, dispatch } = useContext(AppContext);
  const [statusValues, setStatusValues] = useState<any>([
    { status_id: 0, status: "Inactive" },
    { status_id: 1, status: "Active" },
  ]);
  const [selectedStatusValue, setSelectedStatusValue] = useState<any>(0);
  const [selectedApprovedByValue, setSelectedApprovedByValue] =
    useState<any>(0);

  const [restaurantStatusValues, setRestaurantStatusValues] = useState<any>([
    { status_id: 0, status: "pending" },
    { status_id: 1, status: "approved" },
    { status_id: 2, status: "hold" },
  ]);

  const [favItemStatusValues, setFavItemStatusValues] = useState<any>([
    { status_id: 0, status: "pending" },
    { status_id: 1, status: "approved" },
    { status_id: 2, status: "hold" },
  ]);

  const [selectedRestaurantStatusValue, setSelectedRestaurantStatusValue] =
    useState<any>(0);

  const [
    selectedRestaurantApprovedByValue,
    setSelectedRestaurantApprovedByValue,
  ] = useState<any>(0);

  const [selectedFavItemStatusValue, setSelectedFavItemStatusValue] =
    useState<any>(0);

  const [selectedFavItemApprovedByValue, setSelectedFavItemApprovedByValue] =
    useState<any>(0);

  useEffect(() => {
    if (!props.openItem && !props.openRestaurant && !props.openFavItems) {
      setData([]);
    } else {
      setSelectedApprovedByValue(props.itemModalData?.approved_by_id);
      if (props.type === "items") {
        getFoodTypes();
        setData(props.itemModalData);
        setSelectedCuisine({
          cuisine_id: props.itemModalData.cuisine_id,
          cuisine_name: props.itemModalData.cuisine_name,
        });
        setSelectedFoodType({
          food_type_id: props.itemModalData.food_type_id,
          food_type: props.itemModalData.food_type,
        });
        setSelectedItemCategory(props.itemModalData.item_category);
      } else if (props.type === "restaurants") {
        getFoodTypes();
        setData(props.restaurantModalData);
        setSelectedCuisine({
          cuisine_id:
            props.restaurantModalData &&
            props.restaurantModalData.cuisines &&
            props.restaurantModalData.cuisines.length > 0 &&
            props.restaurantModalData.cuisines[0].cuisine_id,
          cuisine_name:
            props.restaurantModalData &&
            props.restaurantModalData.cuisines &&
            props.restaurantModalData.cuisines.length > 0 &&
            props.restaurantModalData.cuisines[0].cuisine_name,
        });
        setSelectedFoodType({
          food_type_id: props.restaurantModalData.food_type_id,
          food_type: props.restaurantModalData.food_type,
        });
      } else {
        setData(props.favItemModalData);
      }
    }
  }, [props.itemModalData, props.restaurantModalData, props.favItemModalData]);

  const [approvedByValues, setApprovedByValues] = useState<any>([
    { approved_by_id: 0, approved_by: "pending" },
    { approved_by_id: 1, approved_by: "level 1" },
    { approved_by_id: 2, approved_by: "level 2" },
    { approved_by_id: 3, approved_by: "level 3" },
  ]);

  const [restaurantApprovedByValues, setRestaurantApprovedByValues] =
    useState<any>([
      { approved_by_id: 0, approved_by: "pending" },
      { approved_by_id: 1, approved_by: "level 1" },
      { approved_by_id: 2, approved_by: "level 2" },
      { approved_by_id: 3, approved_by: "level 3" },
    ]);

  const [favItemApprovedByValues, setFavItemApprovedByValues] = useState<any>([
    { approved_by_id: 0, approved_by: "pending" },
    { approved_by_id: 1, approved_by: "level 1" },
    { approved_by_id: 2, approved_by: "level 2" },
    { approved_by_id: 3, approved_by: "level 3" },
  ]);

  const handleApprovedByChange = (event: any) => {
    setSelectedApprovedByValue(Number(event.target.value));
    setData({
      ...data,
      approved_by_id: Number(event.target.value),
      approved_by: approvedByValues.find(
        (item: any) => item.approved_by_id == Number(event.target.value)
      ).approved_by,
      approver_name: state.user.first_name,
    });
  };

  const handleRestaurantApprovedByChange = (event: any) => {
    setSelectedRestaurantApprovedByValue(Number(event.target.value));
    setData({
      ...data,
      approved_by_id: Number(event.target.value),
      approved_by: restaurantApprovedByValues.find(
        (item: any) => item.approved_by_id == Number(event.target.value)
      ).approved_by,
      approver_name: state.user.first_name,
    });
  };

  const handleChange = (e: any) => {
    if (
      e.target.name === "item_priority" ||
      e.target.name === "item_category_priority"
    ) {
      setData({ ...data, [e.target.name]: Number(e.target.value) });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleStatusChange = (event: any) => {
    setSelectedStatusValue(event.target.value);
    setData({
      ...data,
      status_id: Number(event.target.value),
      status: statusValues.find(
        (item: any) => item.status_id == Number(event.target.value)
      ).status,
    });
  };

  const handleRestaurantStatusChange = (event: any) => {
    setSelectedRestaurantStatusValue(event.target.value);
    setData({
      ...data,
      status_id: Number(event.target.value),
      status: restaurantStatusValues.find(
        (item: any) => item.status_id == Number(event.target.value)
      ).status,
    });
  };

  const handleFavItemStatusChange = (event: any) => {
    setSelectedFavItemStatusValue(event.target.value);
    setData({
      ...data,
      favourited_item_status_id: Number(event.target.value),
      favourited_item_status: favItemStatusValues.find(
        (item: any) => item.status_id == Number(event.target.value)
      ).status,
    });
  };

  const handleUpdateItemApproval = async () => {
    try {
      const response = await custom_patch(
        "updateItems/" + props.itemModalData?.item_id,
        data
      );
      if (response) {
        console.log(response);
        alert("Item updated successfully", "success");
        props.handleItemClose();
        setData([]);
        props.dataRefresh();
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  const handleUpdateRestaurantApproval = async () => {
    const newData = {
      restaurant_name: data.restaurant_name,
      formatted_address: data.formatted_address,
      name: data.name,
      is_active: data.is_active,
      top_dishes: data.top_dishes,
      city_name: data.city_name,
      cuisines: data.cuisines,
      food_type: data.food_type,
      top_dishes_count: data.top_dishes_count,
      current_state: data.current_state,
      linked_apps: data.linked_apps,
      phone_number: data.phone_number,
      status: data.status,
      status_id: data.status_id,
      state_id: data.state_id,
      approved_by: data.approved_by,
      approved_by_id: data.approved_by_id,
      approver_name: data.approver_name,
      city_id: data.city_id,
      country_id: data.country_id,
      image_url: data.image_url,
      modification_date: data.modification_date,
      del: data.del,
    };

    try {
      const response = await custom_patch(
        "updateRestaurants/" + props.restaurantModalData?._id,
        newData
      );
      if (response) {
        console.log(response);
        alert("Restaurant updated successfully", "success");
        props.handleRestaurantClose();
        setData([]);
        props.dataRefresh();
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  const handleUpdateFavItemApproval = async () => {
    let newData = {
      full_name: data.full_name,
      customer_id: data.customer_id,
      item_id: data.item_id,
      restaurant_id: data.restaurant_id,
      approver_name: state.user.first_name,
      favourited_item_status: data.favourited_item_status,
      favourited_item_status_id: data.favourited_item_status_id,
      approved_by_id: 3,
      approved_by: "level 3",
      remark: data.remark,
    };

    try {
      const response = await custom_patch(
        "updateFavouritedItemApprovalStatus",
        newData
      );
      if (response) {
        console.log(response);
        alert("Favourite item updated successfully", "success");
        props.handleFavItemsClose();
        setData([]);
        props.dataRefresh();
      }
    } catch (error: any) {
      console.log(error);
      alert(error.data ? error.data : "Something went wrong", "warning");
    }
  };

  const getCuisines = async (filter: any = {}) => {
    let filterData = [
      { type: "String", key: "cuisine_name", value: filter.cuisine },
    ];
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
    };

    try {
      const response = await get("cuisines", params);

      if (response) {
        const filteredCuisineData = response.data.data.map((item: any) => ({
          cuisine_id: item.cuisine_id,
          cuisine_name: item.cuisine_name,
        }));
        console.log(filteredCuisineData);
        setCuisineData(response.data.data);
        let cuisine_data: any = cuisineData.find(
          (item: any) => item.cuisine_name === filter.cuisine
        );
        setData({
          ...data,
          cuisine_name: cuisine_data.cuisine_name,
          cuisine_id: cuisine_data.cuisine_id,
          parent_cuisine: cuisine_data.parent_cuisine,
        });
        console.log("cuisine_data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCuisineChange = (cuisine_name: any) => {
    setSelectedCuisine({ cuisine_name: cuisine_name });
    getCuisines({ cuisine: cuisine_name });
  };

  const handleFoodTypeChange = (event: any) => {
    let food_type_data: any = foodTypeData.find(
      (item: any) => item.food_type_id === event.target.value
    );

    setSelectedFoodType({
      food_type_id: Number(event.target.value),
    });

    setData({
      ...data,
      food_type: food_type_data.food_type,
      food_type_id: food_type_data.food_type_id,
    });
  };

  const getFoodTypes = async () => {
    let params = {
      page: page,
      limit: rowsPerPage,
    };

    try {
      const response = await get("food_types", params);

      if (response) {
        console.log(response.data.data);
        const filteredFoodTypeData = response.data.data.map((item: any) => ({
          food_type_id: item.food_type_id,
          food_type: item.name,
        }));
        console.log(filteredFoodTypeData);
        setFoodTypeData(filteredFoodTypeData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getItemCategories = async (filter: any = {}) => {
    let filterData = [
      {
        type: "String",
        key: "item_category_name",
        value: filter.item_category,
      },
    ];
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
    };
    try {
      const response = await get("item_categories", params);
      if (response) {
        const filteredItemData = response.data.data.map((item: any) => ({
          item_category_id: item.item_category_id,
          item_category_name: item.item_category_name,
        }));
        setItemCategories(response.data.data);
        let item_category_data: any = itemCategoryData.find(
          (item: any) => item.item_category_name === filter.item_category
        );
        setData({
          ...data,
          item_category: item_category_data.item_category_name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemCategoryChange = (item_category: any) => {
    setSelectedItemCategory(item_category);
    setData({
      ...data,
      item_category: item_category,
    });
    getItemCategories({ item_category: item_category });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return props.type === "items" ? (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <Modal
        open={props.openItem}
        onClose={props.handleItemClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Card
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            maxHeight: 400,
            // borderRadius: 0,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.390625vw",
            },
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: "#F6F8FA",
              borderBottom: "1px solid #D5DBE1",
              padding: "8px 16px 8px 16px",
            }}
          >
            <h6 style={{ marginBottom: 0 }}>Item Approval</h6>
            <Button
              sx={{ minWidth: 0 }}
              className="p-0"
              onClick={props.handleItemClose}
            >
              <CloseIcon />
            </Button>
          </div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ padding: "16px", borderBottom: "1px solid #D5DBE1" }}
          >
            <FormGroup>
              <TextField
                sx={{ width: "100% !important" }}
                fullWidth
                required
                hiddenLabel
                variant="filled"
                id="itemName"
                size="small"
                className="reg-input name-field"
                name="item_name"
                value={data.item_name}
                onChange={handleChange}
                placeholder="Item Name"
              />
            </FormGroup>
            <div
              style={{
                width: "57px",
                height: "18px",
                backgroundColor: "#09414E",
                borderRadius: "4px",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <span style={{ fontSize: "12px", color: "#FFFFFF" }}>
                {props.itemModalData?.status}
              </span>
            </div>
          </div>
          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "14px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Cuisine Name
            </p>
            <FormGroup>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ width: "50%" }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  options={cuisineData.map((item: any) => item.cuisine_name)}
                  filterOptions={(options, state) => {
                    const inputValueLower = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.toLowerCase().includes(inputValueLower)
                    );
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={cuisineData.map(
                        (item: any) =>
                          `${item.cuisine_name} (${item.parent_cuisine})`
                      )}
                    />
                  )}
                  onInputChange={(event, newValue) => {
                    handleCuisineChange(newValue);
                  }}
                  value={selectedCuisine.cuisine_name || ""}
                />
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Item Category
            </p>
            <FormGroup>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ width: "50%" }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  options={itemCategories.map(
                    (item: any) => item.item_category_name
                  )}
                  filterOptions={(options, state) => {
                    const inputValueLower = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.toLowerCase().includes(inputValueLower)
                    );
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} />}
                  onInputChange={(event, newValue) => {
                    handleItemCategoryChange(newValue);
                  }}
                  value={selectedItemCategory || ""}
                />
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Key Ingredient
            </p>
            <FormGroup>
              <TextField
                sx={{ width: "100% !important" }}
                fullWidth
                required
                hiddenLabel
                variant="filled"
                id="keyIngredient"
                size="small"
                className="reg-input name-field"
                name="key_ingredient"
                value={data.key_ingredient}
                onChange={handleChange}
                placeholder="Key Ingredient"
              />
            </FormGroup>
          </div>

          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Item Category Priority
            </p>
            <FormGroup>
              <TextField
                sx={{ width: "100% !important" }}
                fullWidth
                required
                hiddenLabel
                variant="filled"
                id="itemCategoryPriority"
                size="small"
                className="reg-input name-field"
                name="item_category_priority"
                value={data.item_category_priority}
                onChange={handleChange}
                placeholder="Item Category Priority"
              />
            </FormGroup>
          </div>

          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Item Priority
            </p>
            <FormGroup>
              <TextField
                sx={{ width: "100% !important" }}
                fullWidth
                required
                hiddenLabel
                variant="filled"
                id="itemPriority"
                size="small"
                className="reg-input name-field"
                name="item_priority"
                value={data.item_priority}
                onChange={handleChange}
                placeholder="Item Priority"
              />
            </FormGroup>
          </div>

          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Item Description
            </p>
            <FormGroup>
              <TextField
                sx={{ width: "100% !important" }}
                fullWidth
                required
                hiddenLabel
                variant="filled"
                id="itemDescription"
                size="small"
                className="reg-input name-field"
                name="item_description"
                value={data.item_description}
                onChange={handleChange}
                placeholder="Item Description"
              />
            </FormGroup>
          </div>
          <div style={{ padding: "18px 14px" }}>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              Food Type
            </p>
            <FormGroup>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ width: "50%" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  // defaultValue={selectedNewsCategory.news_category}
                  value={selectedFoodType.food_type_id || ""}
                  // label="Apps"
                  onChange={handleFoodTypeChange}
                >
                  {foodTypeData.length > 0 &&
                    foodTypeData.map((e: any) => (
                      <MenuItem
                        key={e.food_type_id}
                        value={e.food_type_id}
                        // onClick={(event) => handleFoodAppChange(e)}
                        disableRipple
                      >
                        {e.food_type}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ padding: "24px 14px" }}>
            <h6
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                color: "#30313D",
              }}
            >
              Remark
            </h6>
            <TextField
              required
              id="remark"
              // className="reg-input name-field"
              fullWidth
              // required
              className=""
              multiline
              rows={4}
              name="data.remark"
              value={data.remark}
              onChange={handleChange}
              placeholder={"Type here"}
            />
          </div>
          <div style={{ width: "100%", padding: "24px 14px" }}>
            <h6>Set Status</h6>
            <FormControl fullWidth>
              <Select
                fullWidth
                labelId="demo-simple-select-label2"
                size="small"
                // id="ApprovedByValue"
                value={selectedStatusValue}
                onChange={handleStatusChange}
              >
                {statusValues.map((e: any) => (
                  <MenuItem key={e.status_id} value={e.status_id}>
                    {e.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: "100%", padding: "24px 14px" }}>
            <h6>Approved By Status</h6>
            <FormControl fullWidth>
              <Select
                fullWidth
                labelId="demo-simple-select-label2"
                size="small"
                // id="ApprovedByValue"
                value={selectedApprovedByValue}
                onChange={handleApprovedByChange}
              >
                {approvedByValues.map((e: any) => (
                  <MenuItem key={e.approved_by_id} value={e.approved_by_id}>
                    {e.approved_by}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#09414E",
                " &:hover": {
                  bgcolor: "#DB561E",
                },
              }}
              onClick={handleUpdateItemApproval}
            >
              Update
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  ) : props.type === "restaurants" ? (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <Modal
        open={props.openRestaurant}
        onClose={props.handleRestaurantClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Card
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            maxHeight: 400,
            // borderRadius: 0,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.390625vw",
            },
          }}
        >
          <div style={{ maxHeight: "100%", overflow: "auto" }}>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: "#F6F8FA",
                borderBottom: "1px solid #D5DBE1",
                padding: "8px 16px 8px 16px",
              }}
            >
              <h6 style={{ marginBottom: 0 }}>Restaurant Approval</h6>
              <Button
                sx={{ minWidth: 0 }}
                className="p-0"
                onClick={props.handleRestaurantClose}
              >
                <CloseIcon />
              </Button>
            </div>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ padding: "16px", borderBottom: "1px solid #D5DBE1" }}
            >
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  hiddenLabel
                  variant="filled"
                  id="restaurantName"
                  size="small"
                  className="reg-input name-field"
                  name="restaurant_name"
                  value={data.restaurant_name}
                  onChange={handleChange}
                  placeholder="Restaurant Name"
                />
              </FormGroup>
              <div
                style={{
                  width: "57px",
                  height: "18px",
                  backgroundColor: "#09414E",
                  borderRadius: "4px",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <span style={{ fontSize: "12px", color: "#FFFFFF" }}>
                  {props.restaurantModalData?.status}
                </span>
              </div>
            </div>
            <div style={{ padding: "18px 14px" }}>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                Address
              </p>
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  hiddenLabel
                  variant="filled"
                  id="formattedAddress"
                  size="small"
                  className="reg-input name-field"
                  name="formatted_address"
                  value={data.formatted_address}
                  onChange={handleChange}
                  placeholder="Formatted Address"
                />
              </FormGroup>
            </div>

            <div style={{ padding: "18px 14px" }}>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                Is Active
              </p>
              <FormGroup>
                <TextField
                  sx={{ width: "50% !important" }}
                  required
                  hiddenLabel
                  variant="filled"
                  id="isActive"
                  size="small"
                  className="reg-input name-field"
                  name="is_active"
                  value={data.is_active}
                  onChange={handleChange}
                  placeholder="Is Active"
                />
              </FormGroup>
            </div>

            <div style={{ padding: "18px 14px" }}>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                Food Type
              </p>
              <FormGroup>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: "50%" }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    // defaultValue={selectedNewsCategory.news_category}
                    value={selectedFoodType.food_type_id || ""}
                    // label="Apps"
                    onChange={handleFoodTypeChange}
                  >
                    {foodTypeData.length > 0 &&
                      foodTypeData.map((e: any) => (
                        <MenuItem
                          key={e.food_type_id}
                          value={e.food_type_id}
                          // onClick={(event) => handleFoodAppChange(e)}
                          disableRipple
                        >
                          {e.food_type}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>

            <div style={{ padding: "18px 14px" }}>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "14px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                Cuisine Name
              </p>
              <FormGroup>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: "50%" }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={cuisineData.map((item: any) => item.cuisine_name)}
                    filterOptions={(options, state) => {
                      const inputValueLower = state.inputValue.toLowerCase();
                      return options.filter((option) =>
                        option.toLowerCase().includes(inputValueLower)
                      );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={cuisineData.map(
                          (item: any) =>
                            `${item.cuisine_name} (${item.parent_cuisine})`
                        )}
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      handleCuisineChange(newValue);
                    }}
                    value={selectedCuisine.cuisine_name || ""}
                  />
                </FormControl>
              </FormGroup>
            </div>

            <div style={{ padding: "24px 14px" }}>
              <h6
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#30313D",
                }}
              >
                Remark
              </h6>
              <TextField
                required
                id="remark"
                // className="reg-input name-field"
                fullWidth
                className=""
                multiline
                rows={4}
                name="data.remark"
                value={data.remark}
                onChange={handleChange}
                placeholder={"Type here"}
              />
            </div>

            <div style={{ width: "100%", padding: "18px 14px" }}>
              <h6>Set Status</h6>
              <FormControl fullWidth style={{ width: "50%" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="RestaurantStatusValue"
                  value={selectedRestaurantStatusValue}
                  onChange={handleRestaurantStatusChange}
                >
                  {restaurantStatusValues.map((e: any) => (
                    <MenuItem key={e.status_id} value={e.status_id}>
                      {e.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* <div style={{ width: "100%", padding: "18px 14px" }}>
              <h6>Approved_BY</h6>
              <FormControl fullWidth style={{ width: "50%" }}>
                <Select
                  labelId="demo-simple-select-label2"
                  id="RestaurantApprovedByValue"
                  value={selectedRestaurantApprovedByValue}
                  onChange={handleRestaurantApprovedByChange}
                >
                  {restaurantApprovedByValues.map((e: any) => (
                    <MenuItem key={e.approved_by_id} value={e.approved_by_id}>
                      {e.approved_by}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            <div className="d-flex justify-content-center mb-4">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#09414E",
                  " &:hover": {
                    bgcolor: "#DB561E",
                  },
                }}
                onClick={handleUpdateRestaurantApproval}
              >
                Update
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  ) : props.type === "getFavouritedItems" ? (
    <Modal
      open={props.openFavItems}
      onClose={props.handleFavItemsClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Card
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          maxHeight: 400,
          // borderRadius: 0,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.390625vw",
          },
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#F6F8FA",
            borderBottom: "1px solid #D5DBE1",
            padding: "8px 16px 8px 16px",
          }}
        >
          <h6 style={{ marginBottom: 0 }}>Favourited Item Approval</h6>
          <Button
            sx={{ minWidth: 0 }}
            className="p-0"
            onClick={props.handleFavItemsClose}
          >
            <CloseIcon />
          </Button>
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "16px", borderBottom: "1px solid #D5DBE1" }}
        >
          <div className="mt-3 d-flex">
            <img
              src={data.profile_pic}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <div className="d-flex">
                <h6 className="me-3">{data.full_name}</h6>
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {data.customer_level}
                </p>
              </div>
              <div className="">
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {data.city}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "57px",
              height: "18px",
              backgroundColor: "#09414E",
              borderRadius: "4px",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <span style={{ fontSize: "12px", color: "#FFFFFF" }}>
              {data.favourited_item_status}
            </span>
          </div>
        </div>

        <div
          className="mt-3 d-flex align-items-center"
          style={{ padding: "16px" }}
        >
          <img
            src={data.item_image}
            alt=""
            className="me-3"
            style={{ width: "58px", height: "58px", borderRadius: "8px" }}
          />
          <div>
            <h6 className="me-3">{data.item_name}</h6>
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              {data.cuisine_name}
            </p>
          </div>
        </div>
        <div className="mt-3 d-flex" style={{ paddingLeft: "16px" }}>
          <img src={LocationMarkerIcon} alt="" className="me-2" />
          <p
            style={{
              fontFamily: "Roboto, sans-serif",
              marginBottom: "4px",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#687385",
            }}
          >
            {data.restaurant_name}
          </p>
        </div>
        {/* <div style={{ padding: "24px 14px" }}>
          <h6
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#30313D",
            }}
          >
            Item Name
          </h6>
          <TextField
            required
            id="itemName"
            // className="reg-input name-field"
            fullWidth
            className=""
            name="data.item_name"
            value={data.item_name}
            onChange={handleChange}
            disabled
            placeholder={"Item Name"}
          />
        </div> */}
        <div style={{ padding: "24px 14px" }}>
          <h6
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#30313D",
            }}
          >
            Remark
          </h6>
          <TextField
            required
            id="remark"
            // className="reg-input name-field"
            fullWidth
            className=""
            multiline
            rows={4}
            name="remark"
            value={data.remark}
            onChange={handleChange}
            placeholder={"Type here"}
          />
        </div>

        <div style={{ width: "100%", padding: "18px 14px" }}>
          <h6>Set Status</h6>
          <FormControl fullWidth style={{ width: "50%" }}>
            <Select
              labelId="demo-simple-select-label"
              id="RestaurantStatusValue"
              value={selectedFavItemStatusValue}
              onChange={handleFavItemStatusChange}
            >
              {favItemStatusValues.map((e: any) => (
                <MenuItem key={e.status_id} value={e.status_id}>
                  {e.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* <div style={{ width: "100%", padding: "24px 14px" }}>
          <h6>APPROVED BY</h6>
          <FormControl fullWidth>
            <Select
              fullWidth
              labelId="demo-simple-select-label2"
              // id="ApprovedByValue"
              value={selectedFavItemApprovedByValue}
              onChange={handleFavItemApprovedByChange}
            >
              {favItemApprovedByValues.map((e: any) => (
                <MenuItem key={e.approved_by_id} value={e.approved_by_id}>
                  {e.approved_by}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> */}

        <div className="d-flex justify-content-center mb-4">
          <Button
            variant="contained"
            sx={{
              bgcolor: "#09414E",
              " &:hover": {
                bgcolor: "#DB561E",
              },
            }}
            onClick={handleUpdateFavItemApproval}
          >
            Update
          </Button>
        </div>
      </Card>
    </Modal>
  ) : (
    <></>
  );
}

export default StatusUpdater;
