import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import "./index.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { get } from "../../../../utils/api";
import Button from "@mui/material/Button";

// const locations = [
//   {
//     name: "Mr India",
//     city: "Warsaw",
//     type: "Restaurant",
//     lat: 52.2315210182532,
//     lng: 21.008612559131027,
//     address: "Krakowska 2, 02-001 Warsaw, Poland",
//   },
//   {
//     name: "Little India",
//     city: "Warsaw",
//     type: "Indian store",
//     lat: 52.20881127678466,
//     lng: 20.980646287849794,
//     address: "Krakowska 2, 02-001 Warsaw, Poland",
//   },
//   {
//     name: "SPICE LAND",
//     city: "Frankfurt",
//     type: "RETAIL/WHOLESALE CHAIN",
//     lat: 50.11317987119162,
//     lng: 8.666727998271336,
//     address: "Kaiserstraße 56, 60329 Frankfurt am Main, Germany",
//   },
//   {
//     name: "KAMLESH KFT",
//     city: "Budapest",
//     type: "RETAIL/WHOLESALE CHAIN",
//     lat: 47.50852787451554,
//     lng: 18.993252575734623,
//     address: "Budapest, Kőbányai út 30, 1105 Hungary",
//   },
// ];

interface Location {
  outlet: string;
  city: string;
  type: string;
  lat: number;
  lng: number;
  address: string;
  route?: string;
}

interface CityCenter {
  city: string;
  center_lat: number;
  center_lng: number;
}

function MapsAutocomplete(props: any) {
  const [data, setData] = useState<any>([]);

  const [cityCount, setCityCount] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [countriesPerPage, setCountriesPerPage] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<any>({ country: 1 });

  const [locations, setLocations] = useState<any>({});
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [areas, setAreas] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<any>("");
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedDistrict, setSelectedDistrict] = useState<any>("");
  const [selectedCity, setSelectedCity] = useState<any>("");
  const [selectedArea, setSelectedArea] = useState<any>("");
  const [iscountrySelected, setIsCountrySelected] = useState<any>(false);
  const [isStateSelected, setIsStateSelected] = useState<any>(false);
  const [isDistrictSelected, setIsDistrictSelected] = useState<any>(false);
  const [isCitySelected, setIsCitySelected] = useState<any>(false);

  useEffect(() => {
    // setCityCount(countLocationsByCity(locations));
    getLocationData();
    setIsCountrySelected(true);
    getStateData(312);
  }, []);

  const getLocationData = async () => {
    let params = {
      page: page,
      limit: countriesPerPage,
      sortBy: sortBy,
    };

    try {
      const response = await get("countries", params);
      if (response) {
        setLocations(response.data.data);
        setCountries(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStateData = async (country_id: Number) => {
    let params = {
      page: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      filter: [
        {
          key: "country_id",
          value: country_id,
          type: "Number",
        },
      ],
    };

    try {
      const response = await get("states", params);
      if (response) {
        setLocations(response.data.data);
        setStates(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  type CityCenter = {
    city: string;
    center_lat: number;
    center_lng: number;
    count: number;
  };

  const getCityData = async (state_id: Number) => {
    let params = {
      page: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      filter: [
        {
          key: "state_id",
          value: state_id,
          type: "Number",
        },
      ],
    };

    try {
      const response = await get("cities", params);
      if (response) {
        setLocations(response.data.data);
        setCities(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showLocations = () => {
    props.onLocationChange(locations);
  };

  const handleCountryChange = async (event: any) => {
    let country = event.target.value;
    setIsCountrySelected(true);
    getStateData(country.country_id);
  };

  const handleStateChange = async (event: any) => {
    let state = event.target.value;
    setIsStateSelected(true);
    getDistrictData(state.state_id);
  };

  const handleDistrictChange = async (event: any) => {
    let district = event.target.value;
    setIsDistrictSelected(true);
    getCitiesData(district.district_id);
  };

  const getCitiesData = async (district_id: any) => {
    let params = {
      page: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      filter: [
        {
          key: "district_id",
          value: district_id,
          type: "Number",
        },
      ],
    };

    try {
      const response = await get("cities", params);
      if (response) {
        setLocations(response.data.data);
        setCities(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistrictData = async (state_id: any) => {
    let params = {
      page: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      filter: [
        {
          key: "state_id",
          value: state_id,
          type: "Number",
        },
      ],
    };

    try {
      const response = await get("cities", params);
      if (response) {
        setLocations(response.data.data);
        setDistricts(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCityChange = async (event: any) => {
    let city = event.target.value;
    getAreaData(city.city_id);
  };

  const getAreaData = async (city_id: any) => {
    let params = {
      page: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      filter: [
        {
          key: "city_id",
          value: city_id,
          type: "Number",
        },
      ],
    };

    try {
      const response = await get("city_areas", params);
      if (response) {
        let areas = response.data.data.map((e: any) => {
          e.geometry = e.geometry;
        });

        setLocations(response.data.data);
        setAreas(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="d-flex justify-content-between align-items-start maps-autocomplete-content">
          <h2>City Map</h2>
          <Select
            // labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry.country}
            onChange={handleCountryChange}
            defaultValue="All Cities"
            // label="City"
            size="small"
            sx={{
              backgroundColor: "#FFFFFF",
              border: "none",
              " & .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              width: "23%",
              maxWidth: "300px",
              " & .MuiSvgIcon-root": {
                color: "#F6C600",
              },
            }}
            IconComponent={() => (
              <span
                className="MuiSvgIcon-root"
                style={{ transform: "rotate(180 deg)", paddingRight: "10px" }}
              >
                <KeyboardArrowDownIcon />
              </span>
            )}
          >
            {countries.map((country: any) => (
              <MenuItem key={country.country} value={country}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
          {iscountrySelected && (
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedState.state}
              onChange={handleStateChange}
              defaultValue="All Cities"
              // label="City"
              size="small"
              sx={{
                backgroundColor: "#FFFFFF",
                border: "none",
                " & .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: "23%",
                maxWidth: "300px",
                " & .MuiSvgIcon-root": {
                  color: "#F6C600",
                },
              }}
              IconComponent={() => (
                <span
                  className="MuiSvgIcon-root"
                  style={{ transform: "rotate(180 deg)", paddingRight: "10px" }}
                >
                  <KeyboardArrowDownIcon />
                </span>
              )}
            >
              {states.map((state: any) => (
                <MenuItem key={state.state} value={state}>
                  {state.state}
                </MenuItem>
              ))}
            </Select>
          )}

          {isStateSelected && (
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDistrict.district}
              onChange={handleDistrictChange}
              defaultValue="All Districts"
              // label="City"
              size="small"
              sx={{
                backgroundColor: "#FFFFFF",
                border: "none",
                " & .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: "23%",
                maxWidth: "300px",
                " & .MuiSvgIcon-root": {
                  color: "#F6C600",
                },
              }}
              IconComponent={() => (
                <span
                  className="MuiSvgIcon-root"
                  style={{ transform: "rotate(180 deg)", paddingRight: "10px" }}
                >
                  <KeyboardArrowDownIcon />
                </span>
              )}
            >
              {districts.map((district: any) => (
                <MenuItem key={district.district_id} value={district}>
                  {district.district}
                </MenuItem>
              ))}
            </Select>
          )}

          {isDistrictSelected && (
            <Select
              // labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCity.city}
              onChange={handleCityChange}
              defaultValue="All Cities"
              // label="City"
              size="small"
              sx={{
                backgroundColor: "#FFFFFF",
                border: "none",
                " & .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: "23%",
                maxWidth: "300px",
                " & .MuiSvgIcon-root": {
                  color: "#F6C600",
                },
              }}
              IconComponent={() => (
                <span
                  className="MuiSvgIcon-root"
                  style={{ transform: "rotate(180 deg)", paddingRight: "10px" }}
                >
                  <KeyboardArrowDownIcon />
                </span>
              )}
            >
              {cities.map((city: any) => (
                <MenuItem key={city.city_id} value={city}>
                  {city.city}
                </MenuItem>
              ))}
            </Select>
          )}

          {isStateSelected && (
            <Button
              variant="contained"
              sx={{ bgcolor: "#09414E" }}
              onClick={showLocations}
            >
              Show
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MapsAutocomplete;
