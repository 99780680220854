import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

import {
  admin_ts_get,
  awsupload,
  custom_patch,
  get,
  getProfile,
  patch,
  post,
} from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import imageCompression from "browser-image-compression";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import AutoComplete from "../../../layout/components/AutoComplete";
import { AppContext } from "../../../utils/state";
import SnackbarAlert from "../../common/sanckbar";

type Props = {};

function EditProductTypeDetails(props: any) {
  const { state, dispatch } = useContext(AppContext);

  let { id } = useParams();
  const navigate = useNavigate();
  if (props.id) {
    id = props.id;
  }
  console.log(id);
  // })
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [productCategories, setProductCategories] = useState<any>([]);
  const [productBrands, setProductBrands] = useState<any>([]);

  let [selectedProductCategoryName, setSelectedProductCategoryName] = useState<any>("");
  let [selectedProductBrandName, setSelectedProductBrandName] = useState<any>(null);
  let [selectedBrandObject, setSelectedBrandObject] = useState<any>({});

  let [formData, setFormData] = useState<any>({
    product_category_id: 0,
    product_type_name: "",
    del: 0,
    brands: [],
    status_id: 1,
    status: "active",
  });
  useEffect(() => {
    getProductTypeById();
    getProductBrands();
  }, []);

  const getProductTypeById = async () => {
    try {
      const response = await get(`product_types/${id}`, {});

      if (response) {
        console.log("NEWCoding", response.data);
        formData = response.data;
        setFormData(formData);
        getProductCategories();

      }
    } catch (error) {
      console.log(error);
    }
  };


  const getProductCategories = async () => {
    try {
      let params = {
        limit: 20
      }
      const response = await get("product_categories", { params });

      if (response) {
        const categoriesData = response.data.data;
        console.log("categoriesData", categoriesData);

        setProductCategories(categoriesData);

        const selectedCategory = categoriesData.find(
          (category: any) =>
            category.product_category_id == formData.product_category_id
        );
        console.log("selectedCategorycc", selectedCategory);
        if (selectedCategory) {
          setSelectedProductCategoryName(selectedCategory);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductBrands = async () => {
    try {
      let params = {
        limit: 90
      }
      const response = await get("brands", { params });

      if (response) {
        const brandsData = response.data.data;
        console.log("brands", brandsData);

        setProductBrands(brandsData);

        // const selectedCategory = brandsData.find(
        //   (e: any) =>
        //     e.brand_id == formData.product_category_id
        // );
        // console.log("selectedCategorycc", selectedCategory);
        // if (selectedCategory) {
        //   setSelectedProductCategoryName(selectedCategory);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProductTypes = async (e: any) => {
    e.preventDefault();

    let productTypeDetails = {
      product_category_id: formData.product_category_id,
      product_type_name: formData.product_type_name,
      del: formData.del,
      brands:formData.brands,
      status_id: formData.status_id,
      status: formData.status,
    };
      console.log("kk", productTypeDetails)
    try {
      const response = await patch(`product_types/${id}`, productTypeDetails);

      console.log(response.data);
      if (response) {
        alert("Product Type updated successfully", "success");
        // navigate("/product-types/list");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  // const handleProductCategoryChange = (e: any) => {
  //   setFormData((prevFormData: any) => ({
  //     ...prevFormData,
  //     product_category_id: e.product_category_id,
  //   }));
  //   setSelectedProductCategoryName(selectedProductCategory || null);

  // };
  const handleProductCategoryChange = (
    event: any,
    selectedProductCategory: any
  ) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      product_category_id: selectedProductCategory?.product_category_id,
    }));
    console.log("yy", formData);
    setSelectedProductCategoryName(selectedProductCategory || null);
  };

  const addBrand = () => {
    console.log('gggggggggggg', selectedBrandObject);
    // let brands_array:any = formData.brands;
    console.log("dd", formData.brands)
    if (formData.brands.length > 0) {
      let exist_brand_id: any = formData.brands.find((e: any) => e.brand_id == selectedBrandObject.brand_id);
      if (!exist_brand_id) {
        formData.brands.push(selectedBrandObject);
      }
    } else {
      formData.brands.push(selectedBrandObject);
    }
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      brands: formData.brands
    }));
    console.log("formdata", formData)
  }
  const handleProductBrandsChange = (
    event: any,
    selectedProductBrand: any
  ) => {
    console.log("selectedProductBranduu", selectedProductBrand)
    let brand_object = {
      brand_id: selectedProductBrand.brand_id,
      brand_name: selectedProductBrand.brand_name,
    }
    setSelectedBrandObject(brand_object)
    // setFormData((prevFormData: any) => ({
    //   ...prevFormData,
    //   brand_id: selectedProductBrand?.brand_id,
    // }));
    // console.log("yy", formData);
    //setSelectedProductBrandName(selectedProductBrand || null);
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setCuisine = (e: any) => {
    // setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  const handleDeleteBrand = (brand_id: any) => {
    const updatedBrands = formData.brands.filter((brand: any) => brand.brand_id !== brand_id);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      brands: updatedBrands,
    }));
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />

      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>Edit Product Type</h5>
          </div>
        </div>
        <form onSubmit={handleEditProductTypes} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productTypeName">Product Type Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="productTypeName"
                  className="reg-input name-field"
                  name="product_type_name"
                  value={formData.product_type_name}
                  onChange={handleChange}
                  placeholder="Product Type Name"
                />
              </FormGroup>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productCategory">Product Category</label>
            </div>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={productCategories}
              style={{ width: 270, padding: "8px 7.5px" }}
              getOptionLabel={(option: any) => option.product_category}
              renderOption={(props, option) => (
                <li {...props}>{option.product_category}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a product category"
                  placeholder="Product Category"
                />
              )}
              value={selectedProductCategoryName} // This should be an object
              onChange={(event, selectedProductCategory: any) =>
                handleProductCategoryChange(event, selectedProductCategory)
              }
            />

          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productBrands">Product Brands</label>
            </div>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={productBrands}
              style={{ width: 270, padding: "8px 7.5px" }}
              getOptionLabel={(option: any) => option.brand_name}
              renderOption={(props, option) => (
                <li {...props}>{option.brand_name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a product brand"
                  placeholder="Product Brand"
                />
              )}
              value={selectedProductBrandName} // This should be an object
              onChange={(event, selectedProductBrand: any) =>
                handleProductBrandsChange(event, selectedProductBrand)
              }
            />
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <Button
                variant="contained"
                type="button"
                className="d-flex justify-content-end"
                // style={{ marginBottom: "50px" }}
                onClick={addBrand}
              >
                <span>Add</span>
              </Button>
            </div>
          </div>



          {formData.brands.length > 0 && (
            <div className="row mt-3 mb-3">
              <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
                {/* Placeholder for alignment */}
              </div>
              <div className="col-xs-12 col-md-8 col-lg-8 d-flex align-items-center">
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>Brand Name</th>
                      <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.brands.map((brand: any) => (
                      <tr key={brand.brand_id} style={{ borderBottom: "1px solid #ddd" }}>
                        <td style={{ padding: "8px" }}>{brand.brand_name}</td>
                        <td style={{ padding: "8px" }}>
                        <div className="col-2"><IconButton edge="end" aria-label="delete" onClick={() =>handleDeleteBrand(brand.brand_id)}>
                    <DeleteIcon />
                  </IconButton></div>
                          {/* <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteBrand(brand.brand_id)}
                          >
                            Delete
                          </Button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          
          <br></br>
          <br></br>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditProductTypeDetails;
