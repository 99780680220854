import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import FieldSettingsIcon from "../../../../assets/images/Invoices/field-settings-icon.svg";
import MasterFilter from "../../../MasterFilter";
import "./index.scss";
import { admin_ts_get, get, patch } from "../../../../../utils/api";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const results = [
  {
    label: "first",
    value: 10,
  },
  {
    label: "second",
    value: 25,
  },
  {
    label: "third",
    value: 50,
  },
  {
    label: "fourth",
    value: 100,
  },
  {
    label: "fifth",
    value: 500,
  },
  {
    label: "sixth",
    value: 1000,
  },
  {
    label: "seventh",
    value: 3000,
  },
  {
    label: "eighth",
    value: 5000,
  },
];

function FieldSettingsModal(props: any) {
  const [fields, setFields] = useState<Array<any>>(props.fieldsMaster);
  const [pageSize, setPageSize] = useState<any>(props.rowsPerPage);
  let [data, setData] = useState<any>([]);
  let [dataKeys, setKeys] = useState<any>([]);

  console.log("pageSize", pageSize);

  useEffect(() => {
    console.log("props.module", props.module);
    console.log("filedsMaster", props.fieldsMaster);
    // if (!props.fieldsMaster) {
    getFields();
    // }
  }, []);

  console.log("fieldSEttings", props);

  useEffect(() => {
    console.log("props.module", props.module);
    console.log("filedsMaster", props.fieldsMaster);
    if (!props.fieldsMaster) {
      getFields();
    }
  }, [props.fieldsMaster, props.module]);

  const getFields = () => {
    if (props.module === "getFavouritedItems") {
      let filter = {};
      let data1: any = { limit: 10, skip: 0, filter: filter };
      let params: any = data1;
      admin_ts_get("getFavouritedItems", params).then(async (res: any) => {
        data = res.data;
        await setData(data);
        console.log("data", data);
        const keys = Object.keys(data[0] || {});
        console.log("keys", dataKeys);
        dataKeys = keys;
        await setKeys(dataKeys);
      });
    } else if (props.module === "getUniqueFavouritedRestaurantItems") {
      let filter = {};
      let data1: any = { limit: 10, skip: 0, filter: filter };
      let params: any = data1;
      admin_ts_get("getUniqueFavouritedRestaurantItems", params).then(
        async (res: any) => {
          data = res.data;
          await setData(data);
          console.log("data", data);
          const keys = Object.keys(data[0] || {});
          console.log("keys", dataKeys);
          dataKeys = keys;
          await setKeys(dataKeys);
        }
      );
    } else {
      get("" + props.module, {}).then((response: any) => {
        if (props.module === "fields_settings") {
          setFields(response.data.data);
        } else {
          console.log("responsenew", response);
          setFields(response.data.fields);
        }
        console.log("fields", fields);
      });
    }
  };

  const updateData = async () => {
    try {
      const response = await patch("fields_settings", {
        collection: props.module,
        fields: fields,
      });
      console.log(response);
      props.handleCloseModal();
      props.getData();
      // return response.data.fields;
    } catch (error) {
      console.log(error);
    }
    props.getData();
  };

  const handleUpdate = (key: any, status: any, type: any) => {
    console.log(key, status, type);
    const newFields = fields.map((field) => {
      if (field.key === key) {
        switch (type) {
          case "coloumn_status":
            return { ...field, coloumn_status: status };
          case "filter_status":
            return { ...field, filter_status: status };
          case "download_status":
            return { ...field, download_status: status };
          default:
            return field;
        }
      } else {
        return field;
      }
    });
    setFields(newFields);
  };

  const handleRadioPage = (e: any) => {
    console.log("newest", e.target.value);
    setPageSize(e.target.value);
    props.setRowsPerPage(e.target.value);
    // props.handleServerPagination(e.target.value);
  };

  return (
    <div>
      <Button onClick={props.handleOpenModal} className="field-settings-btn">
        <img src={FieldSettingsIcon} alt="" />
      </Button>
      {props.openModal ? (
        <Modal
          open={props.openModal}
          onClose={props.handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center invoices-settings-header">
              <div>
                <h6>Settings</h6>
              </div>
              <div>
                <Button
                  onClick={props.handleCloseModal}
                  sx={{
                    color: "#000",
                    bgcolor: "#FFF",
                    ":hover": { bgcolor: "#FFF", color: "#808080" },
                  }}
                >
                  <CancelIcon />
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
              <div className="fieldsettings-page-size">
                <h6>Page size</h6>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  name="radio-buttons-group"
                >
                  {results.map((result) => (
                    <div className="d-flex align-items-center">
                      <FormControlLabel
                        value={result.value}
                        control={
                          <Radio
                            sx={{ color: "#09414E" }}
                            className="field-modal-radio"
                          />
                        }
                        label={result.value}
                        sx={{
                          marginRight: "5px",
                        }}
                        onChange={handleRadioPage}
                      />
                      <span>results</span>
                    </div>
                  ))}
                </RadioGroup>
              </div>
              <div className="field-settings-table">
                <Table
                  sx={{
                    minWidth: 338,
                    maxHeight: "338px",
                    overflow: "auto",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>field</TableCell>
                      <TableCell align="right">column status</TableCell>
                      <TableCell align="right">filter status</TableCell>
                      <TableCell align="right">download status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((e: any, index: number) => (
                      <TableRow
                        key={e.key}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="invoice-field-values"
                        >
                          {e.key.replace(/_/g, " ")}
                        </TableCell>
                        {/* <TableCell align="right">
                        <Switch
                          size="small"
                          checked={e.coloumn_status}
                        />
                      </TableCell> */}
                        <TableCell align="right">
                          <Switch
                            size="small"
                            checked={e.coloumn_status}
                            onChange={(event) =>
                              handleUpdate(
                                e.key,
                                !e.coloumn_status,
                                "coloumn_status"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            size="small"
                            onChange={(event) =>
                              handleUpdate(
                                e.key,
                                !e.filter_status,
                                "filter_status"
                              )
                            }
                            checked={e.filter_status}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            size="small"
                            onChange={(event) =>
                              handleUpdate(
                                e.key,
                                !e.download_status,
                                "download_status"
                              )
                            }
                            checked={e.download_status}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="d-flex justify-content-end fields-modal-btns">
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="contained" onClick={updateData}>
                  Save
                </Button>
                <Button variant="outlined" onClick={props.handleCloseModal}>
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default FieldSettingsModal;
