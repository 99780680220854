import Tabswitch from "../../../../../layout/components/Tabswitch/Tabswitch";

function Header() {
  let tabData = [
    {
      tabName: "List",
      tabLink: "/user-roles/list",
    },
    // {
    //   tabName: "Summary",
    //   tabLink: "/admin/admin-users/summary",
    // },
  ];

  return (
    <>
      <Tabswitch tabData={tabData} />
    </>
  );
}

export default Header;
