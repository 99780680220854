import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  admin_ts_get,
  awsupload,
  custom_patch,
  get,
  getProfile,
  patch,
  post,
} from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import imageCompression from "browser-image-compression";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import AutoComplete from "../../../layout/components/AutoComplete";
import { AppContext } from "../../../utils/state";
import SnackbarAlert from "../../common/sanckbar";

type Props = {};

function EditEnquiryDetails(props: any) {
  const { state, dispatch } = useContext(AppContext);

  let { id } = useParams();
  const navigate = useNavigate();
  if (props.id) {
    id = props.id;
  }
  console.log(id);
  // })
  let [formData, setFormData] = useState({
    product_category_id: 0,
    product_type_id: 0,
    product_name: "",
    image_id: "",
    image_url: "",
    del: 0,
    status_id: 1,
    status: "active",
  });

  let [productCategories, setProductCategories] = useState<any>([]);
  let [productTypes, setProductTypes] = useState<any>([]);
  let [selectedProductCategoryName, setSelectedProductCategoryName] =
    useState<any>("");
  let [selectedProductTypeName, setSelectedProductTypeName] = useState<any>("");
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  useEffect(() => {
    getProductById();

    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    console.log("in private*******************");
    let data = await getProfile();
    console.log(data);
    if (!data) {
      navigate("/login");
    } else {
      // let user:any = data.user;
      setFormData((prevData: any) => ({
        ...prevData,
        approver_name: data.user.first_name + " " + data.user.last_name,
      }));
    }
  };

  const getProductById = async () => {
    try {
      const response = await get(`products/${id}`, {});

      if (response) {
        console.log("NEWCoding", response.data);
        formData = response.data;
        setFormData(formData);
        getProductCategories();
        getProductTypes();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProduct = async (event: any) => {
    event.preventDefault(); // Prevent form submission

    const newData = {
      product_category_id: formData.product_category_id,
      product_type_id: formData.product_type_id,
      product_name: formData.product_name,
      image_id: formData.image_id,
      image_url: formData.image_url,
      del: formData.del,
      status_id: formData.status_id,
      status: formData.status,
    };

    console.log("newData", newData);

    try {
      const response = await patch(`products/${id}`, newData);

      if (response) {
        alert(response.message ? response.message : "success", "success");
        console.log("editProducts", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductCategories = async () => {
    try {
      const response = await get("product_categories", { limit: 50 });

      if (response) {
        const categoriesData = response.data.data;
        setProductCategories(categoriesData);

        const selectedCategory = categoriesData.find(
          (category: any) =>
            category.product_category_id === formData.product_category_id
        );
        console.log("selectedCategory", selectedCategory);
        if (selectedCategory) {
          setSelectedProductCategoryName(selectedCategory);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductTypes = async () => {
    try {
      const response = await get("product_types", { limit: 50 });

      if (response) {
        const typesData = response.data.data;
        setProductTypes(typesData);

        const selectedType = typesData.find(
          (type: any) => type.product_type_id === formData.product_type_id
        );

        if (selectedType) {
          setSelectedProductTypeName(selectedType);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("name", e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProductCategoryChange = (
    event: any,
    selectedProductCategory: any
  ) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      product_category_id: selectedProductCategory?.product_category_id,
    }));
    console.log("yy", formData);
    setSelectedProductCategoryName(selectedProductCategory || null);
  };

  const handleProductTypeChange = (event: any, selectedProductType: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      product_type_id: selectedProductType?.product_type_id,
    }));

    setSelectedProductTypeName(selectedProductType);
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>Edit Item</h5>
          </div>
        </div>
        <form onSubmit={handleEditProduct} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productName">Product Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="productName"
                  className="reg-input name-field"
                  name="product_name"
                  value={formData.product_name}
                  onChange={handleChange}
                  placeholder="Product Name"
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productCategory">Product Category</label>
            </div>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={productCategories}
              style={{ width: 270, padding: "8px 7.5px" }}
              getOptionLabel={(option: any) => option.product_category}
              renderOption={(props, option) => (
                <li {...props}>{option.product_category}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a product category"
                  placeholder="Product Category"
                />
              )}
              value={selectedProductCategoryName} // This should be an object
              onChange={(event, selectedProductCategory: any) =>
                handleProductCategoryChange(event, selectedProductCategory)
              }
            />
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="productType">Product Type</label>
            </div>
            {/* <Autocomplete
              id="checkboxes-tags-demo"
              options={productTypes}
              style={{ width: 270, padding: "8px 7.5px" }}
              getOptionLabel={(option: any) => option.product_type_name}
              renderOption={(props, option) => (
                <li {...props}>{option.product_type_name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a product type"
                  placeholder="Product Type"
                />
              )}
              value={selectedProductTypeName}
              onChange={(event, selectedProductType) =>
                handleProductTypeChange(selectedProductType)
              }
            /> */}
            <Autocomplete
              id="product-type-autocomplete"
              options={productTypes}
              style={{ width: 270, padding: "8px 7.5px" }}
              getOptionLabel={(option: any) => option.product_type_name}
              renderOption={(props, option: any) => (
                <li {...props}>{option.product_type_name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a product type"
                  placeholder="Product Type"
                />
              )}
              value={selectedProductTypeName} // This should be an object
              onChange={(event, selectedProductType: any) =>
                handleProductTypeChange(event, selectedProductType)
              }
            />
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default EditEnquiryDetails;
