import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import CreateInvoiceIcon from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { get } from "../../../../../utils/api";
import Rightbar from "../../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../../layout/components/Selectmenu/Selectmenu";
import Table from "../../../../../layout/components/Table/Table";

function Summary() {
  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = useState<Array<any>>([]);

  const fetchData = () => {
    get("/admin_users", {
      skip: 0,
      limit: 10,
    }).then((response) => {
      setData(response.data.data);
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <Rightbar />
        <div className="row">
          <div className="col-xs-12">
            <div className="d-md-flex d-block justify-content-between align-items-center tabheader-main">
              <div className="invoice-details">
                <h3>Purchases Summary</h3>
              </div>
              <div className="d-md-flex d-block justify-content-between align-items-center invoice-modify-btns">
                <Selectmenu
                  names={["FY-22-23", "FY-22-24"]}
                  className="select-main"
                  selectClass="select-inner"
                />
                <Button
                  variant="contained"
                  color="error"
                  className="invoice-download-btn"
                >
                  <span> Download</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table data={data} />
      </div>
    </div>
  );
}

export default Summary;
