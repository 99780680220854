import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DetailHeader from "../Details/DetailHeader";
import ProductDetails from "./details";
import BrandDetails from "./details";

//import { NewClientModal } from './NewClient'

type Props = {
  className: string;
};

const BrandDetailsRoute: React.FC<Props> = () => {
  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <DetailHeader></DetailHeader>
              <Outlet />
            </>
          }
        >
          <Route
            path=""
            element={
              <>
                <BrandDetails />
              </>
            }
          />

          {/* <Route index element={<Navigate to='/restaurants/details/' />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default BrandDetailsRoute;
