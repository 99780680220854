import * as React from "react";
import "./Tabswitch.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabledata from "../Table/Table";
import Summary from "../Summary/Summary";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  tabData?: any;
  style?: any;
  tabComponent?: any;
  modal?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Tabswitch(props: any) {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const routeTo = (link: string) => {
    // props.history.push(link);
    navigate(link);
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <Box sx={{ width: "100%" }} className="tabswitch-container">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              TabIndicatorProps={{
                sx: { bgcolor: "#BD6844", height: "2px", borderRadius: "4px" },
              }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="tabswitch-head"
            >
              {props.tabData.map((td: any, index: number) => {
                return (
                  <Tab
                    key={index}
                    label={td.tabName}
                    // value={index}
                    onClick={(e) => !props.modal && routeTo(td.tabLink)}
                    style={props.style}
                  />
                );
              })}
            </Tabs>
          </Box>
          {
            // props.modal &&
            props.tabData &&
              props.tabData.map((e: any, index: number) => {
                return (
                  <TabPanel value={value} index={index}>
                    {e.tabComponent}
                  </TabPanel>
                );
              })
          }
        </Box>
      </div>
    </div>
  );
}
