import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import LocationMarkerIcon from "../../../../assets/images/Tabledata/location_marker.svg";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import NewRemarkModal from "../NewRemarkModal";

function ChildReassignCityModal(props: any) {
  const [openNewRemarkModal, setOpenNewRemarkModal] = useState(false);
  const handleNewRemarkModalOpen = () => {
    setOpenNewRemarkModal(true);
  };
  const handleNewRemarkModalClose = () => {
    setOpenNewRemarkModal(false);
  };

  const addNewRemark = () => {
    // props.AddNewReassignRemark();
    handleNewRemarkModalOpen();
  };

  const [isReassignRemarkValid, setIsReassignRemarkValid] = useState(true);

  const handleInputChange = (event: any, newValue: any) => {
    props.handleReassignReasonChange(newValue);

    const isValid = props.reassignRemarkData.some(
      (item: any) => item.remark.toLowerCase() === newValue.toLowerCase()
    );

    console.log("isValid", isValid);

    setIsReassignRemarkValid(!isValid);
  };

  return (
    <Modal
      open={props.openReassignRemark}
      onClose={props.handleReassignRemarkClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={props.style}>
        <form onSubmit={props.handleReassignConfirm}>
          <Typography id="modal-reassign-title" variant="h6" component="h2">
            Please provide these details before you proceed
          </Typography>
          <div className="mt-3 d-flex">
            <img
              src={props.customerDetailData.profile_pic}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <div className="d-flex">
                <h6 className="me-3">{props.data.full_name}</h6>
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.customer_level}
                </p>
              </div>
              <div className="">
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.city}
                </p>
              </div>
            </div>
          </div>

          <Divider className="mt-2" sx={{ opacity: 1 }} />

          <div className="mt-3 d-flex align-items-center">
            <img
              src={props.itemDetailData.image_url}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <h6 className="me-3">{props.data.item_name}</h6>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                {props.data.cuisine_name}
              </p>
            </div>
          </div>
          <div className="mt-3 d-flex">
            <img src={LocationMarkerIcon} alt="" className="me-2" />
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              {props.data.restaurant_name}
            </p>
          </div>

          <div className="row mt-4 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="reassignRemark">Reassign Remark</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup className="d-flex flex-row align-items-center">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="me-3"
                  options={props.reassignRemarkData.map(
                    (item: any) => item.remark
                  )}
                  filterOptions={(options, state) => {
                    const inputValueLower = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.toLowerCase().includes(inputValueLower)
                    );
                  }}
                  sx={{ width: "50%" }}
                  renderInput={(params) => <TextField {...params} />}
                  onInputChange={(event, newValue) => {
                    handleInputChange(event, newValue);
                  }}
                  value={props.selectedReassignRemark.remark || ""}
                />
                {isReassignRemarkValid && (
                  <Link
                    sx={{ cursor: "pointer" }}
                    className=""
                    onClick={() => addNewRemark()}
                  >
                    Add New Remark
                  </Link>
                )}
              </FormGroup>
            </div>
          </div>

          <NewRemarkModal
            openNewRemarkModal={openNewRemarkModal}
            handleNewRemarkModalOpen={handleNewRemarkModalOpen}
            handleNewRemarkModalClose={handleNewRemarkModalClose}
            style={props.style}
            handleNewReassignRemark={props.handleNewReassignRemark}
            newReassignRemark={props.newReassignRemark}
            AddNewReassignRemark={props.AddNewReassignRemark}
          />

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="cityName">Previous City</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  fullWidth
                  id="cityName"
                  // label="Remark"
                  variant="outlined"
                  className=""
                  // onChange={props.handleReassignReason}
                  value={props.data.city || ""}
                  disabled
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="cities">Reassigned City</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <FormControl
                  variant="outlined"
                  fullWidth
                  // style={{ width: "50%" }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={props.cityData.map((city: any) => city.city)}
                    filterOptions={(options, state) => {
                      const inputValueLower = state.inputValue.toLowerCase();
                      return options.filter((option) =>
                        option.toLowerCase().includes(inputValueLower)
                      );
                    }}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newValue) => {
                      props.handleItemChange(newValue);
                    }}
                    value={props.selectedCity || ""}
                  />
                </FormControl>
              </FormGroup>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCancelReassignItem}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="yes"
              type="submit"
              // onClick={props.handleReassignConfirm}
            >
              Confirm
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default ChildReassignCityModal;
