import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Table from "../../../../../layout/components/Table/Table";
import { get } from "../../../../../utils/api";
import AddNewButton from "../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import Selectmenu from "../../../../../layout/components/Selectmenu/Selectmenu";
import ListModule from "../../../../../layout/components/CRUD/List/List";

function UserRoles() {
  const [data, setData] = useState<Array<any>>([]);

  let AdminUsersColumns = ["username", "password", "email", "status_text"];

  return (
    <ListModule
      module="user_roles"
      modulePath="/user-roles"
      primary_id="user_role_id"
    />
  );
}

export default UserRoles;
