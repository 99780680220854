import React, { useEffect, useState } from "react";
import { startCase } from "lodash";

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddNewButton from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
// import Filter from "../../../Filter";
import { get, getProfile } from "../../../../../utils/api";
import MasterFilter from "../../../MasterFilter";
import MaterialPagination from "../../../MaterialPagination/MaterialPagination";
import FieldSettingsModal from "../FieldListSettingsModal";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import RefreshIcon from "@mui/icons-material/Refresh";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 991,
  bgcolor: "#E5EEF0",
  // border: "2px solid #000",
  maxHeight: 700,
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

export default function ListHeader(props: any) {
  console.log("props", props);
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleDeleteConfirm = () => {
    setIsDeleteConfirmed(true);
    props.handleUpdate({ del: 1 });
    handleCloseDelete();
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [approver_name, setApproverName] = useState("");

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    fetchProfile();
  }, []);

  const [isListView, setIsListView] = useState(true);

  const handleListView = () => {
    setIsListView(true);
    props.handleListView();
  };

  const handleCardView = () => {
    setIsListView(false);
    props.handleCardView();
  };

  const fetchProfile = async () => {
    console.log("in private*******************");
    let data = await getProfile();
    console.log(data);
    if (!data) {
      navigate("/login");
    } else {
      // let user:any = data.user;
      setApproverName(data.user.first_name + " " + data.user.last_name);
      // setFormData((prevData: any) => ({
      //   ...prevData,
      //   approver_name: data.user.first_name + " " + data.user.last_name,
      // }));
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openModal, openFieldsModal] = React.useState(false);
  const handleOpenModal = () => openFieldsModal(true);
  const handleCloseModal = () => openFieldsModal(false);

  // const options = props.searchFields.map((option: any) => ({
  //   label: option[props.key],
  //   value: option[props.key],
  // }));

  const handleRefresh = () => {
    props.getData();
  };

  return (
    <div className="row invoicesheader-top">
      <div className="col-xs-12">
        <div className="d-md-flex d-block justify-content-between align-items-center tabheader-main">
          <div className="invoice-details">
            {/* <h3>Invoices Details</h3> */}
            <Box sx={{ minWidth: 120 }}>
              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  variant="standard"
                  // value={props.financialYear}
                  onChange={() => props.handleChange}
                >
                  {props.financialYear.map((item: any) => {
                    return (
                      <MenuItem value={item.financialYear}>
                        {item.financialYear}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
              {/* FY 2022-23 */}
            </Box>
          </div>
          <div className="invoice-master-filter">
            <FormGroup>
              <MasterFilter
                module={props.module}
                filterOptions={props.fieldsMaster}
                filterData={props.filterData}
                setFilterData={props.setFilterData}
                sx={{ width: "100%" }}
                placeholder={"Search " + startCase(props.module)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
          </div>
          <div>
            {props.module !== "admin_users" &&
            props.module !== "admin_api_logs" &&
            props.module !== "api-logs" &&
            props.module !== "getFavouritedItems" &&
            props.module !== "getUniqueFavouritedRestaurantItems" &&
            props.module !== "expert_requests" &&
            props.filterType !== "aggregated" ? (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  className="invoice-create-btn"
                  onClick={() => {
                    props.module === "food_linked_applications"
                      ? handleModalOpen()
                      : navigate(props.modulePath + "/new");
                  }}
                >
                  {/* <img src={AddNewButton} alt="" /> */}

                  <span> Create New {props.module}</span>
                </Button>
                <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    {/* <FoodLinkedApplicationModal
                      handleModalClose={handleModalClose}
                      fieldsMaster={props.fieldsMaster}
                      filterData={props.filterData}
                      setFilterData={props.filterData}
                      getData={props.getData}
                    /> */}
                  </Box>
                </Modal>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <MaterialPagination
              count={props.totalCount}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {props.module === "getFavouritedItems" && (
                <>
                  <Button sx={{ color: "#000000" }} onClick={handleRefresh}>
                    <RefreshIcon />
                  </Button>

                  <Button
                    className={isListView ? "button-highlight" : "button-dim"}
                    sx={{ color: "#000000" }}
                    onClick={handleListView}
                  >
                    <ViewListIcon />
                  </Button>

                  <Button
                    className={isListView ? "button-dim" : "button-highlight"}
                    sx={{ color: "#000000" }}
                    onClick={handleCardView}
                  >
                    <ViewCarouselIcon />
                  </Button>
                </>
              )}
              {props.module === "getUniqueFavouritedRestaurantItems" && (
                <>
                  <Button sx={{ color: "#000000" }} onClick={handleRefresh}>
                    <RefreshIcon />
                  </Button>
                  <Button
                    className={isListView ? "button-highlight" : "button-dim"}
                    sx={{ color: "#000000" }}
                    onClick={handleListView}
                  >
                    <ViewListIcon />
                  </Button>

                  <Button
                    className={isListView ? "button-dim" : "button-highlight"}
                    sx={{ color: "#000000" }}
                    onClick={handleCardView}
                  >
                    <ViewCarouselIcon />
                  </Button>
                </>
              )}
              {props.module === "customers" && (
                <>
                  <Button sx={{ color: "#000000" }} onClick={handleRefresh}>
                    <RefreshIcon />
                  </Button>

                  <Button
                    className={isListView ? "button-highlight" : "button-dim"}
                    sx={{ color: "#000000" }}
                    onClick={handleListView}
                  >
                    <ViewListIcon />
                  </Button>

                  <Button
                    className={isListView ? "button-dim" : "button-highlight"}
                    sx={{ color: "#000000" }}
                    onClick={handleCardView}
                  >
                    <ViewCarouselIcon />
                  </Button>
                </>
              )}

              {/* {props.module === "restaurants" && (
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#104474" }}
                  onClick={props.handleRestaurantChain}
                >
                  Configure Restaurant Chain
                </Button>
              )} */}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="invoice-action-btn"
              >
                Action
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {props.module !== "google_api_metrics" &&
                  props.module !== "restaurants" && (
                    <div>
                      <MenuItem
                        onClick={() => {
                          handleOpenDelete();
                          handleClose();
                        }}
                      >
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          props.handleUpdate({
                            status_id: 1,
                            status: "active",
                          });
                          handleClose();
                        }}
                      >
                        Activate
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          props.handleUpdate({
                            status_id: 0,
                            status: "inactive",
                          });
                          handleClose();
                        }}
                      >
                        Deactivate
                      </MenuItem>
                    </div>
                  )}
                {props.module === "restaurants" && (
                  <div>
                    <MenuItem
                      onClick={() => {
                        handleOpenDelete();
                        handleClose();
                      }}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.handleUpdate({
                          status_id: 1,
                          status: "approved",
                          approved_by: "level 1",
                          approved_by_id: 1,
                          approver_name: approver_name,
                        });
                        handleClose();
                      }}
                    >
                      Approve
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        props.handleUpdate({
                          status_id: 0,
                          status: "inactive",
                        });
                        handleClose();
                      }}
                    >
                      Deactivate
                    </MenuItem> */}
                  </div>
                )}
                <MenuItem
                  onClick={() => {
                    props.handleDownload();
                    handleClose();
                  }}
                >
                  Download
                </MenuItem>
              </Menu>
            </div>

            <FieldSettingsModal
              handleOpenModal={handleOpenModal}
              openModal={openModal}
              module={props.module}
              handleCloseModal={handleCloseModal}
              getData={props.getData}
              fieldsMaster={props.fieldsMaster}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
              setRowsPerPage={props.setRowsPerPage}
            />

            <div>
              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure you want to delete this {props.module}?
                  </Typography>
                  <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
                    <Button
                      variant="contained"
                      className="yes"
                      onClick={handleDeleteConfirm}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      className="no"
                      onClick={handleCloseDelete}
                    >
                      No
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
