import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import "./index.scss";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { get } from "../../../../utils/api";
import MapsAutocomplete from "../MapsAutocomplete";

export default function MapSection() {
  const [locationData, setLocationData] = useState<any>({});
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [mapsCenterPoint, setMapsCenterPoint] = useState<any>({
    lat: 20.5937,
    lng: 78.9629,
  });
  const [page, setPage] = useState(0);
  const [mapZoom, setMapZoom] = useState(5);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC1qbr9XrrO4tlNFOSmBljMnbJ9wU2a104",
  });

  // const getLocationData = async () => {
  //   let params = {
  //     page: page,
  //     limit: rowsPerPage,
  //   };

  //   try {
  //     const response = await get("countries", params);
  //     if (response) {
  //       setLocationData(response.data.data);
  //       console.log(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  console.log("locationData", locationData);

  const handleLocationChange = (e: any) => {
    console.log(e);
    setLocationData(e);
    setSelectedLocation(null);
  };

  // console.log(locationData.map((e: any) => e.lat));

  if (!isLoaded)
    return (
      <div>
        <h1></h1>
      </div>
    );

  // const defaultCenter = {
  //   lat: 52.16372570284234,
  //   lng: 20.93165710701747,
  // };

  console.log("seecetedLocation", selectedLocation);

  return (
    <div className="position-relative">
      <div className="maps-autocomplete">
        <MapsAutocomplete
          onLocationChange={handleLocationChange}
          setMapZoom={setMapZoom}
          setMapsCenterPoint={setMapsCenterPoint}
          locationData={locationData}
        />
      </div>
      <GoogleMap
        mapContainerClassName="map-container"
        zoom={mapZoom}
        center={mapsCenterPoint}
        options={{
          mapId: "1b1d9ea1f1360337",
          // zoom: 11,
          // center: mapsCenterPoint,
          disableDefaultUI: true,
        }}
      >
        {/* {locationData.map((e: any) => (
          <MarkerF
            position={{ lat: e.lat, lng: e.lng }}
            icon={{
              url: "/images/location-marker.svg",
              scaledSize: new window.google.maps.Size(32, 32),
            }}
            onClick={() => setSelectedLocation(e)}
          ></MarkerF>
        ))} */}

        {locationData &&
          locationData.length > 0 &&
          locationData.map((e: any) => (
            <MarkerF
              key={e.id} // Add a unique key to each marker
              position={{
                lat: e.geometry.location.lat,
                lng: e.geometry.location.lng,
              }}
              // icon={{
              //   url: "/images/location-marker.svg",
              //   scaledSize: new window.google.maps.Size(32, 32),
              // }}
              onClick={() => {
                setSelectedLocation(e);
                console.log(e);
              }}
            ></MarkerF>
          ))}

        {selectedLocation && (
          <div>
            <InfoWindow
              onCloseClick={() => setSelectedLocation(null)}
              position={{
                lat: selectedLocation.geometry.location.lat,
                lng: selectedLocation.geometry.location.lng,
              }}
            >
              <div className="info-window-main">
                <div className="info-window-inner">
                  <h3>{selectedLocation.state}</h3>
                  <p>{selectedLocation.city}</p>
                  <p>{selectedLocation.area}</p>

                  <Divider sx={{ width: "100%", bgcolor: "#A5A59F" }} />
                </div>
                {/* <div className="text-center">
                  <Button
                    component={Link}
                    to={selectedLocation.route}
                    target="_blank"
                  >
                    <span>Open in the map</span>
                  </Button>
                </div> */}
              </div>
            </InfoWindow>
          </div>
        )}
      </GoogleMap>
    </div>
  );
}
