import React, { useContext, useEffect, useState } from "react";
import { ReactDOM } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import DropdownOpenerIcon from "../../assets/images/Sidebar/dropdown-open-arrow.svg";
import DropdownCloserIcon from "../../assets/images/Sidebar/dropdown-close-arrow.svg";
import { getProfile } from "../../../utils/api";
import { AppContext } from "../../../utils/state";

function Sidebar({ handleClick }: { handleClick: any }) {
  const location = useLocation();

  const [openLocationMenu, setOpenLocationMenu] = useState(true);

  const [openMyCircleMenu, setOpenMyCircleMenu] = useState(true);

  const [openFollowersMenu, setOpenFollowersMenu] = useState(true);

  const [openCustomerMenu, setOpenCustomerMenu] = useState(true);

  const [openMasterMenu, setOpenMasterMenu] = useState(true);

  const [openAdminMenu, setOpenAdminMenu] = useState(true);

  const [sideNav, setSideNav] = useState(true);
  let msg = true;

  const handleLocationDropdownOpen = () => {
    setOpenLocationMenu(!openLocationMenu);
  };

  const handleMyCircleDropdownOpen = () => {
    setOpenMyCircleMenu(!openMyCircleMenu);
  };

  const handleFollowersDropdownOpen = () => {
    setOpenFollowersMenu(!openFollowersMenu);
  };

  const handleCustomerDropdownOpen = () => {
    setOpenCustomerMenu(!openCustomerMenu);
  };

  const handleMasterDropdownOpen = () => {
    setOpenMasterMenu(!openMasterMenu);
  };

  const handleAdminDropdownOpen = () => {
    setOpenAdminMenu(!openAdminMenu);
  };

  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (!state.user._id) {
      fetchProfile();
    }
  }, [location]);

  const [userRoleData, setUserRoleData] = useState<any>({});

  const fetchProfile = async () => {
    let data = await getProfile();
    console.log("newSecondaryData", data);
    setUserRoleData(data.user);
  };

  return (
    <>
      {sideNav ? (
        <div className="sidebar">
          {userRoleData.user_role_id === 1 ? (
            <>
              <a
                className="sidenav_head d-flex justify-content-between align-items-center"
                href="#home"
              >
                <span className="sidenav_head_title"> Gate</span>
              </a>
              <RxCross2
                className="sidebar_cross"
                onClick={() => {
                  setSideNav(false);
                  handleClick(msg);
                }}
              />
              <Link
                to="/dashboard"
                className={
                  location.pathname.match("/dashboard")
                    ? "active sidenav-sub-item sidenav-dashboard-item"
                    : "sidenav-sub-item sidenav-dashboard-item"
                }
              >
                Dashboard
              </Link>

              <div
                onClick={handleMasterDropdownOpen}
                className={
                  openMasterMenu
                    ? "sidenav-sub-heading"
                    : "sidenav-sub-heading sidenav-item-spacing"
                }
              >
                <img
                  src={openMasterMenu ? DropdownCloserIcon : DropdownOpenerIcon}
                  alt=""
                />{" "}
                <span>Master Data</span>{" "}
              </div>
              {openMasterMenu && (
                <div className="sidebar-dropdown-items">
                  <Link
                    to="/brands"
                    className={
                      location.pathname.match("/brands")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Brands
                  </Link>

                  <Link
                    to="/products"
                    className={
                      location.pathname.match("/products")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Products
                  </Link>
                  <Link
                    to="/enquiries"
                    className={
                      location.pathname.match("/enquiries")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Enquiries
                  </Link>

                  {/* <Link
                    to="/product-brands"
                    className={
                      location.pathname.match("/product-brands")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Product Brands
                  </Link> */}

                  <Link
                    to="/product-types"
                    className={
                      location.pathname.match("/product-types")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Product Types
                  </Link>

                  <Link
                    to="/product-categories"
                    className={
                      location.pathname.match("/product-categories")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Product Categories
                  </Link>
                </div>
              )}

              <div
                onClick={handleAdminDropdownOpen}
                className={
                  openAdminMenu
                    ? "sidenav-sub-heading"
                    : "sidenav-sub-heading sidenav-item-spacing"
                }
              >
                <img
                  src={openAdminMenu ? DropdownCloserIcon : DropdownOpenerIcon}
                  alt=""
                />{" "}
                <span>Admin</span>{" "}
              </div>
              {openAdminMenu && (
                <div className="sidebar-dropdown-items">
                  {/* <Link
                    to="/settings"
                    className={
                      location.pathname.match("/settings")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Settings
                  </Link> */}

                  <Link
                    to="/admin-users"
                    className={
                      location.pathname.match("/admin-users")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Admin Users
                  </Link>

                  <Link
                    to="/user-roles"
                    className={
                      location.pathname.match("/user-roles")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    User Roles
                  </Link>

                  {/* <Link
                to="/contacts"
                className={
                  location.pathname.match("/contacts")
                    ? "active sidenav-sub-item sidenav-dashboard-item"
                    : "sidenav-sub-item sidenav-dashboard-item"
                }
              >
                Contacts
              </Link> */}
                </div>
              )}
            </>
          ) : (
            <>
              <a
                className="sidenav_head d-flex justify-content-between align-items-center"
                href="#home"
              >
                <span className="sidenav_head_title"> Gate</span>
              </a>
              <RxCross2
                className="sidebar_cross"
                onClick={() => {
                  setSideNav(false);
                  handleClick(msg);
                }}
              />
              <Link
                to="/dashboard"
                className={
                  location.pathname.match("/dashboard")
                    ? "active sidenav-sub-item sidenav-dashboard-item"
                    : "sidenav-sub-item sidenav-dashboard-item"
                }
              >
                Dashboard
              </Link>

              <Link
                to="/customers"
                className={
                  location.pathname.match("/customers")
                    ? "active sidenav-sub-item sidenav-dashboard-item"
                    : "sidenav-sub-item sidenav-dashboard-item"
                }
              >
                Customers
              </Link>

              <div
                onClick={handleMasterDropdownOpen}
                className={
                  openMasterMenu
                    ? "sidenav-sub-heading"
                    : "sidenav-sub-heading sidenav-item-spacing"
                }
              >
                <img
                  src={openMasterMenu ? DropdownCloserIcon : DropdownOpenerIcon}
                  alt=""
                />{" "}
                <span>Master Data</span>{" "}
              </div>
              {openMasterMenu && (
                <div className="sidebar-dropdown-items">
                  <Link
                    to="/key-ingredients"
                    className={
                      location.pathname.match("/key-ingredients")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Key Ingredients
                  </Link>

                  <Link
                    to="/favourited_dishes"
                    className={
                      location.pathname.match("/favourited_dishes")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Favourited Dishes
                  </Link>

                  <Link
                    to="/unique_favourited_dishes"
                    className={
                      location.pathname.match("/unique_favourited_dishes")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Unique Favourited Dishes
                  </Link>

                  <Link
                    to="/items"
                    className={
                      location.pathname.match("/items")
                        ? "active sidenav-sub-item sidenav-dashboard-item"
                        : "sidenav-sub-item sidenav-dashboard-item"
                    }
                  >
                    Items
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="sidebar sidebar_collapsed">
          <a
            className="sidenav_collapsed_head d-flex justify-content-between align-items-center"
            href="#home"
          >
            <FaBars
              className="fabars-color"
              onClick={() => {
                setSideNav(true);
                handleClick(!msg);
              }}
            />
          </a>
        </div>
      )}
    </>
  );
}

export default Sidebar;
