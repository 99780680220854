import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { get } from "../../../../utils/api";
import LocationMarkerIcon from "../../../assets/images/Tabledata/location_marker.svg";
import CloseIcon from "@mui/icons-material/Close";
import NewRemarkModal from "./NewRemarkModal";
import ChildReassignRestaurantModal from "./ChildReassignRestaurantModal";
import ChildReassignCityModal from "./ChildReassignCityModal";

function ChildDeleteModal(props: any) {
  console.log("propsold", props);

  return (
    <Modal
      open={props.openDeleteRemark}
      onClose={props.handleDeleteRemarkClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={props.style}>
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please add a reason for deleting this item
          </Typography>

          <div className="mt-3 d-flex">
            <img
              src={props.customerDetailData.profile_pic}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <div className="d-flex">
                <h6 className="me-3">{props.data.full_name}</h6>
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.customer_level}
                </p>
              </div>
              <div className="">
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.city}
                </p>
              </div>
            </div>
          </div>

          <Divider className="mt-2" sx={{ opacity: 1 }} />

          <div className="mt-3 d-flex align-items-center">
            <img
              src={props.itemDetailData.image_url}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <h6 className="me-3">{props.data.item_name}</h6>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                {props.data.cuisine_name}
              </p>
            </div>
          </div>
          <div className="mt-3 d-flex">
            <img src={LocationMarkerIcon} alt="" className="me-2" />
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              {props.data.restaurant_name}
            </p>
          </div>

          <TextField
            fullWidth
            id="outlined-basic"
            label="Remark"
            variant="outlined"
            multiline
            rows={4}
            className="mt-4"
            onChange={props.handleDeleteReason}
          />

          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCancelFavDish}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="yes"
              onClick={props.handleDeleteConfirm}
            >
              Confirm
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  );
}

function ChildReassignModal(props: any) {
  const [openNewRemarkModal, setOpenNewRemarkModal] = useState(false);
  const handleNewRemarkModalOpen = () => {
    setOpenNewRemarkModal(true);
  };
  const handleNewRemarkModalClose = () => {
    setOpenNewRemarkModal(false);
  };

  const addNewRemark = () => {
    // props.AddNewReassignRemark();
    handleNewRemarkModalOpen();
  };

  const [isReassignRemarkValid, setIsReassignRemarkValid] = useState(true);

  const handleInputChange = (event: any, newValue: any) => {
    props.handleReassignReasonChange(newValue);

    const isValid = props.reassignRemarkData.some(
      (item: any) => item.remark.toLowerCase() === newValue.toLowerCase()
    );

    console.log("isValid", isValid);

    setIsReassignRemarkValid(!isValid);
  };

  return (
    <Modal
      open={props.openReassignRemark}
      onClose={props.handleReassignRemarkClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={props.style}>
        <form onSubmit={props.handleReassignConfirm}>
          <Typography id="modal-reassign-title" variant="h6" component="h2">
            Please provide these details before you proceed
          </Typography>
          <div className="mt-3 d-flex">
            <img
              src={props.customerDetailData.profile_pic}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <div className="d-flex">
                <h6 className="me-3">{props.data.full_name}</h6>
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.customer_level}
                </p>
              </div>
              <div className="">
                <p
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#687385",
                  }}
                >
                  {props.data.city}
                </p>
              </div>
            </div>
          </div>

          <Divider className="mt-2" sx={{ opacity: 1 }} />

          <div className="mt-3 d-flex align-items-center">
            <img
              src={props.itemDetailData.image_url}
              alt=""
              className="me-3"
              style={{ width: "58px", height: "58px", borderRadius: "8px" }}
            />
            <div>
              <h6 className="me-3">{props.data.item_name}</h6>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#687385",
                }}
              >
                {props.data.cuisine_name}
              </p>
            </div>
          </div>
          <div className="mt-3 d-flex">
            <img src={LocationMarkerIcon} alt="" className="me-2" />
            <p
              style={{
                fontFamily: "Roboto, sans-serif",
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#687385",
              }}
            >
              {props.data.restaurant_name}
            </p>
          </div>

          <div className="row mt-4 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="reassignRemark">Reassign Remark</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup className="d-flex flex-row align-items-center">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="me-3"
                  options={props.reassignRemarkData.map(
                    (item: any) => item.remark
                  )}
                  filterOptions={(options, state) => {
                    const inputValueLower = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.toLowerCase().includes(inputValueLower)
                    );
                  }}
                  sx={{ width: "50%" }}
                  renderInput={(params) => <TextField {...params} />}
                  onInputChange={(event, newValue) => {
                    handleInputChange(event, newValue);
                  }}
                  value={props.selectedReassignRemark.remark || ""}
                />
                {isReassignRemarkValid && (
                  <Link
                    sx={{ cursor: "pointer" }}
                    className=""
                    onClick={() => addNewRemark()}
                  >
                    Add New Remark
                  </Link>
                )}
              </FormGroup>
            </div>
          </div>

          <NewRemarkModal
            openNewRemarkModal={openNewRemarkModal}
            handleNewRemarkModalOpen={handleNewRemarkModalOpen}
            handleNewRemarkModalClose={handleNewRemarkModalClose}
            style={props.style}
            handleNewReassignRemark={props.handleNewReassignRemark}
            newReassignRemark={props.newReassignRemark}
            AddNewReassignRemark={props.AddNewReassignRemark}
          />

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="previousItem">Previous Item</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  fullWidth
                  id="previousItem"
                  // label="Remark"
                  variant="outlined"
                  className=""
                  // onChange={props.handleReassignReason}
                  value={props.previousItem || ""}
                  disabled
                />
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="items">Reassigned Item</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: "50%" }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.itemData.map((item: any) => item.item_name)}
                    filterOptions={(options, state) => {
                      const inputValueLower = state.inputValue.toLowerCase();
                      return options.filter((option) =>
                        option.toLowerCase().includes(inputValueLower)
                      );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newValue) => {
                      props.handleItemChange(newValue);
                    }}
                    value={props.selectedItem.item_name || ""}
                  />
                </FormControl>
              </FormGroup>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCancelReassignItem}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="yes"
              type="submit"
              // onClick={props.handleReassignConfirm}
            >
              Confirm
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

function EditModal(props: any) {
  const [itemDetailData, setItemDetailData] = useState<any>({});
  const [customerDetailData, setCustomerDetailData] = useState<any>({});

  console.log("props.data", props.data);

  useEffect(() => {
    if (props.open || props.openReassign) {
      getItemById(props.data.item_id);
      getCustomerById(props.data._id);
    }
  }, [props.data.item_id]);

  const getItemById = async (id: any) => {
    try {
      const response = await get(`items/${id}`, {});
      if (response) {
        console.log("response", response.data);
        setItemDetailData(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCustomerById = async (id: any) => {
    try {
      const response = await get(`customers/${id}`, {});
      if (response) {
        console.log("responsecustomer", response.data);
        setCustomerDetailData(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return props.type === "removeItem" ? (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={props.style}>
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this item?
          </Typography>
          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCloseFavDish}
            >
              No
            </Button>

            <ChildDeleteModal
              openDeleteRemark={props.openDeleteRemark}
              handleDeleteRemarkClose={props.handleDeleteRemarkClose}
              style={props.style}
              handleDeleteReason={props.handleDeleteReason}
              handleDeleteConfirm={props.handleDeleteConfirm}
              handleCancelFavDish={props.handleCancelFavDish}
              data={props.data}
              itemDetailData={itemDetailData}
              customerDetailData={customerDetailData}
            />

            <Button
              variant="contained"
              className="yes"
              onClick={props.FavDishDeleteConfirm}
            >
              Yes
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  ) : props.type === "reassignItem" ? (
    <Modal
      open={props.openReassign}
      onClose={props.handleReassignClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={props.style}>
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to reassign this item?
          </Typography>
          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCloseReassignItem}
            >
              No
            </Button>

            <ChildReassignModal
              openReassignRemark={props.openReassignRemark}
              handleReassignRemarkClose={props.handleReassignRemarkClose}
              style={props.style}
              handleReassignReason={props.handleReassignReason}
              handleReassignConfirm={props.handleReassignConfirm}
              handleCancelReassignItem={props.handleCancelReassignItem}
              itemData={props.itemData}
              selectedItem={props.selectedItem}
              handleItemChange={props.handleItemChange}
              previousItem={props.previousItem}
              reassignRemarkData={props.reassignRemarkData}
              handleReassignReasonChange={props.handleReassignReasonChange}
              newReassignRemark={props.newReassignRemark}
              handleNewReassignRemark={props.handleNewReassignRemark}
              selectedReassignRemark={props.selectedReassignRemark}
              AddNewReassignRemark={props.AddNewReassignRemark}
              data={props.data}
              itemDetailData={itemDetailData}
              customerDetailData={customerDetailData}
            />

            <Button
              variant="contained"
              className="yes"
              onClick={props.FavDishReassignConfirm}
            >
              Yes
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  ) : props.type === "reassignRestaurant" ? (
    <Modal
      open={props.openReassignRestaurant}
      onClose={props.handleReassignRestaurantClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={props.style}>
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to reassign this restaurant?
          </Typography>
          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCloseReassignRestaurant}
            >
              No
            </Button>

            <ChildReassignRestaurantModal
              openReassignRemark={props.openReassignRemark}
              handleReassignRemarkClose={props.handleReassignRemarkClose}
              style={props.style}
              handleReassignReason={props.handleReassignReason}
              handleReassignConfirm={props.handleReassignConfirm}
              handleCancelReassignItem={props.handleCancelReassignItem}
              restaurantData={props.restaurantData}
              selectedRestaurant={props.selectedRestaurant}
              selectedRestaurantName={props.selectedRestaurantName}
              handleNewChange={props.handleNewChange}
              handleItemChange={props.handleItemChange}
              previousRestaurant={props.previousRestaurant}
              reassignRemarkData={props.reassignRemarkData}
              handleReassignReasonChange={props.handleReassignReasonChange}
              newReassignRemark={props.newReassignRemark}
              handleNewReassignRemark={props.handleNewReassignRemark}
              selectedReassignRemark={props.selectedReassignRemark}
              AddNewReassignRemark={props.AddNewReassignRemark}
              data={props.data}
              itemDetailData={itemDetailData}
              customerDetailData={customerDetailData}
              allCityData={props.allCityData}
              handleInputCityChange={props.handleInputCityChange}
              selectedCity={props.selectedCity}
              setSelectedCity={props.setSelectedCity}
              setSelectedRestaurant={props.setSelectedRestaurant}
              currentCity={props.currentCity}
            />

            <Button
              variant="contained"
              className="yes"
              onClick={props.RestaurantReassignConfirm}
            >
              Yes
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  ) : props.type === "reassignCity" ? (
    <Modal
      open={props.openReassignCity}
      onClose={props.handleReassignCityClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={props.style}>
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to reassign this city?
          </Typography>
          <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
            <Button
              variant="outlined"
              className="no"
              onClick={props.handleCloseReassignCity}
            >
              No
            </Button>

            <ChildReassignCityModal
              openReassignRemark={props.openReassignRemark}
              handleReassignRemarkClose={props.handleReassignRemarkClose}
              style={props.style}
              handleReassignReason={props.handleReassignReason}
              handleReassignConfirm={props.handleReassignConfirm}
              handleCancelReassignItem={props.handleCancelReassignItem}
              cityData={props.cityData}
              selectedCity={props.selectedCity}
              handleItemChange={props.handleItemChange}
              previousCity={props.previousCity}
              reassignRemarkData={props.reassignRemarkData}
              handleReassignReasonChange={props.handleReassignReasonChange}
              newReassignRemark={props.newReassignRemark}
              handleNewReassignRemark={props.handleNewReassignRemark}
              selectedReassignRemark={props.selectedReassignRemark}
              AddNewReassignRemark={props.AddNewReassignRemark}
              data={props.data}
              itemDetailData={itemDetailData}
              customerDetailData={customerDetailData}
              allCityData={props.allCityData}
              handleInputCityChange={props.handleInputCityChange}
              setSelectedCity={props.setSelectedCity}
              currentCity={props.currentCity}
            />

            <Button
              variant="contained"
              className="yes"
              onClick={props.CityReassignConfirm}
            >
              Yes
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  ) : (
    <></>
  );
}

export default EditModal;
