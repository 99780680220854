import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../../../../utils/api";
import SnackbarAlert from "../../../../common/sanckbar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function NewUserRole() {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    user_role: "",
    status: "inactive",
    status_id: 0,
    del: 0,
  });

  const handleNewUserRole = async (e: any) => {
    e.preventDefault();
    console.log(e);

    let userRoleDetails = {
      user_role: formData.user_role,
      status: formData.status,
      status_id: formData.status_id,
      del: formData.del,
    };

    try {
      const response = await post("user_roles", userRoleDetails);
      console.log(response.data);
      if (response) {
        alert("User Role added successfully", "success");
        navigate("/user-roles/list");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />

      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>New User Role</h5>
          </div>
        </div>
        <form onSubmit={handleNewUserRole} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userRole">User Role</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="userRole"
                  className="reg-input name-field"
                  name="user_role"
                  value={formData.user_role}
                  onChange={handleChange}
                  placeholder="User Role"
                />
              </FormGroup>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewUserRole;
