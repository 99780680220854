import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import DummyImage from "../../../assets/images/Tabledata/dummy-image.png";
import LocationMarkerNewIcon from "../../../assets/images/Tabledata/location-marker-new.svg";
import moment from "moment";
import DummyIcon from "../../../assets/images/Tabledata/dummy-icon.svg";
import ApprovedIcon from "../../../assets/images/Tabledata/approved-icon.svg";
import RejectedIcon from "../../../assets/images/Tabledata/rejected-icon.svg";
import PendingIcon from "../../../assets/images/Tabledata/pending-icon.svg";
import HoldIcon from "../../../assets/images/Tabledata/hold-icon.svg";
import UploadIcon from "@mui/icons-material/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import StarIcon from "../../../assets/images/Tabledata/star-icon.svg";
import GotoIcon from "../../../assets/images/Tabledata/goto-icon.svg";
import DeliveryIcon from "../../../assets/images/Tabledata/deliveries-icon.svg";
import EasyFoodIcon from "../../../assets/images/Tabledata/easyfood-icon.svg";
import TimelyIcon from "../../../assets/images/Tabledata/timely-icon.svg";
import { Tooltip, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { Link } from "react-router-dom";
import TableAddNewIcon from "../../../assets/images/Tabledata/add-new-btn.svg";
import TableEditIcon from "../../../assets/images/Tabledata/edit-btn.svg";

const ITEM_HEIGHT = 48;

function FavItemCard(props: any) {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>(
    Array.from({ length: Object.keys(props.data).length }, () => null)
  );
  // const open = Boolean(anchorEl);
  const isOpen = (index: number) => Boolean(anchorEls[index]);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    // Set the appropriate anchorEl in the array
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClose = (index: number) => {
    // Set the appropriate anchorEl in the array to null
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  let [options, setOptions] = useState([
    {
      option_id: 0,
      option_name: "Reassign Item",
    },
    {
      option_id: 1,
      option_name: "Delete",
    },
    {
      option_id: 2,
      option_name: "Upload Item Image",
    },
    {
      option_id: 3,
      option_name: "Upload Fav Item Image",
    },
    {
      option_id: 4,
      option_name: "Reassign Restaurant",
    },
    {
      option_id: 5,
      option_name: "Reassign City",
    },
  ]);

  const handleItemStatus = (e: any) => {
    props.handleItemModal(e);
  };

  const handleRestaurantStatus = (e: any) => {
    props.handleRestaurantModal(e);
  };

  const handleFavItemStatus = (e: any) => {
    props.handleFavItemModal(e);
  };

  const handleMoreVert = (e: any, newValue: any, index: any) => {
    handleClose(index);

    if (e.option_id === 0) {
      props.handleFavDishReassign(newValue);
    } else if (e.option_id === 1) {
      props.handleFavDishDelete(newValue);
    } else if (e.option_id === 4) {
      props.handleReassignRestaurant(newValue);
    } else if (e.option_id === 5) {
      props.handleReassignCity(newValue);
    }
  };

  return (
    <>
      {Object.entries(props.data).map(([key, value]: any, index: any) => (
        <Card
          sx={{
            width: "291px",
            height: "800px",
            padding: "12px 11px 8px 10px",
            border:
              value.favourited_item_status === "approved"
                ? "1px solid #3BC525"
                : value.favourited_item_status === "pending"
                ? "1px solid #C52525"
                : "1px solid #11385B",
            borderRadius: "12px",
          }}
          key={index}
          className="favourited-items-card"
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <h6>
                  {moment(value.fvrt_item_creation_date).format(
                    "DD-MMM-YYYY hh:mm a"
                  )}{" "}
                </h6>
                {/* <Checkbox
                      sx={{ width: "24px", height: "24px" }}
                      checked={props.checked.includes(value[props.primary_id])}
                      onChange={() => props.handleCheckbox(value)}
                      {...props.label}
                    /> */}

                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={isOpen(index) ? "long-menu" : undefined}
                    aria-expanded={isOpen(index) ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, index)}
                    sx={{ padding: "0px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEls[index]}
                    // open={open}
                    open={isOpen(index)}
                    onClose={() => handleClose(index)}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "25ch",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    {options.map((option) =>
                      option.option_id === 2 ? (
                        <MenuItem key={option.option_id}>
                          <label>
                            <input
                              className="input-image"
                              accept="image/*"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                props.handleItemImage(event, value);
                              }}
                            />
                            {option.option_name}
                          </label>
                        </MenuItem>
                      ) : option.option_id === 3 ? (
                        <MenuItem key={option.option_id}>
                          <label>
                            <input
                              className="input-image"
                              accept="image/*"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                props.handleFavDishUploadImage(event, value)
                              }
                            />
                            {option.option_name}
                          </label>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key={option.option_id}
                          onClick={() => handleMoreVert(option, value, index)}
                        >
                          {option.option_name}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </div>
              </div>
              {/* <div className="fav-card-count">
                    <h6>21 Awardee</h6>
                  </div> */}

              <div className="d-flex align-items-center">
                <img
                  src={value.profile_pic ? value.profile_pic : DummyIcon}
                  alt=""
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <div>
                  <div className="d-flex align-items-center">
                    <h6>{value.full_name ? value.full_name : ""}</h6>
                    {value.customer_level ? (
                      <span className="badge bg-primary ms-2 me-1">
                        {value.customer_level}
                      </span>
                    ) : (
                      ""
                    )}
                    {value.status === "active" || "Active" ? (
                      <img
                        src={ApprovedIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    ) : (
                      <img
                        src={RejectedIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    )}
                  </div>
                  <span className="city-name">{value.city} </span>
                  {value.user_type ? (
                    <span className="badge bg-primary ms-2 me-1">
                      {value.user_type}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Divider sx={{ bgcolor: "#C5C8C9" }} className="mb-2" />

              <div
                className="d-flex item-status-card"
                onClick={() => handleItemStatus(value)}
              >
                <img
                  src={value.item_image ? value.item_image : DummyImage}
                  alt=""
                  style={{
                    width: "61px",
                    height: "61px",
                    marginRight: "11px",
                    borderRadius: "8px",
                  }}
                />
                <div className="fav-card-item-info">
                  <div className="d-flex align-items-center">
                    {value &&
                    value.item_name &&
                    value.item_name.length >= 18 ? (
                      <Tooltip title={value.item_name} placement="top-end">
                        <h5 style={{ marginBottom: "2px", marginRight: "6px" }}>
                          {`${value.item_name.slice(0, 18)}...`}
                        </h5>
                      </Tooltip>
                    ) : (
                      <h5 style={{ marginBottom: "2px", marginRight: "6px" }}>
                        {value.item_name}
                      </h5>
                    )}

                    {value.item_status_id === 1 ? (
                      <img
                        src={ApprovedIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    ) : value.item_status_id === 0 ? (
                      <img
                        src={PendingIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    ) : value.item_status_id === 3 ? (
                      <img
                        src={PendingIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    ) : (
                      <img
                        src={HoldIcon}
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                    )}
                  </div>
                  <h6 style={{ marginBottom: "5px" }}>{value.food_type} </h6>
                  <h6>{value.cuisine_name} </h6>
                </div>
              </div>
              <div>
                <Typography variant="h6">Fav Item Image</Typography>
                <img
                  src={
                    value.favourited_item_image_url
                      ? value.favourited_item_image_url
                      : ""
                  }
                  alt=""
                  style={{
                    width: "61px",
                    height: "61px",
                    borderRadius: "8px",
                  }}
                />
              </div>

              <div
                className="d-flex align-items-center"
                style={{
                  marginBottom: "18px",
                  marginLeft: "2px",
                  gap: "1rem",
                }}
              >
                <Link to={"/items/details/" + value.item_id}>
                  <img src={TableAddNewIcon} alt="" />
                </Link>
                <Link to={"/items/edit/" + value.item_id}>
                  <img src={TableEditIcon} alt="" />
                </Link>
              </div>

              <Card
                className="d-flex flex-column mb-2"
                sx={{ padding: "7px 9px 7px 9px", cursor: "pointer" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ marginBottom: "13px" }}
                >
                  <div>
                    {value.types.map((type: any, index: any) => (
                      <h6
                        key={index}
                        style={{
                          color: "#666666",
                          fontFamily: "Roboto",
                          fontWeight: "normal",
                          fontSize: "12px",
                        }}
                      >
                        {type}
                      </h6>
                    ))}
                  </div>
                  <div className="text-center">
                    <a
                      href={`https://www.google.com/maps/place/?q=place_id:${value.place_id}`}
                      target="_blank"
                    >
                      <GoogleIcon sx={{ color: "#000000" }} />
                    </a>
                    <h6
                      style={{
                        color: "#666666",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: "12px",
                      }}
                    >
                      Google
                    </h6>
                  </div>
                </div>
                <div
                  className="d-flex mb-2 restaurant-status-card"
                  onClick={() => handleRestaurantStatus(value)}
                >
                  <img
                    src={LocationMarkerNewIcon}
                    alt=""
                    style={{ marginRight: "5px" }}
                  />
                  <div className="fav-card-dish-address">
                    <div className="d-flex align-items-center">
                      {value.restaurant_name &&
                      value.restaurant_name.length &&
                      value.restaurant_name.length >= 26 ? (
                        <Tooltip
                          title={value.restaurant_name}
                          placement="top-end"
                        >
                          <p style={{ marginRight: "4px" }}>
                            {`${value.restaurant_name.slice(0, 26)}...`}
                          </p>
                        </Tooltip>
                      ) : (
                        <p style={{ marginRight: "4px" }}>
                          {value.restaurant_name}
                        </p>
                      )}
                      {value.restaurant_status === "approved" ||
                      value.restaurant_status === "Approved" ? (
                        <img
                          src={ApprovedIcon}
                          alt=""
                          style={{ width: "12px", height: "12px" }}
                        />
                      ) : value.restaurant_status === "pending" ||
                        value.restaurant_status === "Pending" ? (
                        <img
                          src={PendingIcon}
                          alt=""
                          style={{ width: "12px", height: "12px" }}
                        />
                      ) : (
                        <img
                          src={HoldIcon}
                          alt=""
                          style={{ width: "12px", height: "12px" }}
                        />
                      )}
                    </div>
                    <h6 style={{ whiteSpace: "nowrap" }}>{value.city}</h6>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    marginBottom: "18px",
                    marginLeft: "2px",
                    gap: "1rem",
                  }}
                >
                  <Link to={"/restaurants/details/" + value.restaurant_id}>
                    <img src={TableAddNewIcon} alt="" />
                  </Link>
                  <Link to={"/restaurants/edit/" + value.restaurant_id}>
                    <img src={TableEditIcon} alt="" />
                  </Link>
                </div>
                <div
                  className=""
                  style={{
                    marginBottom: "18px",
                    marginLeft: "2px",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h6">
                    Business Status: {value.business_status}
                  </Typography>
                  {/* <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                    <Typography variant="h6">Restaurant Cuisines:</Typography>
                    <div
                      className="d-flex flex-wrap"
                      style={{ gap: "8px 2px" }}
                    >
                      {value.restaurant_cuisines.map(
                        (cuisine: any, cuisineIndex: number) => (
                          <Card
                            key={cuisineIndex}
                            className="me-3"
                            sx={{ padding: "4px" }}
                          >
                            <h6
                              style={{
                                marginBottom: 0,
                                fontSize: "12px",
                              }}
                            >
                              {cuisine.cuisine_name}
                            </h6>
                          </Card>
                        )
                      )}
                    </div>
                  </div> */}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: "18px" }}
                >
                  <div className="d-flex align-items-center me-3">
                    <img
                      src={StarIcon}
                      alt=""
                      style={{ marginRight: "4.44px" }}
                    />
                    <h6
                      style={{
                        color: "#999999",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {value.rating}{" "}
                    </h6>
                  </div>
                  <div className="me-3">
                    <h6
                      style={{
                        color: "#999999",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {value.user_ratings_total} reviews
                    </h6>
                  </div>
                  <div>
                    <h6
                      style={{
                        color: "#999999",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {value.followers_count} followers
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 style={{ fontSize: "10px" }}>
                    {value.formatted_address}
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {value.linked_apps && value.linked_apps.length > 0 ? (
                    value.linked_apps.map((app: any) => (
                      <div className="text-center">
                        <a href={app.app_link_url} target="_blank">
                          <DeliveryDiningIcon sx={{ color: "#000000" }} />
                        </a>
                        <h6
                          style={{
                            color: "#666666",
                            fontFamily: "Roboto",
                            fontWeight: "normal",
                            fontSize: "12px",
                          }}
                        >
                          {app.food_app_name}
                        </h6>
                      </div>
                    ))
                  ) : (
                    <div style={{ width: "100%", height: "40px" }}></div>
                  )}
                </div>
              </Card>
              {/* <div className="d-flex justify-content-between align-items-center">
                    <label className="btn">
                      <input
                        className="input-image"
                        accept="image/*"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          props.handleFavDishUploadImage(event, value)
                        }
                      />
                      <UploadIcon />
                    </label> */}
              <div
                className="fav-card-approved-info text-end"
                onClick={() => handleFavItemStatus(value)}
              >
                <h6
                  style={{
                    color:
                      value.favourited_item_status === "approved"
                        ? "#3BC525"
                        : value.favourited_item_status === "pending"
                        ? "#C52525"
                        : "#11385B",
                  }}
                >
                  {value.favourited_item_status}{" "}
                </h6>
                <span
                  style={{
                    color:
                      value.favourited_item_status === "approved"
                        ? "#3BC525"
                        : value.favourited_item_status === "pending"
                        ? "#C52525"
                        : "#11385B",
                  }}
                >
                  {" "}
                  {moment(value.fvrt_item_creation_date).format(
                    "DD-MMM-YYYY hh:mm a"
                  )}{" "}
                </span>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}

export default FavItemCard;
