import { FC, useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import App from "../App";
import Login from "../modules/login/login";
import Register from "../modules/register/register";
import { AppContext } from "../utils/state";
import { Logout, AuthPage, useAuth } from "../modules/auth";

const AppRoutes: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { currentUser } = useAuth();
  // let accessToken = "" + localStorage.getItem("accessToken");

  // useEffect(() => {
  //   if (state.isAuthenticated && state.accessToken) {
  //     try {
  //       if (!didRequest.current) {
  //         const { data } = await getUserByToken(apiToken);

  //         if (data) {
  //           console.log("data", data);
  //           console.log("data.data", data.data);
  //           setCurrentUser(data.data)
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error)
  //       if (!didRequest.current) {
  //         logout()
  //       }
  //     } finally {
  //       setShowSplashScreen(false)
  //     }
  //   }
  // }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {/* {state.isAuthenticated ? ( */}
          {/* {accessToken.length > 10 ? ( */}
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          {/* ) : ( */}
            <>
              {/* <Route path='auth/*' element={<AuthPage />} /> */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          {/* )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
