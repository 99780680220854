import React, { useState, useRef, useContext, useEffect } from "react";
import "./Table.scss";
import moment from "moment";
import {
  admin_ts_get,
  awsupload,
  awsupload_ts,
  custom_patch,
  get,
  patch,
  post,
} from "../../../utils/api";
import Chip from "@mui/material/Chip";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TableAddNewIcon from "../../assets/images/Tabledata/add-new-btn.svg";
import TableEditIcon from "../../assets/images/Tabledata/edit-btn.svg";
import ColumnFieldSelect from "../ColumnFieldSelect";
import UploadIcon from "@mui/icons-material/Upload";
import imageCompression from "browser-image-compression";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Box, Button, Card } from "@mui/material";
import StatusUpdater from "./StatusUpdater";
import EditModal from "./FavDishDeleteModal";
import FavDishDeleteModal from "./FavDishDeleteModal";
import { AppContext } from "../../../utils/state";
import MapSection from "./MapSection";
import SnackbarAlert from "../../../modules/common/sanckbar";
import FavItemCard from "./FavItemsCard";
import AdminActivityLog from "./AdminActivityLog";
import CustomerCardView from "./CustomerCardVIew";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Tabledata(props: any) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);
  let data = props.data;

  let dataKeys: any[] = [];
  let [image_name, setImageName] = useState("");
  let [imageFile, setImagefile] = useState<any>();
  let [favImageFile, setFavImagefile] = useState<any>();
  let [image_url, setImageUrl] = useState<any>();
  let [image_id, setImageId] = useState<any>();

  if (props.module === "fields_settings") {
    props.columns.map((e: any) => {
      dataKeys.push(e);
    });
    dataKeys.push("actions");
  } else if (props.fields && props.fields.length > 0) {
    props.fields.map((e: any) => {
      if (e.coloumn_status) {
        dataKeys.push(e.key);
      }
    });
    dataKeys.push("actions");
  } else if (props.columns) {
    dataKeys = props.columns;
  } else if (data[0]) {
    dataKeys = Object.keys(data[0]);
  }

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const showRightSideBar = (rowData: any) => {
    // if (props.rightSidebar) {
    //   props.showRightSidebar({status:false,data:rowData});
    // } else {
    props.showRightSidebar({ status: true, data: rowData });
    // }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModalData, setEditModalData] = useState<any>({});
  const [reassignModalData, setReassignModalData] = useState<any>({});
  const [reassignRestaurantModalData, setReassignRestaurantModalData] =
    useState<any>({});

  const [reassignCityModalData, setReassignCityModalData] = useState<any>({});

  const [openRestaurant, setOpenRestaurant] = useState(false);
  const handleRestaurantOpen = () => setOpenRestaurant(true);
  const handleRestaurantClose = () => {
    setOpenRestaurant(false);
    setRestaurantModalData({});
    dataRefresh();
  };
  const [restaurantId, setRestaurantId] = useState(0);
  const [openItem, setOpenItem] = useState(false);
  const handleItemOpen = () => setOpenItem(true);
  const handleItemClose = () => {
    setOpenItem(false);
    setItemModalData({});
    dataRefresh();
  };
  const [itemId, setItemId] = useState(0);

  const [openFavItems, setOpenFavItems] = useState(false);
  const handleFavItemsOpen = () => setOpenFavItems(true);
  const handleFavItemsClose = () => {
    setOpenFavItems(false);
    setFavItemModalData({});
    dataRefresh();
  };
  const [favItemId, setFavItemId] = useState(0);

  const [openUniqueFavItems, setOpenUniqueFavItems] = useState(false);
  const handleUniqueFavItemsOpen = () => setOpenUniqueFavItems(true);
  const handleUniqueFavItemsClose = () => {
    setOpenUniqueFavItems(false);
    setUniqueFavItemModalData({});
    dataRefresh();
  };
  const [uniqueFavItemId, setUniqueFavItemId] = useState(0);

  const [openDelete, setOpenDelete] = useState(false);
  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [openDeleteRemark, setOpenDeleteRemark] = useState(false);
  const handleDeleteRemarkOpen = () => setOpenDeleteRemark(true);
  const handleDeleteRemarkClose = () => {
    setOpenDeleteRemark(false);
    setDeleteConfirmed(false);
  };
  const [openReassign, setOpenReassign] = useState(false);
  const handleReassignOpen = () => setOpenReassign(true);
  const handleReassignClose = () => setOpenReassign(false);
  const [reassignConfirmed, setReassignConfirmed] = useState(false);
  const [openReassignRemark, setOpenReassignRemark] = useState(false);
  const handleReassignRemarkOpen = () => setOpenReassignRemark(true);
  const handleReassignRemarkClose = () => {
    setOpenReassignRemark(false);
    setReassignConfirmed(false);
    setSelectedReassignRemark({});
  };

  const [openReassignRestaurant, setOpenReassignRestaurant] = useState(false);
  const handleReassignRestaurantOpen = () => setOpenReassignRestaurant(true);
  const handleReassignRestaurantClose = () => setOpenReassignRestaurant(false);
  const [reassignRestaurantConfirmed, setReassignRestaurantConfirmed] =
    useState(false);
  const [openReassignRestaurantRemark, setOpenReassignRestaurantRemark] =
    useState(false);
  const handleReassignRestaurantRemarkOpen = () =>
    setOpenReassignRestaurantRemark(true);
  const handleReassignRestaurantRemarkClose = () => {
    setOpenReassignRestaurantRemark(false);
    setReassignRestaurantConfirmed(false);
    setSelectedReassignRestaurantRemark({});
  };

  const [openReassignCity, setOpenReassignCity] = useState(false);
  const handleReassignCityOpen = () => setOpenReassignCity(true);
  const handleReassignCityClose = () => setOpenReassignCity(false);
  const [reassignCityConfirmed, setReassignCityConfirmed] = useState(false);
  const [openReassignCityRemark, setOpenReassignCityRemark] = useState(false);
  const handleReassignCityRemarkOpen = () => setOpenReassignCityRemark(true);
  const handleReassignCityRemarkClose = () => {
    setOpenReassignCityRemark(false);
    setReassignCityConfirmed(false);
    setSelectedReassignCityRemark({});
  };

  const [selectedCountryData, setSelectedCountryData] = useState<any>({});

  const [previousItem, setPreviousItem] = useState<any>("");
  const [previousRestaurant, setPreviousRestaurant] = useState<any>("");
  const [previousCity, setPreviousCity] = useState<any>("");
  const [dishInfo, setDishInfo] = useState<any>({});

  const [reassignRemarkData, setReassignRemarkData] = useState([]);
  const [newReassignRemark, setNewReassignRemark] = useState<any>("");
  const [reassignRestaurantRemarkData, setReassignRestaurantRemarkData] =
    useState([]);

  const getReassignRemarkData = async (e: any) => {
    try {
      const response = await get("reassign_remarks", {});

      if (response) {
        setReassignRemarkData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataRefresh = () => {
    props.getData();
  };

  useEffect(() => {
    if (
      props.module === "items" ||
      props.module === "restaurants" ||
      props.module === "getFavouritedItems" ||
      props.module === "getUniqueFavouritedRestaurantItems"
    ) {
      props.getData();

      // setFavItemsModalData(data);
    }
  }, [openItem, openRestaurant, openFavItems]);

  const [reassignInfo, setReassignInfo] = useState<any>({
    reassigned_by: "",
    item_id: 0,
    remark: "",
    customer_id: "",
    reassigned_item_id: 0,
  });

  const [reassignRestaurantInfo, setReassignRestaurantInfo] = useState<any>({
    reassigned_by: "",
    restaurant_id: 0,
    remark: "",
    reassigned_restaurant_id: 0,
  });

  const [reassignCityInfo, setReassignCityInfo] = useState<any>({
    reassigned_by: "",
    city_id: 0,
    remark: "",
    reassigned_city_id: 0,
  });

  const [itemData, setItemData] = useState<Array<any>>([]);
  const [restaurantData, setRestaurantData] = useState<Array<any>>([]);
  const [cityData, setCityData] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedRestaurant, setSelectedRestaurant] = useState<any>();
  const [selectedRestaurantData, setSelectedRestaurantData] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedCityData, setSelectedCityData] = useState<any>();
  const [itemModalData, setItemModalData] = useState<any>({});
  const [restaurantModalData, setRestaurantModalData] = useState<any>({});
  const [favItemModalData, setFavItemModalData] = useState<any>({});
  const [uniqueFavItemModalData, setUniqueFavItemModalData] = useState<any>({});
  const [selectedReassignRemark, setSelectedReassignRemark] = useState<any>({
    remark: "",
  });
  const [
    selectedReassignRestaurantRemark,
    setSelectedReassignRestaurantRemark,
  ] = useState<any>({
    remark: "",
  });
  const [selectedReassignCityRemark, setSelectedReassignCityRemark] =
    useState<any>({
      remark: "",
    });

  const handleNewReassignRemark = (e: any) => {
    setNewReassignRemark(e.target.value);
  };

  const AddNewReassignRemark = async (e: any) => {
    e.preventDefault();
    try {
      const response = await post("reassign_remarks", {
        remark: newReassignRemark,
      });
      if (response) {
        console.log(response);
        alert("Reassign remark added successfully", "success");
        // getReassignRemarkData({});
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  const handleRestaurantModal = (e: any, model_name: any) => {
    if (model_name === "restaurants") {
      handleRestaurantOpen();
      setRestaurantId(e._id);
      getRestaurantModalData(e._id);
    } else {
      handleRestaurantOpen();
      setRestaurantId(e.restaurant_id);
      getRestaurantModalData(e.restaurant_id);
    }
  };

  const handleFavItemModal = (e: any) => {
    setFavItemId(e._id);

    let element = {
      full_name: e.full_name,
      customer_id: e._id,
      customer_name: e.full_name,
      item_id: e.item_id,
      item_name: e.item_name,
      item_status: e.item_status,
      restaurant_name: e.restaurant_name,
      restaurant_id: e.restaurant_id,
      restaurant_status: e.restaurant_status,
      approver_name: state.user.first_name,
      favourited_item_status: e.favourited_item_status,
      favourited_item_status_id: e.favourited_item_status_id,
      fav_items_approved_by_id: e.fav_items_approved_by_id,
      fvrt_item_creation_date: e.fvrt_item_creation_date,
      fvrt_item_id: e.fvrt_item_id,
      profile_pic: e.profile_pic,
      customer_level: e.customer_level,
      city: e.city,
      item_image: e.item_image,
      cuisine_name: e.cuisine_name,
      remark: "",
    };

    setFavItemModalData(element);
    // getFavouritedItemModalData(e);
    handleFavItemsOpen();
  };

  const handleItemModal = (e: any) => {
    handleItemOpen();

    setItemId(e.item_id);
    getItemModalData(e.item_id);
  };

  const getItemModalData = async (id: any) => {
    try {
      const response = await get("items/" + id, {});
      if (response) {
        setItemModalData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRestaurantModalData = async (id: any) => {
    try {
      const response = await get("restaurants/" + id, {});
      if (response) {
        console.log("responseRestaurant", response.data);
        setRestaurantModalData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("newrestaurantselecteedDAta", selectedRestaurant);

  const handleFavDishDelete = (e: any) => {
    setDishInfo(e);
    handleDeleteOpen();
    setEditModalData(e);
  };

  const handleCloseFavDish = () => {
    handleDeleteClose();
  };

  const handleCancelFavDish = () => {
    handleDeleteRemarkClose();
    handleDeleteClose();
    // setDeleteConfirmed(false);
  };

  const FavDishDeleteConfirm = () => {
    handleDeleteRemarkOpen();
  };

  const handleDeleteConfirm = () => {
    custom_patch(`removeItem`, dishInfo)
      .then((res) => {
        props.getData();
        alert("Item removed successfully", "success");
      })
      .catch(function (error) {
        console.log(error);
        alert("Something went wrong", "warning");
      });
    handleDeleteClose();
  };

  const handleDeleteReason = (e: any) => {
    setDishInfo({
      ...dishInfo,
      remark: e.target.value,
      deleted_by: state.user.first_name,
    });
  };

  const handleDetails = (e: any) => {
    navigate(props.detailLink + e._id);
  };

  const handleEdit = (e: any) => {
    navigate(props.editLink + e._id);
  };

  const handleUpload = (event: any, e: any) => {
    console.log(event, e);
  };

  const handleFavDishReassign = (e: any) => {
    setPreviousItem(e.item_name);
    setReassignInfo({
      ...reassignInfo,
      item_id: e.item_id,
      customer_id: e._id,
      restaurant_id: e.restaurant_id,
      reassigned_by: state.user.first_name,
    });
    setReassignModalData(e);
    handleReassignOpen();
  };

  const handleRestaurantReassign = (e: any) => {
    setPreviousRestaurant(e.restaurant_name);
    setReassignRestaurantInfo({
      ...reassignRestaurantInfo,
      restaurant_id: e.restaurant_id,
      reassigned_by: state.user.first_name,
    });
    setReassignRestaurantModalData(e);
    handleReassignRestaurantOpen();
  };

  const handleCityReassign = (e: any) => {
    setPreviousCity(e.city_name);
    setReassignCityInfo({
      ...reassignCityInfo,
      city_id: e.city_id,
      reassigned_by: state.user.first_name,
    });
    setReassignCityModalData(e);
    findCity(e.city_id);
    handleReassignCityOpen();
  };

  const findCity = async (id: any) => {
    try {
      const response = await get("cities/" + id, {});

      if (response) {
        console.log("responseNewCityCurrent", response);
        setSelectedCountryData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("ainREassignCItyMOdalData", reassignCityModalData);

  const handleCloseReassignItem = () => {
    handleReassignClose();
  };

  const handleCancelReassignItem = () => {
    handleReassignRemarkClose();
    handleReassignClose();
  };

  const handleCloseReassignRestaurant = () => {
    handleReassignRestaurantRemarkClose();
    handleReassignRestaurantClose();
  };

  const handleCancelReassignRestaurant = () => {
    handleReassignRestaurantRemarkClose();
    handleReassignRestaurantClose();
  };

  const handleCloseReassignCity = () => {
    handleReassignCityRemarkClose();
    handleReassignCityClose();
  };

  const handleCancelReassignCity = () => {
    handleReassignCityRemarkClose();
    handleReassignCityClose();
  };

  const FavDishReassignConfirm = () => {
    handleReassignRemarkOpen();
  };

  const RestaurantReassignConfirm = () => {
    handleReassignRestaurantRemarkOpen();
  };

  const CityReassignConfirm = () => {
    handleReassignCityRemarkOpen();
  };

  const handleReassignConfirm = (e: any) => {
    e.preventDefault();
    try {
      const response = custom_patch(`reassignItem`, reassignInfo);
      if (response) {
        console.log(response);
        props.getData();
        alert("Item reassigned successfully", "success");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
    handleReassignClose();
  };

  const handleReassignRestaurantConfirm = (e: any) => {
    e.preventDefault();

    console.log("reassignRestaurantInfoNew", selectedRestaurantData);

    let newData = {
      reassign_restaurant_data: selectedRestaurantData,
      item_id: reassignRestaurantModalData.item_id,
      reassigned_by: reassignRestaurantInfo.reassigned_by,
      remark: reassignRestaurantInfo.remark,
      customer_id: reassignRestaurantModalData._id,
      city_id: reassignRestaurantModalData.city_id,
      restaurant_id: reassignRestaurantInfo.restaurant_id,
    };

    console.log("reassignRestaurantInfoNewData", newData);

    try {
      const response = custom_patch(`reassignRestaurant`, newData);
      if (response) {
        console.log(response);
        props.getData();
        alert("Restaurant reassigned successfully", "success");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
    handleReassignRestaurantClose();
  };

  const handleReassignCityConfirm = (e: any) => {
    e.preventDefault();

    let newData = {
      item_id: reassignCityModalData.item_id,
      city_id: reassignCityInfo.city_id,
      restaurant_id: reassignCityModalData.restaurant_id,
      reassign_city_id: selectedCityData[0].city_id,
      customer_id: reassignCityModalData._id,
      reassigned_by: reassignCityInfo.reassigned_by,
    };

    try {
      const response = custom_patch(`reassignCity`, newData);
      if (response) {
        console.log(response);
        props.getData();
        alert("City reassigned successfully", "success");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
    handleReassignCityClose();
  };

  const handleReassignReason = (e: any) => {
    setReassignInfo({
      ...reassignInfo,
      remark: e.target.value,
    });
  };

  const handleReassignRestaurantReason = (e: any) => {
    setReassignRestaurantInfo({
      ...reassignRestaurantInfo,
      remark: e.target.value,
    });
  };

  const handleReassignCityReason = (e: any) => {
    setReassignCityInfo({
      ...reassignCityInfo,
      remark: e.target.value,
    });
  };

  const handleCheckbox = (e: any) => {
    console.log("new", e[props.primary_id]);
    console.log(props);
    const checkedItems = [...props.checked];
    let index = checkedItems.indexOf(e[props.primary_id]);
    if (index !== -1) {
      checkedItems.splice(index, 1);
    } else {
      checkedItems.push(e[props.primary_id]);
    }
    props.setChecked(checkedItems);
    // if (props.module === "restaurants") {
    const checkedCities = [...props.checkedCities];
    let cityIndex = checkedCities.indexOf(e[props.city_id]);
    if (cityIndex !== -1) {
      checkedCities.splice(cityIndex, 2);
    } else {
      checkedCities.push(e.city_id);
    }
    props.setCheckedCities(checkedCities);

    console.log("checkPropsNewCIties", checkedCities);

    const checkedRestaurants = [...props.checkedRestaurants];
    let restaurantIndex = checkedRestaurants.indexOf(e[props.restaurant_name]);
    if (restaurantIndex !== -1) {
      checkedRestaurants.splice(restaurantIndex, 1);
    } else {
      checkedRestaurants.push(e.restaurant_name);
    }
    props.setCheckedRestaurants(checkedRestaurants);

    console.log("props.checked", e);
  };

  const handleCheckboxAll = () => {
    props.setIsHeaderChecked(!props.isHeaderChecked);
    if (props.isHeaderChecked) {
      props.checked.splice(0, props.checked.length);
    } else {
      data.map((e: any) => {
        props.checked.push(e[props.primary_id]);
      });
    }
  };

  const setDetailViewModal = (rowData: any) => {
    console.log(props.module);
    if (props.module === "customers") {
      props.setDetailViewModal({ status: true, id: rowData["_id"] });
      console.log(rowData["_id"]);
    } else if (props.module === "restaurants") {
      props.setDetailViewModal({ status: true, id: rowData["_id"] });
    } else {
      console.log({ status: true, id: rowData[props.primary_id] });
      props.setDetailViewModal({ status: true, id: rowData[props.primary_id] });
    }
  };

  const getDirection = (key: string, sortBy: any) => {
    if (sortBy[key] == -1) {
      return "desc";
    } else {
      return "asc";
    }
  };

  const getSortKey = (sortBy: any) => {
    let sortByAr = Object.keys(sortBy);
    return sortByAr[0];
  };

  const getItemData = async (filter: any = {}) => {
    let filterData = [{ type: "String", key: "item_name", value: filter.item }];
    let params = {
      page: page,
      limit: 50,
      filter: filterData,
    };
    try {
      const response = await get("items", params);
      if (response) {
        console.log("response", response);
        setItemData(response.data.data);

        let item_data: any = response.data.data.find(
          (item: any) => item.item_name === filter.item
        );

        setSelectedItem({
          item_id: item_data.item_id,
          item_name: item_data.item_name,
        });

        setReassignInfo({
          ...reassignInfo,
          reassigned_item_id: item_data.item_id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCityData = async (filter: any = {}) => {
    console.log("filtercity", filter);
    let filterData = [{ type: "String", key: "city_name", value: filter.name }];
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
    };
    try {
      const response = await admin_ts_get(
        `searchCity/${selectedCountryData.country_id}/${filter.name}`,
        {}
      );
      if (response) {
        console.log("responseNewarray", response.data);
        setCityData(response.data);

        let city_data: any = response.data.find(
          (e: any) => e.city_name === filter.name
        );

        console.log("city_data", city_data);

        // setSelectedCity({
        //   city_id: city_data.city_id,
        //   city_name: city_data.city_name,
        // });

        // setReassignCityInfo({
        //   ...reassignCityInfo,
        //   reassigned_city_id: city_data.city_id,
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [currentCity, setCurrentCity] = useState<any>("");
  const [allCityData, setAllCityData] = useState<any>([]);

  const getRestaurantData = async (filter: any = {}) => {
    let filterData = [
      { type: "String", key: "restaurant_name", value: filter.name },
    ];

    console.log("filterDataNewandOld", filter);
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
    };

    let cityId = Number(reassignRestaurantModalData.city_id);

    try {
      const response = await admin_ts_get(
        `searchRestaurant/${filter.name}/${cityId}`,
        {}
      );
      if (response) {
        console.log("response", response);
        if (response.data.results.length > 0) {
          setRestaurantData(response.data.results);
        }

        let restaurant_data: any = response.data.results.find(
          (restaurant: any) =>
            restaurant.name === selectedRestaurant.restaurant_name
        );

        // setRestaurantData(restaurant_data);
        console.log("restaurant_data", restaurant_data);
        setReassignRestaurantInfo({
          ...reassignRestaurantInfo,
          reassigned_restaurant_id: restaurant_data.restaurant_id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemChange = (item: any) => {
    setSelectedItem({
      item_name: item,
    });
    // setReassignInfo({
    //   ...reassignInfo,
    //   reassigned_item_id: selectedItem.item_id,
    // });

    getItemData({ item: item });
  };

  const [selectedRestaurantName, setSelectedRestaurantName] = useState("");
  const [placeId, setPlaceId] = useState("");

  const handleRestaurantChange = (event: any, newValue: any) => {
    // event.preventDefault();
    console.log("newValues", event, newValue);
    setSelectedRestaurant(newValue);

    if (placeId) {
      let restaurant_data: any = restaurantData.filter(
        (e: any) => e.place_id === placeId
      );

      console.log("restaurantNameNew", restaurant_data);
      setSelectedRestaurantData(restaurant_data);
    }

    getRestaurantData({ name: newValue });
  };

  const handleNewChange = (event: any, newValue: any) => {
    console.log("newOnchangeEvent", event, newValue);
    if (newValue) {
      setSelectedRestaurantName(newValue.name);
      setPlaceId(newValue.placeId);
    }
  };

  const handleCityChange = (city: any) => {
    console.log("cityName", city);
    setCurrentCity(city);
    setSelectedCity(city);

    console.log("allCityData", cityData);

    let city_data: any = cityData.filter((e: any) => e.city === city);

    console.log("city_data", city_data);
    setSelectedCityData(city_data);

    getCityData({ name: city });
  };

  const handleReassignReasonChange = (remark: any) => {
    setSelectedReassignRemark({
      remark: remark,
    });

    getReassignRemarkData({ remark: remark });
  };

  const handleReassignRestaurantReasonChange = (remark: any) => {
    setSelectedReassignRestaurantRemark({
      remark: remark,
    });

    getReassignRemarkData({ remark: remark });
  };

  const handleReassignCityReasonChange = (remark: any) => {
    setSelectedReassignCityRemark({
      remark: remark,
    });

    getReassignRemarkData({ remark: remark });
  };

  const onChangeHandler = async (event: any, e: any) => {
    setImagefile(event.target.files[0]);
    uploadImage(event, e);
    console.log("eventuploaditem", event, e);
  };

  const favouriteUploadHandler = async (event: any, e: any) => {
    setFavImagefile(event.target.files[0]);
    favouriteUploadImage(event, e);
  };

  const handleFavDishUploadImage = (event: any, e: any) => {
    console.log("eupload", event, e);
    favouriteUploadImage(event, e);
  };

  const handleItemImage = (event: any, e: any) => {
    console.log("euploaditem", event, e);
    uploadImage(event, e);
  };

  console.log("newpropsdata", restaurantModalData);

  const favouriteUploadImage = async (event: any, e: any) => {
    console.log("eventfav", event);
    let restaurant_id: any;
    let item_id: any;
    let collection_name: any;
    // if (props.module === "cuisines") {
    //   folder_name = "Cuisines";
    //   collection_name = "cuisines";
    //   file_id = e.cuisine_id;
    // } else if (props.module === "items") {
    //   folder_name = "items";
    //   collection_name = "items";
    //   file_id = e.item_id;
    // } else if (props.module === "food_linked_applications") {
    //   folder_name = "food_linked_applications";
    //   collection_name = "food_linked_applications";
    //   file_id = e.food_linked_application_id;
    // } else if (props.module === "countries") {
    //   folder_name = "Countries";
    //   collection_name = "countries";
    //   file_id = e.country_id;
    // } else {
    // }
    restaurant_id = e.restaurant_id;
    item_id = e.item_id;
    console.log("restaurant_id", restaurant_id, item_id);
    console.log("converted------------file", event.target.files[0]);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload_ts(
      "uploadRestaurantItemImage/" + restaurant_id + "/" + item_id,
      data1
    )
      .then((res: any) => {
        console.log("resnewupload", res);
        props.getData();
        alert("Profile pic uploaded successfully", "success");
      })
      .catch((err: any) => {
        console.error(err);
        alert("Something went wrong", "warning");
      });
  };

  const uploadImage = async (event: any, e: any) => {
    let file_id: any;
    let folder_name: any;
    let collection_name: any;
    if (props.module === "products") {
      folder_name = "products";
      collection_name = "products";
      file_id = e.product_id;
    }else if(props.module === "enquiries") {
      folder_name = "enquiries";
      collection_name = "enquiries";
      file_id = e.enquiry_id;
    } else if (props.module === "brands") {
      folder_name = "brands";
      collection_name = "brands";
      file_id = e.brand_id;
    } else if (props.module === "product_categories") {
      folder_name = "product_categories";
      collection_name = "product_categories";
      file_id = e.product_category_id;
    } else {
    }
    console.log("converted------------file", event.target.files[0]);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        file_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          image_url = res.data.image_url;
          image_id = res.data.image_id;
          setImageUrl(image_url);
          setImageId(image_id);

          if (image_url && image_id) {
            e["image_url"] = image_url;
            e["image_id"] = image_id;
            console.log(image_url, image_id);
            UpdateData(e);
          }
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const UpdateData = async (e: any) => {
    // e.preventDefault();
    let collection_name: any;
    let file_id: any;
    if (props.module === "products") {
      collection_name = "products";
      file_id = e.product_id;
    }else if(props.module === "enquiries") {
      collection_name = "enquiries";
      file_id = e.enquiry_id;
    } else if (props.module === "brands") {
      collection_name = "brands";
      file_id = e.brand_id;
    } else if (props.module === "product_categories") {
      collection_name = "product_categories";
      file_id = e.product_category_id;
    } else {
    }

    console.log("newDownloadedItems", collection_name, file_id);

    let data;

    try {
      data = {
        image_url: e.image_url,
        image_id: e.image_id,
      };

      patch(`${collection_name}/${file_id}`, data).then(function (data) {
        console.log(data);
        // if (data) {
        //   navigate(`/cuisines/details/${id}`);
        // }
        props.getData();
        alert("Item Image uploaded successfully", "success");
      });
    } catch (error) {
      console.log(error);
      alert("Something went wrong", "warning");
    }
  };

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (container && e.deltaMode === 0) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const shouldPreventScroll =
        (container.scrollLeft === 0 && e.deltaY < 0) ||
        (container.scrollLeft === maxScrollLeft && e.deltaY > 0);
      if (shouldPreventScroll) {
        e.preventDefault();
      }
    }
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return props.module === "maps" ? (
    <MapSection />
  ) : props.module === "getFavouritedItems" && !props.isListView ? (
    <>
      <div
        className="d-flex flex-wrap"
        style={{ gap: "24px", padding: "12px 0 40px 12px" }}
      >
        <SnackbarAlert
          open={snackbar}
          closeSnackbar={closeSnackbar}
          message={alert_message}
          severity={alert_severity}
        />
        <FavItemCard
          key={data._id}
          data={data}
          checked={props.checked}
          handleCheckbox={handleCheckbox}
          primary_id={props.primary_id}
          label={label}
          openCardItem={openItem}
          setOpenCardItem={setOpenItem}
          handleItemModal={handleItemModal}
          handleRestaurantModal={handleRestaurantModal}
          handleFavItemModal={handleFavItemModal}
          handleFavDishReassign={handleFavDishReassign}
          handleFavDishDelete={handleFavDishDelete}
          handleFavDishUploadImage={handleFavDishUploadImage}
          handleItemImage={handleItemImage}
          handleReassignRestaurant={handleRestaurantReassign}
          handleReassignCity={handleCityReassign}
          restaurantData={restaurantData}
        />
      </div>
      {openItem && (
        <div>
          <StatusUpdater
            type="items"
            openItem={openItem}
            itemModalData={itemModalData}
            getItemData={props.getData}
            handleItemClose={handleItemClose}
            id={itemId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      {openRestaurant && (
        <div>
          <StatusUpdater
            type="restaurants"
            openRestaurant={openRestaurant}
            restaurantModalData={restaurantModalData}
            handleRestaurantClose={handleRestaurantClose}
            id={restaurantId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      {openFavItems && (
        <div>
          <StatusUpdater
            type="getFavouritedItems"
            openFavItems={openFavItems}
            favItemModalData={favItemModalData}
            handleFavItemsClose={handleFavItemsClose}
            id={favItemId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      <div>
        <EditModal
          type="removeItem"
          open={openDelete}
          handleClose={handleDeleteClose}
          FavDishDeleteConfirm={FavDishDeleteConfirm}
          handleCloseFavDish={handleCloseFavDish}
          style={style}
          deleteConfirmed={deleteConfirmed}
          handleCancelFavDish={handleCancelFavDish}
          openDeleteRemark={openDeleteRemark}
          handleDeleteReason={handleDeleteReason}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleDeleteRemarkClose={handleDeleteRemarkClose}
          data={editModalData}
          dataRefresh={dataRefresh}
        />
      </div>
      <div>
        <EditModal
          type="reassignItem"
          openReassign={openReassign}
          handleReassignClose={handleReassignClose}
          FavDishReassignConfirm={FavDishReassignConfirm}
          handleCloseReassignItem={handleCloseReassignItem}
          style={style}
          reassignConfirmed={reassignConfirmed}
          handleCancelReassignItem={handleCancelReassignItem}
          openReassignRemark={openReassignRemark}
          handleReassignReason={handleReassignReason}
          handleReassignConfirm={handleReassignConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignRemarkClose}
          itemData={itemData}
          handleItemChange={handleItemChange}
          selectedItem={selectedItem}
          previousItem={previousItem}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignRemark}
          handleReassignReasonChange={handleReassignReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignModalData}
          dataRefresh={dataRefresh}
        />
      </div>

      <div>
        <EditModal
          type="reassignRestaurant"
          openReassignRestaurant={openReassignRestaurant}
          handleReassignRestaurantClose={handleReassignRestaurantClose}
          RestaurantReassignConfirm={RestaurantReassignConfirm}
          handleCloseReassignRestaurant={handleCloseReassignRestaurant}
          selectedRestaurantName={selectedRestaurantName}
          style={style}
          reassignConfirmed={reassignRestaurantConfirmed}
          handleCancelReassignItem={handleCancelReassignRestaurant}
          openReassignRemark={openReassignRestaurantRemark}
          handleReassignReason={handleReassignRestaurantReason}
          handleReassignConfirm={handleReassignRestaurantConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignRestaurantRemarkClose}
          restaurantData={restaurantData}
          handleNewChange={handleNewChange}
          handleItemChange={handleRestaurantChange}
          selectedRestaurant={selectedRestaurant}
          previousRestaurant={previousRestaurant}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignRestaurantRemark}
          handleReassignReasonChange={handleReassignRestaurantReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignRestaurantModalData}
          allCityData={allCityData}
          // selectedCity={selectedCity}
          // setSelectedCity={setSelectedCity}
          setSelectedRestaurant={setSelectedRestaurant}
          // currentCity={currentCity}
          dataRefresh={dataRefresh}
        />
      </div>

      <div>
        <EditModal
          type="reassignCity"
          openReassignCity={openReassignCity}
          handleReassignCityClose={handleReassignCityClose}
          CityReassignConfirm={CityReassignConfirm}
          handleCloseReassignCity={handleCloseReassignCity}
          style={style}
          reassignConfirmed={reassignCityConfirmed}
          handleCancelReassignItem={handleCancelReassignCity}
          openReassignRemark={openReassignCityRemark}
          handleReassignReason={handleReassignCityReason}
          handleReassignConfirm={handleReassignCityConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignCityRemarkClose}
          cityData={cityData}
          handleItemChange={handleCityChange}
          selectedCity={selectedCity}
          previousCity={previousCity}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignCityRemark}
          handleReassignReasonChange={handleReassignCityReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignCityModalData}
          dataRefresh={dataRefresh}
        />
      </div>
    </>
  ) : props.module === "getUniqueFavouritedRestaurantItems" &&
    !props.isListView ? (
    <>
      <div
        className="d-flex flex-wrap"
        style={{ gap: "24px", padding: "12px 0 40px 12px" }}
      >
        <SnackbarAlert
          open={snackbar}
          closeSnackbar={closeSnackbar}
          message={alert_message}
          severity={alert_severity}
        />
        <FavItemCard
          key={data._id}
          data={data}
          checked={props.checked}
          handleCheckbox={handleCheckbox}
          primary_id={props.primary_id}
          label={label}
          openCardItem={openItem}
          setOpenCardItem={setOpenItem}
          handleItemModal={handleItemModal}
          handleRestaurantModal={handleRestaurantModal}
          handleFavItemModal={handleFavItemModal}
          handleFavDishReassign={handleFavDishReassign}
          handleFavDishDelete={handleFavDishDelete}
          handleFavDishUploadImage={handleFavDishUploadImage}
          handleItemImage={handleItemImage}
          handleReassignRestaurant={handleRestaurantReassign}
          handleReassignCity={handleCityReassign}
          restaurantData={restaurantData}
        />
      </div>
      {openItem && (
        <div>
          <StatusUpdater
            type="items"
            openItem={openItem}
            itemModalData={itemModalData}
            getItemData={props.getData}
            handleItemClose={handleItemClose}
            id={itemId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      {openRestaurant && (
        <div>
          <StatusUpdater
            type="restaurants"
            openRestaurant={openRestaurant}
            restaurantModalData={restaurantModalData}
            handleRestaurantClose={handleRestaurantClose}
            id={restaurantId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      {openFavItems && (
        <div>
          <StatusUpdater
            type="getFavouritedItems"
            openFavItems={openFavItems}
            favItemModalData={favItemModalData}
            handleFavItemsClose={handleFavItemsClose}
            id={favItemId}
            dataRefresh={dataRefresh}
          />
        </div>
      )}

      <div>
        <EditModal
          type="removeItem"
          open={openDelete}
          handleClose={handleDeleteClose}
          FavDishDeleteConfirm={FavDishDeleteConfirm}
          handleCloseFavDish={handleCloseFavDish}
          style={style}
          deleteConfirmed={deleteConfirmed}
          handleCancelFavDish={handleCancelFavDish}
          openDeleteRemark={openDeleteRemark}
          handleDeleteReason={handleDeleteReason}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleDeleteRemarkClose={handleDeleteRemarkClose}
          data={editModalData}
          dataRefresh={dataRefresh}
        />
      </div>
      <div>
        <EditModal
          type="reassignItem"
          openReassign={openReassign}
          handleReassignClose={handleReassignClose}
          FavDishReassignConfirm={FavDishReassignConfirm}
          handleCloseReassignItem={handleCloseReassignItem}
          style={style}
          reassignConfirmed={reassignConfirmed}
          handleCancelReassignItem={handleCancelReassignItem}
          openReassignRemark={openReassignRemark}
          handleReassignReason={handleReassignReason}
          handleReassignConfirm={handleReassignConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignRemarkClose}
          itemData={itemData}
          handleItemChange={handleItemChange}
          selectedItem={selectedItem}
          previousItem={previousItem}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignRemark}
          handleReassignReasonChange={handleReassignReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignModalData}
          dataRefresh={dataRefresh}
        />
      </div>

      <div>
        <EditModal
          type="reassignRestaurant"
          openReassignRestaurant={openReassignRestaurant}
          handleReassignRestaurantClose={handleReassignRestaurantClose}
          RestaurantReassignConfirm={RestaurantReassignConfirm}
          handleCloseReassignRestaurant={handleCloseReassignRestaurant}
          selectedRestaurantName={selectedRestaurantName}
          style={style}
          reassignConfirmed={reassignRestaurantConfirmed}
          handleCancelReassignItem={handleCancelReassignRestaurant}
          openReassignRemark={openReassignRestaurantRemark}
          handleReassignReason={handleReassignRestaurantReason}
          handleReassignConfirm={handleReassignRestaurantConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignRestaurantRemarkClose}
          restaurantData={restaurantData}
          handleNewChange={handleNewChange}
          handleItemChange={handleRestaurantChange}
          selectedRestaurant={selectedRestaurant}
          previousRestaurant={previousRestaurant}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignRestaurantRemark}
          handleReassignReasonChange={handleReassignRestaurantReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignRestaurantModalData}
          allCityData={allCityData}
          // selectedCity={selectedCity}
          // setSelectedCity={setSelectedCity}
          setSelectedRestaurant={setSelectedRestaurant}
          // currentCity={currentCity}
          dataRefresh={dataRefresh}
        />
      </div>

      <div>
        <EditModal
          type="reassignCity"
          openReassignCity={openReassignCity}
          handleReassignCityClose={handleReassignCityClose}
          CityReassignConfirm={CityReassignConfirm}
          handleCloseReassignCity={handleCloseReassignCity}
          style={style}
          reassignConfirmed={reassignCityConfirmed}
          handleCancelReassignItem={handleCancelReassignCity}
          openReassignRemark={openReassignCityRemark}
          handleReassignReason={handleReassignCityReason}
          handleReassignConfirm={handleReassignCityConfirm}
          handleDeleteRemarkOpen={handleDeleteRemarkOpen}
          handleReassignRemarkClose={handleReassignCityRemarkClose}
          cityData={cityData}
          handleItemChange={handleCityChange}
          selectedCity={selectedCity}
          previousCity={previousCity}
          reassignRemarkData={reassignRemarkData}
          selectedReassignRemark={selectedReassignCityRemark}
          handleReassignReasonChange={handleReassignCityReasonChange}
          newReassignRemark={newReassignRemark}
          handleNewReassignRemark={handleNewReassignRemark}
          AddNewReassignRemark={AddNewReassignRemark}
          data={reassignCityModalData}
          dataRefresh={dataRefresh}
        />
      </div>
    </>
  ) : props.module === "admin_activity_logs" ? (
    <AdminActivityLog data={props.data} />
  ) : props.module === "customers" && !props.isListView ? (
    <div
      className="d-flex flex-wrap"
      style={{ gap: "24px", padding: "12px 0 40px 12px" }}
    >
      <CustomerCardView
        key={data._id}
        data={data}
        checked={props.checked}
        handleCheckbox={handleCheckbox}
        primary_id={props.primary_id}
        label={label}
        openCardItem={openItem}
        setOpenCardItem={setOpenItem}
        handleDetails={handleDetails}
        handleEdit={handleEdit}
      />
    </div>
  ) : (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div
        className="row invoices-table-container"
        ref={containerRef}
        onWheel={handleWheel}
        style={{ overflowX: "auto" }}
      >
        <div className="col-xs-12">
          <table className="table table-striped invoices-table">
            <thead className="thead-light thead-main">
              <tr>
                <>
                  {props.module !== "fields_settings" && (
                    <Checkbox
                      checked={props.isHeaderChecked}
                      className="d-flex"
                      {...label}
                      onChange={handleCheckboxAll}
                    />
                  )}
                  {dataKeys.map((e: any) => {
                    return (
                      <th scope="col" className="table-headings">
                        <div>
                          <TableSortLabel
                            // active={orderBy === headCell.id}
                            active={e == getSortKey(props.sortBy)}
                            direction={getDirection(e, props.sortBy)}
                            // direction={orderBy === headCell.id ? order : "asc"}
                            onClick={(event) => props.handleSortBy(e)}
                          >
                            {/* {props.sortBy === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )} */}
                            <span>
                              {e.includes("created_by_name")
                                ? e.replace("created_by_name", "Created By")
                                : e.includes("client_address")
                                ? e.replace("client_address", "Address")
                                : e.includes("del")
                                ? e.replace("del", "Del")
                                : e.includes("purchase_advance_amount")
                                ? e.replace(
                                    "purchase_advance_amount",
                                    "Purchase Advance"
                                  )
                                : e.includes("expense_group_name")
                                ? e.replace(
                                    "expense_group_name",
                                    "Expense Group"
                                  )
                                : e.includes("expense_group_id")
                                ? e.replace("expense_group_id", "Bill Id")
                                : e.includes("TO_From")
                                ? e.replace("TO_From", "To/From")
                                : e.includes("invoice_field_settings")
                                ? e
                                    .replace
                                    // "invoice_field_settings",
                                    // <InvoiceFieldSettings />
                                    ()
                                : e.replaceAll("_", " ").toLowerCase()}
                            </span>
                          </TableSortLabel>
                        </div>
                      </th>
                    );
                  })}
                  <th>
                    <ColumnFieldSelect
                      className="fields-dropdown"
                      onClick={props.onClick}
                      buttonClass={props.buttonClass}
                      //   imagePath={props.imagePath}
                      color={props.color}
                      handleFieldStatus={props.handleFieldStatus}
                      fields={props.fields}
                      // customElement={props.customElement}
                    />
                  </th>
                </>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((e: any, index: any) => {
                  return (
                    <tr key={e[props.primary_id]}>
                      {props.module !== "fields_settings" && (
                        <td className="text-center">
                          {" "}
                          <Checkbox
                            className="form-check-input"
                            checked={props.checked.includes(
                              e[props.primary_id]
                            )}
                            onChange={() => handleCheckbox(e)}
                            {...label}
                            sx={{
                              paddingLeft: "13px",
                            }}
                          />
                        </td>
                      )}
                      {dataKeys.map((e2: any) => {
                        let value = e[e2];
                        if (
                          Array.isArray(value) &&
                          value.length > 0 &&
                          e2 !== "linked_apps" &&
                          e2 !== "reference_info" &&
                          e2 !== "replies" &&
                          e2 !== "types" &&
                          e2 !== "restaurant_cuisines"
                        ) {
                          const keys = Object.keys(value[0]);
                          value = (
                            <table className="table table-striped invoices-table">
                              <thead>
                                <tr>
                                  {keys.map((key, i) => (
                                    <th key={i}>{key}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {value.map((row, i) => (
                                  <tr key={i}>
                                    {keys.map((key, j) => (
                                      <td key={j}>{row[key]}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );
                        } else if (e2.includes("date")) {
                          value = moment(value).format("DD-MMM-YYYY hh:mm a");
                        } else if (e2.includes("last_seen")) {
                          value = moment(value).format("DD-MMM-YYYY hh:mm a");
                        } else if (e2.includes("fields")) {
                          return e.fields.map((e4: any) => {
                            return e4;
                          });
                        } else if (e2.includes("popular_city")) {
                          if (value == 1) {
                            return <td>Yes</td>;
                          } else if (value == 0) {
                            return <td>No</td>;
                          }
                        } else if (
                          typeof e[e2] === "boolean" &&
                          props.module !== "getFavouritedItems" &&
                          props.module !==
                            "getUniqueFavouritedRestaurantItems" &&
                          props.module !== "restaurants" &&
                          props.module !== "customers"
                        ) {
                          return (
                            <td>
                              <span style={{ color: e[e2] ? "green" : "red" }}>
                                {e[e2] ? "Yes" : "No"}
                              </span>
                            </td>
                          );
                        } else if (
                          (typeof e[e2] === "boolean" &&
                            props.module === "getFavouritedItems") ||
                          (typeof e[e2] === "boolean" &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                          //     ||
                          // (props.module === "restaurants" &&
                          //   props.filterType === "aggregated")
                        ) {
                          return (
                            <td>
                              <span style={{ color: e[e2] ? "green" : "red" }}>
                                {e[e2] ? "True" : "False"}
                              </span>
                            </td>
                          );
                        } else if (
                          typeof e[e2] === "boolean" &&
                          props.module === "customers"
                        ) {
                          return (
                            <td>
                              <span style={{ color: e[e2] ? "green" : "red" }}>
                                {e[e2] ? "True" : "False"}
                              </span>
                            </td>
                          );
                        } else if (
                          typeof e[e2] === "boolean" &&
                          props.module === "restaurants"
                        ) {
                          return (
                            <td>
                              <span style={{ color: e[e2] ? "green" : "red" }}>
                                {e[e2] ? "True" : "False"}
                              </span>
                            </td>
                          );
                        }
                        //  else if (e2.includes("country")) {
                        //   let countries: any = [];
                        //   value.forEach((countryObj: any) => {
                        //     countries.push(<p>{countryObj.country_name}</p>);
                        //     console.log(countries);
                        //   });
                        //   return countries.length > 0 ? (
                        //     <>{countries}</>
                        //   ) : (
                        //     <td></td>
                        //   );
                        // }
                        else if (
                          e2.includes("app_logo") ||
                          e2.includes("report_photo") ||
                          e2.includes("profile_pic") ||
                          (e2.includes("item_image") &&
                            !e2.includes("favourited_item_image_url"))
                        ) {
                          return (
                            <div>
                              <img
                                src={value}
                                // alt={imageObj.alt}
                                width="100%"
                                height="100px"
                                className="image"
                              />
                            </div>
                          );
                        } else if (e2.includes("favourited_item_image_url")) {
                          return (
                            <div className="w-100">
                              <img
                                src={value}
                                // alt={imageObj.alt}
                                // width="100%"
                                height="100px"
                                className="image"
                              />
                            </div>
                          );
                        } else if (e2.includes("geometry")) {
                          return <td></td>;
                        } else if (e2.includes("reply")) {
                          let reply = [];
                          reply.push(
                            <td className="content-title-ellipsis">{`${value.slice(
                              0,
                              30
                            )}...`}</td>
                          );
                          return reply.length > 0 ? <>{reply}</> : <td></td>;
                        } else if (
                          e2 == "status" &&
                          props.module !== "customer_issue_reports" &&
                          props.module !== "restaurants"
                        ) {
                          value =
                            e.status === "inactive" ||
                            e.status === "Inactive" ? (
                              <Chip
                                label="Inactive"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(216, 106, 106, 0.32)",
                                  color: "#840A0A",
                                }}
                              />
                            ) : e.status === "active" ||
                              e.status === "Active" ? (
                              <Chip
                                label="Active"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(135, 216, 106, 0.32)",
                                  color: "#0A8442",
                                }}
                              />
                            ) : e.status === "new" || e.status === "New" ? (
                              <Chip
                                label="New"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(255, 255, 0, 1)",
                                  color: "#112A46",
                                }}
                              />
                            ) : (
                              <></>
                            );
                        } else if (
                          props.module === "restaurants" &&
                          e2 === "status"
                        ) {
                          value =
                            e.status === "pending" ? (
                              <Chip
                                label="pending"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(216, 106, 106, 0.32)",
                                  color: "#840A0A",
                                }}
                              />
                            ) : e.status === "hold" ? (
                              <Chip
                                label="hold"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(135, 216, 106, 0.32)",
                                  color: "#0A8442",
                                }}
                              />
                            ) : (
                              <Chip
                                label="approved"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(255, 255, 0, 1)",
                                  color: "#112A46",
                                }}
                              />
                            );
                        } else if (
                          props.module === "customer_issue_reports" &&
                          e2 === "status"
                        ) {
                          value =
                            e.status === "pending" ? (
                              <Chip
                                label="pending"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(216, 106, 106, 0.32)",
                                  color: "#840A0A",
                                }}
                              />
                            ) : e.status === "solved" ? (
                              <Chip
                                label="solved"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(135, 216, 106, 0.32)",
                                  color: "#0A8442",
                                }}
                              />
                            ) : (
                              <Chip
                                label="hold"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(255, 255, 0, 1)",
                                  color: "#112A46",
                                }}
                              />
                            );
                        } else if (e2.includes("status_text")) {
                          value =
                            e.status_text === "Deactive" ? (
                              <Chip
                                label="Deactive"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(216, 106, 106, 0.32)",
                                  color: "#840A0A",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Active"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(135, 216, 106, 0.32)",
                                  color: "#0A8442",
                                }}
                              />
                            );
                        } else if (
                          (e2.includes("item_status") &&
                            !e2.includes("item_status_id") &&
                            !e2.includes("favourited_item_status") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("item_status") &&
                            !e2.includes("item_status_id") &&
                            !e2.includes("favourited_item_status") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value =
                            // e.item_status_id === 0 ||
                            // e.item_status_id === 1 ||
                            // e.item_status_id === 2 ||
                            e.item_status_id === 0 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() => handleItemModal(e)}
                              >
                                inactive
                              </Button>
                            ) : e.item_status_id === 1 ? (
                              // e.item_status_id === 3 ||
                              // e.item_status === "active" ?
                              <Button
                                // disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleItemModal(e)}
                              >
                                active
                              </Button>
                            ) : e.item_status_id === 3 ? (
                              // e.item_status_id === 4 ||
                              // e.item_status === "new" ?
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#2E294E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() => handleItemModal(e)}
                              >
                                new
                              </Button>
                            ) : (
                              <></>
                            );
                        } else if (
                          (e2.includes("full_name") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("full_name") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "blue" }}>
                              {e.full_name}
                            </td>
                          );
                        } else if (
                          (e2.includes("customer_level") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("customer_level") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "blue" }}>
                              {e.customer_level}
                            </td>
                          );
                        } else if (
                          (e2.includes("item_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("item_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "#F24C00" }}>
                              {e.item_id}
                            </td>
                          );
                        } else if (e2.includes("item_name")) {
                          value = (
                            <>
                              <td
                                style={{ paddingLeft: "0", color: "#F24C00" }}
                                // onClick={() => handleItemModal(e)}
                              >
                                {e.item_name}
                              </td>
                              {/* <EditModal
                              type="item"
                              open={openItem}
                              handleClose={handleItemClose}
                              style={style}
                              itemId={itemId}
                            /> */}
                            </>
                          );
                        } else if (
                          (e2.includes("favourited_item_status") &&
                            !e2.includes("favourited_item_status_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("favourited_item_status") &&
                            !e2.includes("favourited_item_status_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value =
                            e.status === "inactive" ||
                            e.status === "Inactive" ||
                            (e.restaurant_status_id === 0 &&
                              e.favourited_item_status_id === 0) ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleFavItemModal(e)}
                              >
                                pending
                              </Button>
                            ) : e.restaurant_status_id === 0 &&
                              e.favourited_item_status_id === 1 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleFavItemModal(e)}
                              >
                                approved
                              </Button>
                            ) : e.restaurant_status_id === 0 &&
                              e.favourited_item_status_id === 2 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#9A031E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleFavItemModal(e)}
                              >
                                hold
                              </Button>
                            ) : e.restaurant_status_id === 1 &&
                              e.favourited_item_status_id === 0 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() => handleFavItemModal(e)}
                              >
                                pending
                              </Button>
                            ) : e.restaurant_status_id === 1 &&
                              e.favourited_item_status_id === 1 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() => handleFavItemModal(e)}
                              >
                                approved
                              </Button>
                            ) : e.restaurant_status_id === 1 &&
                              e.favourited_item_status_id === 2 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#9A031E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() => handleFavItemModal(e)}
                              >
                                hold
                              </Button>
                            ) : (
                              <></>
                            );
                        } else if (
                          (e2.includes("favourited_item_status_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("favourited_item_status_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "#F24C00" }}>
                              {e.favourited_item_status_id}
                            </td>
                          );
                          // } else if (
                          //   e2.includes("item_status") &&
                          //   !e2.includes("item_status_id") &&
                          //   !e2.includes("favourited_item_status") &&
                          //   !e2.includes("favourited_item_status_id") &&
                          //   props.module === "getFavouritedItems"
                          // ) {
                          //   value = (
                          //     <td style={{ paddingLeft: "0", color: "#F24C00" }}>
                          //       {e.item_status}
                          //     </td>
                          //   );
                        } else if (
                          (e2.includes("item_status_id") &&
                            !e2.includes("favourited_item_status_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("item_status_id") &&
                            !e2.includes("favourited_item_status_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "#F24C00" }}>
                              {e.item_status_id}
                            </td>
                          );
                        } else if (
                          (e2.includes("restaurant_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("restaurant_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "#8EA604" }}>
                              {e.restaurant_id}
                            </td>
                          );
                        } else if (
                          (e2.includes("restaurant_name") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("restaurant_name") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <>
                              <td
                                style={{ paddingLeft: "0", color: "#8EA604" }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                {e.restaurant_name}
                              </td>
                              {/* <EditModal
                              type="restaurant"
                              open={openRestaurant}
                              handleClose={handleRestaurantClose}
                              style={style}
                              restaurantId={restaurantId}
                            /> */}
                            </>
                          );
                        } else if (
                          (e2.includes("restaurant_status") &&
                            !e2.includes("restaurant_status_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("restaurant_status") &&
                            !e2.includes("restaurant_status_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value =
                            e.item_status_id === 0 &&
                            e.restaurant_status_id === 0 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                pending
                              </Button>
                            ) : e.item_status_id === 0 &&
                              e.restaurant_status_id === 1 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                approved
                              </Button>
                            ) : e.item_status_id === 0 &&
                              e.restaurant_status_id === 2 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#9A031E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                hold
                              </Button>
                            ) : e.item_status_id === 3 &&
                              e.restaurant_status_id === 0 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                pending
                              </Button>
                            ) : e.item_status_id === 3 &&
                              e.restaurant_status_id === 1 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                approved
                              </Button>
                            ) : e.item_status_id === 3 &&
                              e.restaurant_status_id === 2 ? (
                              <Button
                                disabled
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#9A031E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                // onClick={() => handleRestaurantModal(e)}
                              >
                                hold
                              </Button>
                            ) : e.item_status_id === 1 &&
                              e.restaurant_status_id === 0 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#F22B29",
                                  " &:hover": {
                                    backgroundColor: "#D72638",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() =>
                                  handleRestaurantModal(e, "favourited_items")
                                }
                              >
                                pending
                              </Button>
                            ) : e.item_status_id === 1 &&
                              e.restaurant_status_id === 1 ? (
                              <Button
                                // disabled
                                variant="contained"
                                sx={{
                                  color: "#000000",
                                  bgcolor: "#47A025",
                                  " &:hover": {
                                    backgroundColor: "#2E933C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() =>
                                  handleRestaurantModal(e, "favourited_items")
                                }
                              >
                                approved
                              </Button>
                            ) : e.item_status_id === 1 &&
                              e.restaurant_status_id === 2 ? (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "#FFFFFF",
                                  bgcolor: "#9A031E",
                                  " &:hover": {
                                    backgroundColor: "#FF2C2C",
                                  },
                                  minWidth: 0,
                                  padding: "5px 10px",
                                  height: "28px",
                                  fontSize: "12px",
                                  textTransform: "lowercase",
                                }}
                                onClick={() =>
                                  handleRestaurantModal(e, "favourited_items")
                                }
                              >
                                hold
                              </Button>
                            ) : (
                              <></>
                            );
                        } else if (
                          (e2.includes("linked_apps") &&
                            props.module === "restaurants") ||
                          (e2.includes("linked_apps") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("linked_apps") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          return (
                            <td key={index}>
                              <div className="news-details-images d-flex p-2">
                                {e.linked_apps.map(
                                  (app: any, appIndex: number) => (
                                    <Link to={app.app_link_url}>
                                      <img
                                        key={appIndex}
                                        src={app.app_logo}
                                        alt={`${app.food_app_name} Logo`}
                                        width="24px"
                                        height="24px"
                                        className="me-3"
                                      />
                                    </Link>
                                  )
                                )}
                              </div>
                            </td>
                          );
                        } else if (
                          e2.includes("replies") &&
                          props.module === "circle_posts"
                        ) {
                          return (
                            <td key={index}>
                              <div className="news-details-images d-flex p-2">
                                {e.replies.map(
                                  (reply: any, replyIndex: number) => (
                                    <Card
                                      key={replyIndex}
                                      className="me-3"
                                      sx={{ padding: "10px" }}
                                    >
                                      <div>
                                        <h6>
                                          <strong>Post: </strong>
                                          {reply.post}
                                        </h6>
                                      </div>
                                      <div>
                                        <h6>
                                          <strong>Profile id: </strong>
                                          {reply.profile_id}
                                        </h6>
                                      </div>
                                      <div>
                                        <h6>
                                          <strong>Profile Pic: </strong>
                                        </h6>
                                        <img
                                          src={reply.profile_pic}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <h6>
                                          <strong>Upvote Count: </strong>
                                          {reply.upvote_count}
                                        </h6>
                                      </div>
                                      <div>
                                        <h6>
                                          <strong>User Name: </strong>
                                          {reply.user_name}
                                        </h6>
                                      </div>
                                    </Card>
                                  )
                                )}
                              </div>
                            </td>
                          );
                        } else if (
                          e2.includes("types") &&
                          props.module === "restaurants"
                        ) {
                          return (
                            <td key={index}>
                              <div className="news-details-images d-flex p-2">
                                {e.types.map((type: any, typeIndex: number) => (
                                  <Card
                                    key={typeIndex}
                                    className="me-3"
                                    sx={{ padding: "3px" }}
                                  >
                                    <h6
                                      style={{
                                        marginBottom: 0,
                                        fontSize: "12px",
                                      }}
                                    >
                                      {type}
                                    </h6>
                                  </Card>
                                ))}
                              </div>
                            </td>
                          );
                        } else if (
                          (e2.includes("restaurant_cuisines") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("restaurant_cuisines") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          return (
                            <td key={index}>
                              <div className="news-details-images d-flex p-2">
                                {e.restaurant_cuisines.map(
                                  (cuisine: any, cuisineIndex: number) => (
                                    <Card
                                      key={cuisineIndex}
                                      className="me-3"
                                      sx={{ padding: "6px" }}
                                    >
                                      <h6
                                        style={{
                                          marginBottom: 0,
                                          fontSize: "12px",
                                        }}
                                      >
                                        {cuisine.cuisine_name}
                                      </h6>
                                    </Card>
                                  )
                                )}
                              </div>
                            </td>
                          );
                        } else if (
                          e2.includes("reference_info") &&
                          props.module === "restaurants"
                        ) {
                          return (
                            <td key={index}>
                              <div className="news-details-images d-flex p-2">
                                {e.reference_info.map(
                                  (app: any, appIndex: number) => (
                                    <Link to={app.reference_link}>
                                      <img
                                        key={appIndex}
                                        src={app.reference_image_url}
                                        alt={`${app.reference} Image`}
                                        width="24px"
                                        height="24px"
                                      />
                                    </Link>
                                  )
                                )}
                              </div>
                            </td>
                          );
                        } else if (
                          (e2.includes("restaurant_status_id") &&
                            props.module === "getFavouritedItems") ||
                          (e2.includes("restaurant_status_id") &&
                            props.module ===
                              "getUniqueFavouritedRestaurantItems")
                        ) {
                          value = (
                            <td style={{ paddingLeft: "0", color: "#8EA604" }}>
                              {e.restaurant_status_id}
                            </td>
                          );
                        } else if (
                          (e2.includes("headers") &&
                            props.module === "admin_api_logs") ||
                          (e2.includes("headers") &&
                            props.module === "api-logs")
                        ) {
                          value = <div></div>;
                        } else if (
                          (e2.includes("user") &&
                            props.module === "admin_api_logs") ||
                          (e2.includes("user") && props.module === "api-logs")
                        ) {
                          value = <div></div>;
                        } else if (
                          (e2.includes("query") &&
                            props.module === "admin_api_logs") ||
                          (e2.includes("query") && props.module === "api-logs")
                        ) {
                          value = <div></div>;
                        } else if (e2.includes("actions")) {
                          value = (
                            <div className=" d-flex align-items-center">
                              {props.module !== "customer_issue_reports" && (
                                <button
                                  className="btn"
                                  // onClick={(e) => handleInvoiceEdit(e)}
                                >
                                  <Link
                                    to={props.detailLink + e[props.primary_id]}
                                    // onClick={(e) => handleInvoiceEdit(e)}
                                  >
                                    <img src={TableAddNewIcon} alt="" />
                                  </Link>
                                </button>
                              )}
                              {props.module === "customer_issue_reports" ? (
                                ""
                              ) : props.module === "getFavouritedItems" ||
                                props.module ===
                                  "getUniqueFavouritedRestaurantItems" ? (
                                <label className="btn">
                                  <input
                                    className="input-image"
                                    accept="image/*"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) =>
                                      favouriteUploadHandler(event, e)
                                    }
                                  />
                                  <UploadIcon />
                                </label>
                              ) : (
                                <label className="btn">
                                  <input
                                    className="input-image"
                                    accept="image/*"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                      onChangeHandler(event, e);
                                    }}
                                  />
                                  <UploadIcon />
                                </label>
                              )}
                              {
                                // props.module === "customers" ||
                                props.module === "admin_api_logs" ||
                                props.module === "api-logs" ? (
                                  " "
                                ) : props.module === "items" ? (
                                  <>
                                    <button className="btn">
                                      <Link
                                        to={
                                          props.editLink + e[props.primary_id]
                                        }
                                        // onClick={() => handleEdit(e)}
                                      >
                                        <img src={TableEditIcon} alt="" />
                                      </Link>
                                    </button>
                                    {e.status === "inactive" ||
                                    e.status === "Inactive" ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          color: "#000000",
                                          bgcolor: "#FF5F1F",
                                          " &:hover": {
                                            backgroundColor: "#FE5F00",
                                          },
                                          // minWidth: 0,
                                          // padding: "5px 10px",
                                          height: "28px",
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() => handleItemModal(e)}
                                      >
                                        Approve Item
                                      </Button>
                                    ) : e.status_id === 3 ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          color: "#000000",
                                          bgcolor: "#FF5F1F",
                                          " &:hover": {
                                            backgroundColor: "#FE5F00",
                                          },
                                          // minWidth: 0,
                                          // padding: "5px 10px",
                                          height: "28px",
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() => handleItemModal(e)}
                                      >
                                        Approve Item
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : props.module === "restaurants" &&
                                  props.filterType !== "aggregated" ? (
                                  <>
                                    <button className="btn">
                                      <Link
                                        to={
                                          props.editLink + e[props.primary_id]
                                        }
                                        // onClick={() => handleEdit(e)}
                                      >
                                        <img src={TableEditIcon} alt="" />
                                      </Link>
                                    </button>
                                    {e.status === "pending" ||
                                    e.approved_by_id === 0 ||
                                    e.approved_by_id === 1 ||
                                    e.approved_by_id === 2 ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          color: "#000000",
                                          bgcolor: "#FF5F1F",
                                          " &:hover": {
                                            backgroundColor: "#FE5F00",
                                          },
                                          // minWidth: 0,
                                          // padding: "5px 10px",
                                          height: "28px",
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() =>
                                          handleRestaurantModal(
                                            e,
                                            "restaurants"
                                          )
                                        }
                                      >
                                        Approve
                                      </Button>
                                    ) : e.status_id === 3 ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          color: "#000000",
                                          bgcolor: "#FF5F1F",
                                          " &:hover": {
                                            backgroundColor: "#FE5F00",
                                          },
                                          // minWidth: 0,
                                          // padding: "5px 10px",
                                          height: "28px",
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() => handleItemModal(e)}
                                      >
                                        Approve
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <button className="btn">
                                      <Link
                                        to={
                                          props.editLink + e[props.primary_id]
                                        }
                                        // onClick={() => handleEdit(e)}
                                      >
                                        <img src={TableEditIcon} alt="" />
                                      </Link>
                                    </button>
                                  </>
                                )
                              }

                              {props.module === "getFavouritedItems" && (
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#09414E",
                                      " &:hover": {
                                        backgroundColor: "#083A46",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => handleFavDishDelete(e)}
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#AF4518CC",
                                      " &:hover": {
                                        backgroundColor: "#DB561E",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                      marginRight: "10px",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() => handleFavDishReassign(e)}
                                  >
                                    Reassign Item
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#AF4518CC",
                                      " &:hover": {
                                        backgroundColor: "#DB561E",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                      marginRight: "10px",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() => handleRestaurantReassign(e)}
                                  >
                                    Reassign Restaurant
                                  </Button>

                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#AF4518CC",
                                      " &:hover": {
                                        backgroundColor: "#DB561E",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() => handleCityReassign(e)}
                                  >
                                    Reassign City
                                  </Button>
                                </div>
                              )}

                              {props.module ===
                                "getUniqueFavouritedRestaurantItems" && (
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#09414E",
                                      " &:hover": {
                                        backgroundColor: "#083A46",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => handleFavDishDelete(e)}
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#AF4518CC",
                                      " &:hover": {
                                        backgroundColor: "#DB561E",
                                      },
                                      minWidth: 0,
                                      padding: "5px 10px",
                                      height: "28px",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => handleFavDishReassign(e)}
                                  >
                                    Reassign
                                  </Button>
                                </div>
                              )}
                            </div>
                          );
                        } else if (e2.includes("invoice_field_settings")) {
                          value = <div></div>;
                        } else if (e2.includes("image_url")) {
                          return (
                            <td style={{ padding: "10px" }}>
                              <img height="30px" width="30px" src={value} />
                            </td>
                          );
                        }

                        return <td scope="col">{value}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div>
          <EditModal
            type="removeItem"
            open={openDelete}
            handleClose={handleDeleteClose}
            FavDishDeleteConfirm={FavDishDeleteConfirm}
            handleCloseFavDish={handleCloseFavDish}
            style={style}
            deleteConfirmed={deleteConfirmed}
            handleCancelFavDish={handleCancelFavDish}
            openDeleteRemark={openDeleteRemark}
            handleDeleteReason={handleDeleteReason}
            handleDeleteConfirm={handleDeleteConfirm}
            handleDeleteRemarkOpen={handleDeleteRemarkOpen}
            handleDeleteRemarkClose={handleDeleteRemarkClose}
            data={editModalData}
            dataRefresh={dataRefresh}
          />
        </div>
        <div>
          <EditModal
            type="reassignItem"
            openReassign={openReassign}
            handleReassignClose={handleReassignClose}
            FavDishReassignConfirm={FavDishReassignConfirm}
            handleCloseReassignItem={handleCloseReassignItem}
            style={style}
            reassignConfirmed={reassignConfirmed}
            handleCancelReassignItem={handleCancelReassignItem}
            openReassignRemark={openReassignRemark}
            handleReassignReason={handleReassignReason}
            handleReassignConfirm={handleReassignConfirm}
            handleDeleteRemarkOpen={handleDeleteRemarkOpen}
            handleReassignRemarkClose={handleReassignRemarkClose}
            itemData={itemData}
            handleItemChange={handleItemChange}
            selectedItem={selectedItem}
            previousItem={previousItem}
            reassignRemarkData={reassignRemarkData}
            selectedReassignRemark={selectedReassignRemark}
            handleReassignReasonChange={handleReassignReasonChange}
            newReassignRemark={newReassignRemark}
            handleNewReassignRemark={handleNewReassignRemark}
            AddNewReassignRemark={AddNewReassignRemark}
            data={reassignModalData}
            dataRefresh={dataRefresh}
          />
        </div>
        <div>
          <EditModal
            type="reassignRestaurant"
            openReassignRestaurant={openReassignRestaurant}
            handleReassignRestaurantClose={handleReassignRestaurantClose}
            RestaurantReassignConfirm={RestaurantReassignConfirm}
            handleCloseReassignRestaurant={handleCloseReassignRestaurant}
            style={style}
            reassignConfirmed={reassignRestaurantConfirmed}
            handleCancelReassignItem={handleCancelReassignRestaurant}
            openReassignRemark={openReassignRestaurantRemark}
            handleReassignReason={handleReassignRestaurantReason}
            handleReassignConfirm={handleReassignRestaurantConfirm}
            handleDeleteRemarkOpen={handleDeleteRemarkOpen}
            handleReassignRemarkClose={handleReassignRestaurantRemarkClose}
            restaurantData={restaurantData}
            handleNewChange={handleNewChange}
            handleItemChange={handleRestaurantChange}
            selectedRestaurant={selectedRestaurant}
            selectedRestaurantName={selectedRestaurantName}
            previousRestaurant={previousRestaurant}
            reassignRemarkData={reassignRemarkData}
            selectedReassignRemark={selectedReassignRestaurantRemark}
            handleReassignReasonChange={handleReassignRestaurantReasonChange}
            newReassignRemark={newReassignRemark}
            handleNewReassignRemark={handleNewReassignRemark}
            AddNewReassignRemark={AddNewReassignRemark}
            data={reassignRestaurantModalData}
            allCityData={allCityData}
            // selectedCity={selectedCity}
            // setSelectedCity={setSelectedCity}
            setSelectedRestaurant={setSelectedRestaurant}
            // currentCity={currentCity}
            dataRefresh={dataRefresh}
          />
        </div>

        <div>
          <EditModal
            type="reassignCity"
            openReassignCity={openReassignCity}
            handleReassignCityClose={handleReassignCityClose}
            CityReassignConfirm={CityReassignConfirm}
            handleCloseReassignCity={handleCloseReassignCity}
            style={style}
            reassignConfirmed={reassignCityConfirmed}
            handleCancelReassignItem={handleCancelReassignCity}
            openReassignRemark={openReassignCityRemark}
            handleReassignReason={handleReassignCityReason}
            handleReassignConfirm={handleReassignCityConfirm}
            handleDeleteRemarkOpen={handleDeleteRemarkOpen}
            handleReassignRemarkClose={handleReassignCityRemarkClose}
            cityData={cityData}
            handleItemChange={handleCityChange}
            selectedCity={selectedCity}
            previousCity={previousCity}
            reassignRemarkData={reassignRemarkData}
            selectedReassignRemark={selectedReassignCityRemark}
            handleReassignReasonChange={handleReassignCityReasonChange}
            newReassignRemark={newReassignRemark}
            handleNewReassignRemark={handleNewReassignRemark}
            AddNewReassignRemark={AddNewReassignRemark}
            data={reassignCityModalData}
            dataRefresh={dataRefresh}
          />
        </div>
        {openItem && (
          <div>
            <StatusUpdater
              type="items"
              openItem={openItem}
              itemModalData={itemModalData}
              getItemData={props.getData}
              handleItemClose={handleItemClose}
              id={itemId}
              dataRefresh={dataRefresh}
            />
          </div>
        )}
        {openRestaurant && (
          <div>
            <StatusUpdater
              type="restaurants"
              openRestaurant={openRestaurant}
              restaurantModalData={restaurantModalData}
              handleRestaurantClose={handleRestaurantClose}
              id={restaurantId}
              dataRefresh={dataRefresh}
            />
          </div>
        )}
        {openFavItems && (
          <div>
            <StatusUpdater
              type="getFavouritedItems"
              openFavItems={openFavItems}
              favItemModalData={favItemModalData}
              handleFavItemsClose={handleFavItemsClose}
              id={favItemId}
              dataRefresh={dataRefresh}
            />
          </div>
        )}

        {openUniqueFavItems && (
          <div>
            <StatusUpdater
              type="getUniqueFavouritedRestaurantItems"
              openUniqueFavItems={openUniqueFavItems}
              uniqueFavItemModalData={uniqueFavItemModalData}
              handleUniqueFavItemsClose={handleUniqueFavItemsClose}
              id={uniqueFavItemId}
              dataRefresh={dataRefresh}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Tabledata;
