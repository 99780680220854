import React, { useContext, useState } from "react";
import { ReactDOM } from "react";
import "./index.scss";
import { Outlet } from "react-router-dom";
import BreadCrumb from "../layout/components/BreadCrumb";
import Footer from "../layout/components/Footer/Footer";
import Navbar from "../layout/components/Navbar/Navbar";
import Sidebar from "../layout/components/Sidebar/Sidebar";
import Tabswitch from "../layout/components/Tabswitch/Tabswitch";
import Tabledata from "../layout/components/Table/Table";
import Tabheader from "../layout/components/Tabheader/Tabheader";
import Pagination from "../layout/components/Pagination/Pagination";
import Rightbar from "../layout/components/Rightbar/Rightbar";
import { AppContext } from "../utils/state";

export default function MasterLayout() {
  const [dropdownValue, setDropdownValue] = useState("Delnie Pvt. Ltd");
  const [sideNav, setSideNav] = useState(false);
  const handleClick = (status: boolean | ((prevState: boolean) => boolean)) => {
    setSideNav(status);
  };

  const { state, dispatch } = useContext(AppContext);

  const path = window.location.pathname;

  return (
    <div>
      <Navbar user={state.user} onChange={setDropdownValue} />
      <Sidebar handleClick={handleClick} />
      <div
        className={
          "container master-layout-container " +
          (sideNav === false ? " half-width" : " full-width")
        }
      >
        {path.split("/").length - 1 === 4 && <BreadCrumb />}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
