import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../../../utils/api";
import { startCase } from "lodash";
import { MenuItem, Select } from "@mui/material";

export default function NewModule(props: any) {
  // const initialData = {
  //   client_name: "",
  //   client_contact_no: "",
  //   client_pincode: "",
  //   client_address: "",
  //   pan_number: "",
  //   gst_number: "",
  //   bank_name: "",
  //   bank_account_no: "",
  //   ifsc_code: "",
  // };

  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [formData, setFormData] = useState<any>([]);
  const [fields, setFields] = useState<Array<any>>([]);
  // let [countries, setCountries] = useState<Array<any>>([]);

  useEffect(() => {
    console.log("props.module", props.module);

    // getCountries();
    getFields();
    // }
  }, []);

  // const getCountries = () => {
  //   get("countries", {}).then((response: any) => {
  //     console.log("countries", response.data.data);
  //     countries = response.data.data;
  //     setCountries(countries);
  //   });
  // };

  const getFields = () => {
    get("" + props.module, {}).then((response: any) => {
      setFields(response.data.fields);
      console.log("fields", response.data.fields);
      fields.map((field: any) => {});
    });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await post(props.module, formData);
      console.log(response);
      if (response) {
        navigate(props.modulePath + "/list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      {/* <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      /> */}

      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>{"New " + startCase(props.module)}</h5>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {fields
            .filter((e: any) => e.create_status == true)
            .map((field) => (
              <div
                key={field.key}
                className="row mt-3 mb-3"
                // style={{ display: field.coloumn_status ? "block" : "none" }}
              >
                <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
                  <label htmlFor={field.key}>
                    {startCase(field.key.replaceAll("_", " "))}
                  </label>
                </div>
                <div className="col-xs-12 col-md-8 col-lg-8">
                  <FormGroup>
                    {/* {field.key === "state" && (
                    <Select
                      className="reg-input name-field"
                      sx={{ width: "100% !important" }}
                      fullWidth

                      name={field.key}
                      value={formData[field.key] || ""}
                      placeholder={startCase(field.key.replaceAll("_", " "))}

                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>

                  )} */}
                    {field.key === "country" &&
                    field.collection_name === "states" ? (
                      <Select
                        className="reg-input name-field"
                        sx={{ width: "100% !important" }}
                        fullWidth
                        name={field.key}
                        value={formData[field.key] || ""}
                        placeholder={startCase(field.key.replaceAll("_", " "))}
                        onChange={handleChange}
                      >
                        {/* 
                        {field.countries ?
                          <>
                        field.countries.map((country: any) => {
                              // your code here, e.g.:
                              <MenuItem value={field.key}>Ten</MenuItem>
                            })
                          </>
                          


                          : null} */}
                      </Select>
                    ) : null}

                    {/* {field.key === "country" && field.collection_name ==="states" (
                  <Select
                  className="reg-input name-field"
                  sx={{ width: "100% !important" }}
                  fullWidth
                 
                  name={field.key}
                  value={formData[field.key] || ""}
                  placeholder={startCase(field.key.replaceAll("_"," "))}
               
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
                    
                  )} */}
                    {field.type === "String" && (
                      <TextField
                        sx={{ width: "100% !important" }}
                        fullWidth
                        // required
                        className="reg-input name-field"
                        name={field.key}
                        value={formData[field.key] || ""}
                        onChange={handleChange}
                        placeholder={startCase(field.key.replaceAll("_", " "))}
                      />
                    )}
                    {field.type === "Number" && (
                      <TextField
                        sx={{ width: "100% !important" }}
                        fullWidth
                        // required
                        type="number"
                        className="reg-input name-field"
                        name={field.key}
                        value={formData[field.key] || ""}
                        onChange={handleChange}
                        placeholder={startCase(field.key.replaceAll("_", " "))}
                      />
                    )}
                    {field.type === "Date" && (
                      <TextField
                        sx={{ width: "100% !important" }}
                        fullWidth
                        // required
                        type="date"
                        className="reg-input name-field"
                        name={field.key}
                        value={formData[field.key] || ""}
                        onChange={handleChange}
                        placeholder={startCase(field.key.replaceAll("_", " "))}
                      />
                    )}
                  </FormGroup>
                </div>
              </div>
            ))}
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
