import React from "react";
import "../Table.scss";

function AdminActivityLog(props: any) {
  return (
    <>
      {Object.entries(props.data).map(([key, value]: any, index: any) => (
        <table className="table table-striped invoices-table">
          <tbody>
            <tr key={index} className="activity-log-entry">
              {value.modified_by} has done an action of type {value.type} on{" "}
              {value.restaurant_name ? value.restaurant_name : value.item_name}.
            </tr>
          </tbody>
        </table>
      ))}
    </>
  );
}

export default AdminActivityLog;
