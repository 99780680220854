import ListModule from "../../../../layout/components/CRUD/List/List";

function List() {
  return (
    <ListModule
      module="product_types"
      modulePath="/product-types"
      primary_id="product_type_id"
    />
  );
}

export default List;
