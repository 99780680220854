import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import DummyImage from "../../../assets/images/Tabledata/dummy-image.png";
import LocationMarkerNewIcon from "../../../assets/images/Tabledata/location-marker-new.svg";
import moment from "moment";
import DummyIcon from "../../../assets/images/Tabledata/dummy-icon.svg";
import ApprovedIcon from "../../../assets/images/Tabledata/approved-icon.svg";
import RejectedIcon from "../../../assets/images/Tabledata/rejected-icon.svg";
import PendingIcon from "../../../assets/images/Tabledata/pending-icon.svg";
import HoldIcon from "../../../assets/images/Tabledata/hold-icon.svg";
import UploadIcon from "@mui/icons-material/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const ITEM_HEIGHT = 48;

function CustomerCardView(props: any) {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>(
    Array.from({ length: Object.keys(props.data).length }, () => null)
  );
  // const open = Boolean(anchorEl);
  const isOpen = (index: number) => Boolean(anchorEls[index]);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    // Set the appropriate anchorEl in the array
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClose = (index: number) => {
    // Set the appropriate anchorEl in the array to null
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  // let [options, setOptions] = useState([
  //   {
  //     option_id: 0,
  //     option_name: "Reassign Item",
  //   },
  //   {
  //     option_id: 1,
  //     option_name: "Delete",
  //   },
  //   {
  //     option_id: 2,
  //     option_name: "Upload Item Image",
  //   },
  //   {
  //     option_id: 3,
  //     option_name: "Upload Fav Item Image",
  //   },
  // ]);

  let [options, setOptions] = useState([
    {
      option_id: 0,
      option_name: "Details",
    },
    {
      option_id: 1,
      option_name: "Edit",
    },
    {
      option_id: 2,
      option_name: "Upload",
    },
  ]);

  console.log("favItemCard", props.data);

  const handleItemStatus = (e: any) => {
    // props.setOpenCardItem(!props.openCardItem);
    props.handleItemModal(e);
    console.log("handleStatusUpdate", e);
  };

  const handleRestaurantStatus = (e: any) => {
    // props.setOpenCardRestaurant(!props.openCardRestaurant);
    props.handleRestaurantModal(e);
    console.log("handleRestaurantStatus", e);
  };

  const handleFavItemStatus = (e: any) => {
    // props.setOpenCardFavItem(!props.openCardFavItem);
    props.handleFavItemModal(e);
    console.log("handleFavItemStatus", e);
  };

  // const handleMoreVert = (e: any, newValue: any, index: any) => {
  //   handleClose(index);
  //   console.log("handleMoreVert", e, newValue);
  //   if (e.option_id === 0) {
  //     props.handleFavDishReassign(newValue);
  //   } else if (e.option_id === 1) {
  //     props.handleFavDishDelete(newValue);
  //   }
  // };

  const handleMoreVert = (e: any, newValue: any, index: any) => {
    console.log("current_cities", e, newValue, index);
    handleClose(index);
    if (e.option_id === 0) {
      props.handleDetails(newValue);
    } else if (e.option_id === 1) {
      props.handleEdit(newValue);
    }
  };

  return (
    <>
      {Object.entries(props.data).map(
        ([key, value]: any, index: any) => (
          console.log("valuecusItemCard", value),
          (
            <Card
              sx={{
                width: "291px",
                height: "500px",
                padding: "12px 11px 8px 10px",
                border:
                  value.status_id === 1
                    ? "1px solid #3BC525"
                    : "1px solid #C52525",
                borderRadius: "12px",
              }}
              key={index}
              className="favourited-items-card"
            >
              <div className="row">
                <div className="col-xs-12">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <h6>
                      {moment(value.last_seen).format("DD-MMM-YYYY hh:mm a")}{" "}
                    </h6>
                    {/* <Checkbox
                      sx={{ width: "24px", height: "24px" }}
                      checked={props.checked.includes(value[props.primary_id])}
                      onChange={() => props.handleCheckbox(value)}
                      {...props.label}
                    /> */}

                    <div>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={isOpen(index) ? "long-menu" : undefined}
                        aria-expanded={isOpen(index) ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, index)}
                        sx={{ padding: "0px" }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEls[index]}
                        // open={open}
                        open={isOpen(index)}
                        onClose={() => handleClose(index)}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                          },
                        }}
                      >
                        {options.map((option) =>
                          option.option_id === 2 ? (
                            <MenuItem key={option.option_id}>
                              <label>
                                <input
                                  className="input-image"
                                  accept="image/*"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    props.handleItemImage(event, value);
                                  }}
                                />
                                {option.option_name}
                              </label>
                            </MenuItem>
                          ) : option.option_id === 3 ? (
                            <MenuItem key={option.option_id}>
                              <label>
                                <input
                                  className="input-image"
                                  accept="image/*"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    props.handleFavDishUploadImage(event, value)
                                  }
                                />
                                {option.option_name}
                              </label>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              key={option.option_id}
                              onClick={() =>
                                handleMoreVert(option, value, index)
                              }
                            >
                              {option.option_name}
                            </MenuItem>
                          )
                        )}
                      </Menu>
                    </div>
                  </div>
                  {/* <div className="fav-card-count">
                    <h6>21 Awardee</h6>
                  </div> */}

                  <div className="d-flex align-items-center">
                    <img
                      src={value.profile_pic ? value.profile_pic : DummyIcon}
                      alt=""
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <div className="d-flex align-items-center">
                        <h6>{value.full_name ? value.full_name : ""}</h6>
                        {value.customer_level ? (
                          <span className="badge bg-primary ms-2 me-1">
                            {value.customer_level}
                          </span>
                        ) : (
                          ""
                        )}
                        {value.status === "active" || "Active" ? (
                          <img
                            src={ApprovedIcon}
                            alt=""
                            style={{ width: "12px", height: "12px" }}
                          />
                        ) : (
                          <img
                            src={RejectedIcon}
                            alt=""
                            style={{ width: "12px", height: "12px" }}
                          />
                        )}
                      </div>
                      <span className="city-name">{value.current_city} </span>
                    </div>
                  </div>
                  <Divider sx={{ bgcolor: "#C5C8C9" }} className="mb-2" />

                  <div
                    className="d-flex item-status-card"
                    // onClick={() => handleItemStatus(value)}
                  >
                    <div className="fav-card-item-info">
                      <div className="d-flex align-items-center">
                        <h5 style={{ marginBottom: "2px", marginRight: "6px" }}>
                          {/* {value.item_name.length <= 20
                            ? value.item_name
                            : `${value.item_name.slice(0, 18)}...`} */}
                        </h5>
                      </div>
                      <h6 style={{ marginBottom: "5px" }}>
                        {/* {value.food_type}{" "} */}
                      </h6>
                      {/* <h6>{value.cuisine_name} </h6> */}
                    </div>
                  </div>

                  <Card
                    className=" mb-2 restaurant-status-card"
                    sx={{ padding: "7px 9px 7px 9px", cursor: "pointer" }}
                    // onClick={() => handleRestaurantStatus(value)}
                  >
                    <div>
                      <Typography variant="h6" className="mb-2">
                        Creation Date:{" "}
                        {moment(value.creation_date).format(
                          "DD-MMM-YYYY hh:mm A"
                        )}
                      </Typography>
                      <Typography variant="h6" className="mb-2">
                        Current City: {value.current_city}
                      </Typography>
                      <Typography variant="h6" className="mb-2">
                        Location Permission: {value.location_permission}
                      </Typography>
                      <Typography variant="h6" className="mb-2">
                        Mobile Verification: {value.mobile_verification}
                      </Typography>
                      <h6
                        style={{
                          color: "#999999",
                          fontFamily: "Roboto",
                          fontWeight: "normal",
                          fontSize: "14px",
                        }}
                      >
                        {value.country_code} {value.phone_number}
                      </h6>
                    </div>
                    <div className="d-flex">
                      <img
                        src={LocationMarkerNewIcon}
                        alt=""
                        style={{ marginRight: "5px" }}
                      />
                      <div className="fav-card-dish-address">
                        <div className="mb-2 align-items-center">
                          <p style={{ marginRight: "4px" }}>
                            {/* {value.restaurant_name.length &&
                          value.restaurant_name.length <= 28
                            ? value.restaurant_name
                            : `${value.restaurant_name.slice(0, 26)}...`} */}
                            {value.current_city}
                          </p>
                          <h6>{value.current_state}</h6>
                          {/* {value.restaurant_status === "approved" ||
                        "Approved" ? (
                          <img
                            src={ApprovedIcon}
                            alt=""
                            style={{ width: "12px", height: "12px" }}
                          />
                        ) : value.restaurant_status === "pending" ||
                          "Pending" ? (
                          <img
                            src={PendingIcon}
                            alt=""
                            style={{ width: "12px", height: "12px" }}
                          />
                        ) : (
                          <img
                            src={HoldIcon}
                            alt=""
                            style={{ width: "12px", height: "12px" }}
                          />
                        )} */}
                        </div>

                        {/* <h6 style={{ whiteSpace: "nowrap" }}>{value.city}</h6> */}
                      </div>
                    </div>
                    <div className="mb-2">
                      <h6>Ip Based City {value.ip_based_city} </h6>
                      <h6
                        style={{
                          color: "#999999",
                          fontFamily: "Roboto",
                          fontWeight: "normal",
                          fontSize: "14px",
                        }}
                      >
                        {value.email}
                      </h6>
                    </div>
                    <div className="mb-2">
                      {value.explored_cities.map((city: any) => {
                        return (
                          <h6
                            className="me-2"
                            style={{
                              color: "#999999",
                              fontFamily: "Roboto",
                              fontWeight: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {city.city}
                          </h6>
                        );
                      })}
                    </div>
                    <div>
                      <h6>User Type: {value.user_type}</h6>
                    </div>
                    <div>
                      <h6>Status: {value.status}</h6>
                    </div>
                  </Card>
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <label className="btn">
                      <input
                        className="input-image"
                        accept="image/*"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          props.handleFavDishUploadImage(event, value)
                        }
                      />
                      <UploadIcon />
                    </label> */}
                </div>
              </div>
            </Card>
          )
        )
      )}
    </>
  );
}

export default CustomerCardView;
