import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import "./MaterialPagination.scss";

export default function MaterialPagination(props: any) {
  return (
    <TablePagination
      sx={props.sx}
      component="div"
      count={props.count}
      page={props.page - 1}
      onPageChange={props.handleChangePage}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 3000, 5000]}
    />
  );
}
