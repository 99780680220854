import { lazy, FC, Suspense, useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Summary from "../layout/components/Summary/Summary";
import Tabledata from "../layout/components/Table/Table";
import MasterLayout from "../master-layout";
import Dashboard from "../modules/home/dashboard/dashboard";
import Settings from "../modules/home/admin/settings";
import { AppContext } from "../utils/state";
import { useAuth } from "../modules/auth";
import { getProfile } from "../utils/api";
import Profile from "../modules/home/admin/profile";
import UserRoles from "../modules/home/admin/user-roles";
import AdminUsers from "../modules/home/admin/admin-users";
import ProductCategories from "../modules/home/product-categories";
import ProductTypes from "../modules/home/product-types";
import Products from "../modules/home/products";
import Brands from "../modules/home/brands";
import ProductBrands from "../modules/home/product_brands";
import Enquiries from "../modules/home/enquiries";

const PrivateRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location);
  const { state, dispatch } = useContext(AppContext);
  const { currentUser } = useAuth();
  // let accessToken = "" + localStorage.getItem("accessToken");
  useEffect(() => {
    console.log("in private*******************");
    if (!state.user._id) {
      fetchProfile();
    }
  }, [location]);

  const fetchProfile = async () => {
    let data = await getProfile();
    if (!data) {
      navigate("/login");
    } else {
      state.user = data.user;
      state.isAuthenticated = true;
      dispatch({
        type: "setAuth",
        data: true,
      });
    }
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/product-categories/*" element={<ProductCategories />} />
          <Route path="/product-types/*" element={<ProductTypes />} />
          <Route path="/products/*" element={<Products />} />
          {/* <Route path="/product-brands/*" element={<ProductBrands />} /> */}
          <Route path="/enquiries/*" element={<Enquiries />} />

          <Route path="/brands/*" element={<Brands />} />
          <Route path="/admin-users/*" element={<AdminUsers />} />
          <Route path="/user-roles/*" element={<UserRoles />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          {/* <Route path="/field-settings/*" element={<FieldSettings />} /> */}
          {/* <Route path="/contacts/*" element={<Contacts />} /> */}
          {/* <Route path="/google-api-metrics/*" element={<GoogleApiMetrics />} /> */}
          <Route path="/summary" element={<Summary />} />
          <Route index element={<Navigate to="/dashboard" />} />
          <Route path="/profile/*" element={<Profile />} />
        </>
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
